export default class ActionTypes {
    public static AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
    public static FETCH_USER_DATA = "FETCH_USER_DATA";
    public static GET_USER_DATA = "GET_USER_DATA";
    public static GET_ALL_COUNTRY = "GET_ALL_COUNTRY";
    public static UPDATE_USER_INFO = "UPDATE_USER_INFO";
    public static UPDATE_ADVISOR_INFO = "UPDATE_ADVISOR_INFO";
    public static UPDATE_AVATAR = "UPDATE_AVATAR";
    public static VERIFY_PHONE_ADVISOR = "VERIFY_PHONE_ADVISOR";
    public static CREATE_VERIFY_PHONE_ADVISOR = "CREATE_VERIFY_PHONE_ADVISOR";
    public static GET_ROLEPLAY_PROMPT = "GET_ROLEPLAY_PROMPT";
    public static UPDATE_ROLEPLAY_PROMPT = "UPDATE_ROLEPLAY_PROMPT";
    public static PREVIEW_OUTCOME_ROLEPLAY_PROMPT =
        "PREVIEW_OUTCOME_ROLEPLAY_PROMPT";

    public static GET_VOCABULARY_SCENARIO_LANGUAGE =
        "GET_VOCABULARY_SCENARIO_LANGUAGE";
    public static POST_VOCABULARY_SCENARIO_LANGUAGE =
        "POST_VOCABULARY_SCENARIO_LANGUAGE";
    public static PUT_VOCABULARY_SCENARIO_LANGUAGE =
        "PUT_VOCABULARY_SCENARIO_LANGUAGE";
    public static GENERATE_VOCABULARY_SCENARIO_LANGUAGE =
        "GENERATE_VOCABULARY_SCENARIO_LANGUAGE";
    public static GENERATE_SAMPLE_CHAT_V1 = "GENERATE_SAMPLE_CHAT_V1";
    public static GET_SAMPLE_CHAT_V1 = "GET_SAMPLE_CHAT_V1";
    public static CREATE_SAMPLE_CHAT_V1 = "CREATE_SAMPLE_CHAT_V1";
    public static UPDATE_SAMPLE_CHAT_V1 = "UPDATE_SAMPLE_CHAT_V1";
    public static CREATE_LINK_REQUEST_V1 = "CREATE_LINK_REQUEST_V1";
    public static REMOVE_LINK_REQUEST_V1 = "REMOVE_LINK_REQUEST_V1";
    public static UNLINK_PARENT_V1 = "UNLINK_PARENT_V1";
    public static GET_LIST_PARENT_V1 = "GET_LIST_PARENT_V1";
    public static LIST_PARENT_BY_QUERY_V1 = "LIST_PARENT_BY_QUERY_V1";
    public static GET_PARENT_BY_ID = "GET_PARENT_BY_ID";
    public static UPDATE_ADVISOR_PROFILE_V1 = "UPDATE_ADVISOR_PROFILE_V1";
    public static PRE_CHECK_CREATE_LINK_REQUEST_V1 = "PRE_CHECK_CREATE_LINK_REQUEST_V1";

}
