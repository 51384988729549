import { Options, Vue } from "vue-class-component";

@Options({
  components: {

  },
  watch: {

  }
})
export default class Notification extends Vue {
  public isMobile: Boolean = false
  public haveUnreadMessage: boolean = false;
  public showNoti = false
  public userData: any = null
  public notifications: any = []

  public style: any = {
    notiIcon: {
      haveNewMessage_url: 'images/icons/svg/notification-icon-active.svg',
      noNewMessage_url: 'images/icons/svg/notification-icon.svg'
    }
  }

  public async mounted() {
    document.addEventListener('click', this.handleClickOutside);
  }

  public unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  // Function to close the popup when clicking outside
  public handleClickOutside = (event: any) => {
    if (!event.target.closest('#notification-icon') && !event.target.closest('#notification-ui')) {
      this.showNoti = false;
    }
  };

  public tab: any = 'all';

  public async changeTab(nameTab: any) {
    this.tab = nameTab;
  }

  public async handleReadNoti(item: any) {

  }

  public async handleReadAllNoti() {

  }
}
