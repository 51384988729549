<script lang="ts" setup>
import { defineProps } from "vue";

interface Props {
  icon?: Object;
  title?: string;
  variant?: "outline" | "default";
  isDisabled?: boolean;
}

const emits = defineEmits(["on-click"]);
const props = withDefaults(defineProps<Props>(), {
  title: "",
  variant: "default",
  isDisabled: false,
});

function onClick() {
  emits("on-click");
}
</script>

<template>
  <button
    class="controlBtn"
    :class="{ controlOutline: variant === 'outline' }"
    @click="onClick"
    :disabled="isDisabled"
  >
    <div class="controlBtn-icon">
      <component :is="icon" />
    </div>
    <p class="controlBtn-title">{{ title }}</p>
  </button>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";
.controlBtn {
  @include flexbox(row, center, center, null, 1, 8px);
  width: 100%;
  background: linear-gradient(99.65deg, #24be85 1.57%, #13a7a4 65.99%);
  border: 1px solid transparent;
  color: $neutral-900;
  border-radius: 12px;
  padding: 12px;
  &.controlOutline {
    border: 1px solid #d6d6d6;
    background: #ffffff;
    color: $neutral-300;
  }
  &-icon {
    width: 20px;
    height: 20px;
    display: flex;
  }
  &-title {
    @include typography(14px, 400, 1.5, normal, inherit);
  }
}
</style>
