<template>
    <div ref="popoverButton" @click="togglePopover">
        <slot name="toggle-open-popover"></slot>
    </div>
    <!-- <div id="test-id" class="my-class">This is content Selectet</div> -->
    <div id="id-popover" ref="popoverContent" class="my-class">
        <slot name="popover-content"> </slot>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted, watch } from "vue";
import { Popover } from "bootstrap";

interface Props {
    placement?: string;
}
const props = withDefaults(defineProps<Props>(), {
    placement: "auto",
});
const popoverButton = ref<any>(null);
const popoverContent = ref<any>(null);
let popoverInstance: any = null;
const togglePopover = () => {
    if (popoverInstance) {
        popoverInstance.toggle();
    }
};

const handleClickOutside = (event: any) => {
    if (
        popoverContent.value &&
        !popoverContent.value.contains(event.target) &&
        !popoverButton.value.contains(event.target)
    ) {
        if (popoverInstance) {
            popoverInstance.hide();
        }
    }
};
onMounted(() => {
    popoverInstance = new Popover(popoverButton.value, {
        content: popoverContent.value,
        // content: "#test-id",
        html: true,
        trigger: "focus",
        placement: props.placement as any,
        customClass: "fj-popover",
        // selector: "#test-id",
    });
    document.addEventListener("click", handleClickOutside);
});
defineExpose({
    togglePopover,
});
onUnmounted(() => {
    if (popoverInstance) {
        popoverInstance.dispose();
    }
    document.removeEventListener("click", handleClickOutside);
});
</script>

<style lang="scss">
#id-popover,
#test-id {
    display: none;
}
.popover.fj-popover {
    max-width: 400px;
    border: none;
    box-shadow: none;
    background-color: transparent;
    .popover-body {
        padding: 0;
    }
    .popover-arrow {
        &::before {
            /* border-left-color: rgb(189 189 189 / 30%); */
            border-left-color: transparent;
            /* top: 2px; */
        }

        &:after {
            /* z-index: 200;
            top: 3px; */
        }
    }
    #id-popover {
        display: block;
    }
}
</style>
