<template>
  <div class="container-layout">
    <MenuSideLeft v-if="deviceType === 'desktop'">
      <slot name="left-menu"> </slot>
    </MenuSideLeft>
    <div class="container-content">
      <slot> </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import MenuSideLeft from "@/components/layout/ToggleShowHiddenLeftMenu/index.vue";
import { useDeviceType } from "@/hooks/useDevice";
const { deviceType } = useDeviceType();
</script>
<style>
.container-layout {
  display: flex;
  gap: 15px;
  flex-direction: row;
  min-height: 100vh;
  flex: auto;
  position: relative;
  background: #e8f3ec;
}

.container-layout .container-content::-webkit-scrollbar {
  display: none;
}

.container-layout .container-content {
  /* height: 100vh; */
  /* max-height: 100svh; */
  /* overflow-y: auto; */
  width: 0;
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 100vh;
}

@media screen and (max-width: 1024px) {
  .container-layout .container-content {
    padding: 16px;
  }
}
</style>
