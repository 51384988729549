<script lang="ts" setup>
import { defineProps, ref } from "vue";
import Button from "../common/Button.vue";
import {
  CalendarV2Icon,
  TaskIcon,
  WhatsappIcon,
} from "@/assets/images/icons/components";
import { toast } from "vue3-toastify";
import store from "@/store";
import Modal from "../common/Modal.vue";
import ParentMeetingForm from "./ParentMeetingForm.vue";
import { MeetingDetails, UserData } from "../../type";
import { sendMeetingRequest } from "../../fetchService";

type ModalInstance = InstanceType<typeof Modal>;

interface Props {
  parentId: number;
  phone: string;
}

const modal = ref<ModalInstance | null>(null);
const isRequesting = ref<boolean>(false);
const emits = defineEmits(["reload"]);
const props = withDefaults(defineProps<Props>(), {
  parentId: 0,
  phone: "",
});

const showModal = () => {
  if (modal.value) {
    modal.value.show();
  }
};
function handleWhatsapp() {
  if (props.phone) {
    const number = props.phone.replace(/[()]/g, "");
    window.open(`https://wa.me/${number}`, "_blank");
  } else {
    toast.error("Parent's phone number is required");
  }
}

const sendRequest = async (data: {
  phone: string;
  details: MeetingDetails;
}) => {
  const advisor = store.state.userData as unknown as UserData;
  if (advisor) {
    isRequesting.value = true;
    const response = await sendMeetingRequest(
      props.parentId,
      advisor.id,
      props.phone,
      data.details
    );
    if (response.isSuccess) {
      if (modal.value) {
        modal.value.hide();
      }

      toast.success("Meeting booked successfully");
      emits("reload");
    } else {
      if (modal.value) {
        modal.value.hide();
      }

      toast.error("Invalid information format. Please try again.");
    }
    isRequesting.value = false;
  }
};
</script>

<template>
  <div class="parentControls">
    <Button :icon="CalendarV2Icon" :title="'Book Meeting'" @click="showModal" />
    <Button
      :icon="WhatsappIcon"
      :title="'Whatsapp'"
      variant="outline"
      @on-click="handleWhatsapp"
    />
    <Button
      :icon="TaskIcon"
      :title="'New Note'"
      variant="outline"
      :is-disabled="true"
      @on-click=""
    />
    <Modal
      ref="modal"
      id="meetingModal"
      title="Video calls and meetings"
      :maxWidth="'900px'"
    >
      <ParentMeetingForm
        @send-request="sendRequest"
        :is-loading="isRequesting"
      />
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";
.parentControls {
  @include flexbox(row, space-between, center, null, null, 8px);
  width: 100%;
  @include responsive-for(mobile) {
    @include flexbox(column, center, center, null, null, 8px);
  }
}
</style>
