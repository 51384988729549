<script lang="ts" src="./notification.ts"></script>
<style scoped lang="scss" src="./notification.scss"></style>



<template>
    <div class="align-self-center" style="position:relative">
        <!-- <div class="d-flex justify-content-center">
            <img v-if="haveUnreadMessage" id="notification-icon"
                :src="require(`@/assets/${style.notiIcon.haveNewMessage_url}`)"
                class="align-self-center new-noti-icon" @click="showNoti=!showNoti" />
            <img v-else id="notification-icon" :src="require(`@/assets/${style.notiIcon.noNewMessage_url}`)"
                class="align-self-center" @click="showNoti=!showNoti" />
        </div> -->

        <div id="notification-ui" v-if="showNoti" class=""
            style="position:absolute; width:400px ; z-index: 3; right: calc( 100% - 50px); top:100%">
            <!-- <img src="@/assets/dashboard/svg/notify.svg" style="position:relative;"/> -->
            <div class="container noti-board">
                <div class="d-flex justify-content-between">
                    <div>
                        <p class="text-start"
                            style="font-size:18px;font-style: normal; font-weight: 600; line-height: 150%; color: var(--primary-color-azibai, #0A0A0A);">
                            Notifications</p>
                    </div>
                    <div :class="!haveUnreadMessage ? 'disabled-item' : 'cursor-pointer'" @click="handleReadAllNoti"
                        data-bs-toggle="tooltip" data-bs-title="Mark all as read" data-bs-placement="bottom">
                        <img src="@/assets/images/icons/png/read-all.png" />
                    </div>

                </div>

                <div class="d-flex justify-content-between col">
                    <div :class="[tab === 'all' ? 'tab-feature-active' : 'tab-feature', 'col-6']" style="cursor:pointer"
                        @click="changeTab('all')">
                        All
                    </div>
                    <div :class="[tab === 'unread' ? 'tab-feature-active' : 'tab-feature', 'col-6']" style="cursor:pointer"
                        @click="changeTab('unread')">
                        Unread
                    </div>
                </div>

                <div class="d-flex flex-column mt-2" style="max-height: calc( 85vh - 90px); overflow: auto;">
                    <div v-for="(item, index) in notifications" :key="index">
                        <p v-if="index=== indexOlder" class="text-start"
                            style="font-size:18px;font-style: normal; font-weight: 600; line-height: 150%; color: var(--primary-color-azibai, #0A0A0A);">
                            Older
                        </p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
