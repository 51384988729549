<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.5555 11.889C1.5555 12.4108 1.61998 12.7216 1.71797 12.922C1.80055 13.0909 1.93499 13.2405 2.23537 13.377C2.56299 13.5259 3.06454 13.647 3.83909 13.7253C4.60565 13.8027 5.5834 13.8334 6.83328 13.8334C8.08316 13.8334 9.06092 13.8027 9.82747 13.7253C10.602 13.647 11.1036 13.5259 11.4312 13.377C11.7316 13.2405 11.866 13.0909 11.9486 12.922C12.0466 12.7216 12.1111 12.4108 12.1111 11.889C12.1111 11.3671 12.0466 11.0564 11.9486 10.8559C11.866 10.6871 11.7316 10.5374 11.4312 10.4009C11.1036 10.252 10.602 10.1309 9.82747 10.0527C9.06092 9.97522 8.08316 9.94453 6.83328 9.94453C5.5834 9.94453 4.60565 9.97522 3.83909 10.0527C3.06454 10.1309 2.56299 10.252 2.23537 10.4009C1.93499 10.5374 1.80055 10.6871 1.71797 10.8559C1.61998 11.0564 1.5555 11.3671 1.5555 11.889Z"
            stroke="currentColor"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M4.33317 4.66659C4.33317 6.0473 5.45246 7.16658 6.83317 7.16658C8.21388 7.16658 9.33317 6.0473 9.33317 4.66659C9.33317 3.28587 8.21388 2.16659 6.83317 2.16659C5.45246 2.16659 4.33317 3.28587 4.33317 4.66659Z"
            stroke="currentColor"
            stroke-width="1.66667"
        />
        <path
            d="M12.9445 5.22217V6.88883M12.9445 8.5555V6.88883M12.9445 6.88883H14.6112H11.2778"
            stroke="currentColor"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
