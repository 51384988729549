<script lang="ts" src="./category.ts"></script>
<style lang="scss" scoped src="./category.scss"></style>

<template>
    <ModalBase
        ref="modal-base-delete-category"
        title="Delete this category"
        btnAction="Yes"
        btnCancel="Cancel"
        :description="`Are you sure you want to delete ${categoryDataSelected.length} categories?`"
        :isDanger="true"
        @handleClickBtnAction="onDeleteCategory"
        @handleClickCancel="handleCancelDeleteCategory"
    />
    <div
        class="modal"
        ref="modal-category"
        tabindex="-1"
        :style="{ opacity: isShowPopupConfirm ? '0' : '1' }"
    >
        <div class="modal-dialog modal-dialog-centered">
            <!-- List Video Topic -->
            <div class="modal-content">
                <div
                    class="d-flex flex-row justify-content-between w-100 header"
                >
                    <p style="color: #222; font-size: 18px; font-weight: 600">
                        Ai Character Category List
                    </p>
                    <button
                        id="close-modal-video-topic"
                        ref="close-add-admin-modal"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>

                <div
                    class="d-flex flex-column w-100"
                    style="overflow: auto; gap: 5px"
                >
                    <div
                        class="category-item justify-content-start"
                        @click="handleAddNewCategory"
                    >
                        <img src="@/assets/images/icons/svg/add.svg" />
                        <p
                            style="
                                color: #169c8a;
                                font-size: 14px;
                                font-weight: 500;
                            "
                        >
                            Add new category
                        </p>
                    </div>

                    <!-- Create New Category -->
                    <div
                        v-if="isStateModal === 'create'"
                        class="form-create-category"
                    >
                        <FLInput>
                            <input
                                placeholder="Enter your category..."
                                v-model="categoryForm.text"
                            />
                        </FLInput>
                        <div class="actions">
                            <span
                                @click="handleCancelSaveCategoryForm"
                                class="action-cancel"
                                title="Discard"
                            >
                                <CloseIcon />
                            </span>
                            <span
                                @click="handleSaveCategoryForm"
                                class="action-save"
                                title="Save"
                            >
                                <StickIcon />
                            </span>
                        </div>
                    </div>
                    <div class="category-container">
                        <div
                            v-for="(item, _index) in categoriesData"
                            :style="
                                categoryDataSelected.includes(item?.value)
                                    ? 'background: #E8F3EC'
                                    : ''
                            "
                            :key="_index"
                            class="category-item"
                        >
                            <label :htmlFor="`category-item-${item.value}`">
                                <input
                                    :id="`category-item-${item.value}`"
                                    type="checkbox"
                                    style="display: none"
                                    @click="(e: any) =>
                                                handleCheckChooseCategory(
                                                    item,
                                                    e.target.checked
                                                )"
                                />
                                <img
                                    v-if="
                                        categoryDataSelected.includes(
                                            item?.value
                                        )
                                    "
                                    src="@/assets/images/icons/svg/check-input.svg"
                                    style="cursor: pointer"
                                />
                                <img
                                    v-else
                                    src="@/assets/images/icons/svg/uncheck-input.svg"
                                    style="cursor: pointer"
                                />
                            </label>
                            <FLInput
                                v-if="
                                    isStateModal === 'edit' &&
                                    categoryForm.value === item.value
                                "
                                style="width: auto; flex: 1"
                            >
                                <input
                                    placeholder="Enter your category..."
                                    v-model="categoryForm.text"
                                />
                            </FLInput>
                            <div v-else style="width: 100%; text-align: start">
                                {{ item?.text }}
                            </div>
                            <div class="actions">
                                <template
                                    v-if="
                                        isStateModal === 'edit' &&
                                        categoryForm.value === item.value
                                    "
                                >
                                    <span
                                        @click="handleCancelSaveCategoryForm"
                                        class="action-cancel"
                                        title="Discard"
                                    >
                                        <CloseIcon />
                                    </span>
                                    <span
                                        @click="handleSaveCategoryForm"
                                        class="action-save"
                                        title="Save"
                                    >
                                        <StickIcon />
                                    </span>
                                </template>
                                <template v-else>
                                    <i
                                        class="bi bi-pencil"
                                        style="cursor: pointer"
                                        @click="handleEditCategory(item)"
                                    ></i>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="categoryDataSelected.length > 0"
                    class="d-flex flex-row justify-content-between w-100"
                    style="gap: 16px"
                >
                    <button
                        class="button-delete"
                        @click="handleOpenDeleteCategoryConfirm()"
                    >
                        Delete
                    </button>
                    <div
                        class="d-flex flex-row justify-content-end w-100"
                        style="gap: 16px"
                    >
                        <button
                            class="button-cancel"
                            @click="handleUnSelectAll"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            <!-- End List Video Topic -->
        </div>
    </div>
</template>
