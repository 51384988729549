// @ts-nocheck
type GetterTypesUserType = {
    GET_ALLOW_EDIT_LIST: string,
    GET_TAB_ACTIVE: string,
};

export const GetterTypesUser: GetterTypesUserType = {
    GET_ALLOW_EDIT_LIST: 'GET_ALLOW_EDIT_LIST',
    GET_TAB_ACTIVE: 'GET_TAB_ACTIVE',
};


function transformObjectProperties(targetObject, prefix) {
    const transformedObject = {};

    // Iterate over the object properties
    for (const key in targetObject) {
        transformedObject[key] = `${prefix}/${targetObject[key]}`;
    }

    return transformedObject;
}
;

export const TransformedGetterTypesUser: GetterTypesUserType = transformObjectProperties(GetterTypesUser, 'user');
