import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { Auth } from "aws-amplify";

class ApiService {
    private http: AxiosInstance;

    constructor() {
        const baseUrl = process.env.VUE_APP_API;

        const axiosConfig: AxiosRequestConfig = {
            baseURL: baseUrl,
            headers: {},
            withCredentials: false,
            timeout: 60000,
        };

        this.http = axios.create(axiosConfig);

        this.initializeRequestInterceptor();
    }

    private initializeRequestInterceptor() {
        this.http.interceptors.request.use(
            async (config) => {
                try {
                    const session = await Auth.currentSession();
                    config.headers.Authorization = `Bearer ${session
                        .getIdToken()
                        .getJwtToken()}`;
                    return config;
                } catch (error) {
                    console.error("Error in request interceptor", error);
                    return Promise.reject(error);
                }
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    public async executeGetRequest(
        url: string,
        params = {},
        headers = null
    ): Promise<AxiosResponse | string | null> {
        try {
            const response = await this.http.get(url, {
                params,
                timeout: 600000,
            });
            return response;
        } catch (e: any) {
            if (e.response) {
                return e.response;
            } else if (e.toString().includes("Network Error")) {
                return "NETWORK_ERROR";
            } else {
                return null;
            }
        }
    }
    public async executeGetPathRequest(
        url: string,
        params = {},
        headers = null
    ): Promise<AxiosResponse | string | null> {
        try {
            // Create an array of path values from the remaining parameters
            const pathValues = Object.values(params).join("/");

            const fullUrl = `${url}/${pathValues}`;
            params = {};
            const response = await this.http.get(fullUrl, {
                params,
                timeout: 600000,
            });
            return response;
        } catch (e: any) {
            if (e.response) {
                return e.response;
            } else if (e.toString().includes("Network Error")) {
                return "NETWORK_ERROR";
            } else {
                return null;
            }
        }
    }

    public async executePostRequest(
        url: string,
        payload: object,
        headers = null
    ): Promise<AxiosResponse | string | null> {
        try {
            const response = await this.http.post(url, payload, {
                timeout: 600000,
            });
            return response;
        } catch (e: any) {
            if (e.response) {
                return e.response;
            } else if (e.toString().includes("Network Error")) {
                return "NETWORK_ERROR";
            } else {
                return null;
            }
        }
    }
    public async executePutRequest(
        url: string,
        payload: object,
        headers = null
    ): Promise<AxiosResponse | string | null> {
        try {
            const response = await this.http.put(url, payload, {
                timeout: 600000,
            });
            return response;
        } catch (e: any) {
            if (e.response) {
                return e.response;
            } else if (e.toString().includes("Network Error")) {
                return "NETWORK_ERROR";
            } else {
                return null;
            }
        }
    }
    public async executeDeleteRequest(
        url: string,
        payload: object,
        headers = null
    ): Promise<AxiosResponse | string | null> {
        try {
            const response = await this.http.delete(url, { data: payload });
            return response;
        } catch (e: any) {
            if (e.response) {
                return e.response;
            } else if (e.toString().includes("Network Error")) {
                return "NETWORK_ERROR";
            } else {
                return null;
            }
        }
    }
    public async executePatchRequest(
        url: string,
        payload: object,
        headers = null
    ): Promise<AxiosResponse | string | null> {
        try {
            const response = await this.http.patch(url, payload, {
                timeout: 600000,
            });
            return response;
        } catch (e: any) {
            if (e.response) {
                return e.response;
            } else if (e.toString().includes("Network Error")) {
                return "NETWORK_ERROR";
            } else {
                return null;
            }
        }
    }
}

export default new ApiService();

// import axios from "axios";

// import { Auth } from 'aws-amplify';

// const baseUrl = process.env.VUE_APP_API;

// const axiosConfig = {
//     baseURL: baseUrl,
//     headers: {},
//     withCredentials: false,
//     crossDomain: true,
//     timeout: 60000,
// };

// const $http = axios.create(axiosConfig);

// $http.interceptors.request.use(
//     async (config) => {
//         try {
//             const session = await Auth.currentSession();
//             config.headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
//             return config;
//         } catch (error) {
//             // Handle error: user not authenticated or other errors
//             console.error("Error in request interceptor", error);
//             // Optionally, redirect to login or handle as needed
//             return Promise.reject(error);
//         }
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

// export const executeGetRequest = async (url: string, params = {}, headers = null) => {
//     try {
//         const response = await $http.get(url, { params, timeout: 600000 });
//         return response;
//     } catch (e: any) {
//         if (e.response) {
//             return e.response;
//         } else if (e.toString().includes("Network Error")) {
//             return "NETWORK_ERROR";
//         } else {
//             return null;
//         }
//     }
// };

// export const executePostRequest = async (url: string, payload: object, headers = null) => {
//     try {
//         const response = await $http.post(url, payload, { timeout: 600000 });
//         return response;
//     } catch (e: any) {
//         if (e.response) {
//             return e.response;
//         } else if (e.toString().includes("Network Error")) {
//             return "NETWORK_ERROR";
//         } else {
//             return null;
//         }
//     }
// };

// export const executeDeleteRequest = async (url: string, payload: object, headers = null) => {
//     try {
//         const response = await $http.delete(url, { data: payload });
//         return response;
//     } catch (e: any) {
//         if (e.response) {
//             return e.response;
//         } else if (e.toString().includes("Network Error")) {
//             return "NETWORK_ERROR";
//         } else {
//             return null;
//         }
//     }
// };

// export const executePutRequest = async (url: string, payload: object, headers = null) => {
//     try {
//         const response = await $http.put(url, payload, { timeout: 600000 });
//         return response;
//     } catch (e: any) {
//         if (e.response) {
//             return e.response;
//         } else if (e.toString().includes("Network Error")) {
//             return "NETWORK_ERROR";
//         } else {
//             return null;
//         }
//     }
// };
