<script setup lang="ts">
import { ArrowDownIcon } from "@/assets/images/icons/components";
import { AccordingPanel, FLForm } from "@/components/base";
import { AllCountries } from "@/constants/allCoutries";
import { computed, onMounted, ref, watch } from "vue";
import { default as FJPopover } from "./popover.vue";
import { debounce } from "lodash";
const { FLInput } = FLForm;
interface Props {
    defaultSelected?: {
        continents: string;
        country: string;
    };
    errorMessage?: string | null;
}
const props = withDefaults(defineProps<Props>(), {});
const emits = defineEmits(["on-change"]);
const selected = ref<any>(null);
const searchValue = ref<string>("");
const refElPopover = ref<any>(null);
function defaultSelectChange() {
    if (props.defaultSelected) {
        selected.value = props.defaultSelected;
    }
}
const onChangeInput = debounce((value: any) => {
    searchValue.value = value.target.value;
}, 150);

function selectedChange(continents: string, country: string) {
    if (refElPopover.value) refElPopover.value.togglePopover();
    emits("on-change", { continents, country });
    selected.value = { continents, country };
}

const countriesFilter: any = computed(() => {
    if (!searchValue.value) return AllCountries;

    const result: any = {};
    for (const [key, continents] of Object.entries(AllCountries)) {
        for (const country of continents) {
            if (
                country.name
                    .toLowerCase()
                    .includes(searchValue.value.toLowerCase())
            ) {
                if (!result[key]) result[key] = [];
                result[key].push(country);
            }
        }
    }
    return result;
});
watch(
    () => props.defaultSelected,
    () => {
        defaultSelectChange();
    },
    { deep: true }
);
onMounted(() => {
    defaultSelectChange();
});
</script>
<template>
    <div class="ai-country">
        <label>Country</label>
        <FJPopover ref="refElPopover" placement="bottom">
            <template #toggle-open-popover>
                <div class="select-value">
                    <span v-if="selected?.continents" class="value">
                        {{ selected.continents }}/{{ selected.country }}
                    </span>
                    <span v-else class="text-default"> Select country </span>
                    <button class="toggle-view-popup">
                        <ArrowDownIcon />
                    </button>
                </div>
            </template>
            <template #popover-content>
                <div class="popup-countries">
                    <FLInput require>
                        <input
                            @keyup="onChangeInput"
                            placeholder="Enter name country"
                        />
                    </FLInput>
                    <div class="countries-list">
                        <AccordingPanel
                            v-for="(con, key) in countriesFilter"
                            :key="key"
                            :idTarget="`country-${key
                                .toString()
                                .replaceAll(' ', '_')}`"
                            className="group-continents small-button "
                        >
                            <template #title>
                                <span class="title-group">{{ key }}</span>
                            </template>
                            <template #content>
                                <div class="countries-of-continents">
                                    <div
                                        v-for="(ctr, _idx) in con"
                                        :key="ctr.code"
                                        :value="ctr.code_639"
                                        @click="
                                            selectedChange(
                                                key.toString(),
                                                ctr.name
                                            )
                                        "
                                        class="country-item ani-appear"
                                        style="font-size: 16px; font-weight: 400;"
                                    >
                                        {{ ctr.name }}
                                    </div>
                                </div>
                            </template>
                        </AccordingPanel>
                    </div>

                    <p v-if="errorMessage" class="text-error">
                        *{{ errorMessage }}
                    </p>
                </div>
            </template>
        </FJPopover>
    </div>
</template>
<style lang="scss">
.popup-countries {
    font-family: "Poppins";
    padding: 10px;
    border-radius: 16px;
    box-shadow: 5px 5px 15px #33333338;
    width: 400px;
    border: 1px solid var(--neutral-06-day, #d6d6d6);
    background-color: white;
    /* position: relative; */
    .title-group {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 600;
    }
    .countries-of-continents {
        padding-left: 5px;
        .country-item {
            padding: 5px 8px;
            border-radius: 8px;
            cursor: pointer;
            font-size: 1.5em;
            &:hover {
                background-color: var(--secondary-100);
                color: var(--primary-500);
            }
        }
    }
    .accordion-item {
        margin-top: 8px;
    }
    .accordion.group-continents {
        box-shadow: none;
        border-radius: 0;
        .accordion-button {
            padding: 5px;
        }
        .accordion-body {
            padding: 0;
        }
    }
    .countries-list {
        max-height: 450px;
        overflow-y: auto;
    }
}

.ai-country {
    width: 100%;
    font-size: 1.5rem;
    label {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
        color: #464749;
        text-align: left;
        width: 100%;
    }
    .select-value {
        background-color: white;
        border-radius: 12px;
        /* max-width: 100; */
        padding: 4px 4px 4px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--neutral-06-day, #d6d6d6);
        cursor: pointer;
        .toggle-view-popup {
            background-color: transparent;
            border-radius: 8px;
            border: none;
            padding: 3px 10px;
            transition: all 250ms ease-in-out;
            &:hover {
                background-color: var(--neutral-800);
            }
        }
        .text-default {
            color: var(--neutral-500);
        }
        &:hover,
        &:focus,
        &:active,
        &:visited,
        &:focus-within {
            box-shadow: 0 0 8px #3333331c;
        }
    }
}
</style>
