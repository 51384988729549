<script lang="ts" setup>
import { DropdownSelect } from "@/components/base";
import { IDropdownItem } from "@/interfaces";
import { computed, defineEmits, defineProps, ref, withDefaults } from "vue";
import { ITopic } from "../utils/type";

interface Props {
    listTopic: ITopic[];
    scenarioName: string;
}
const props = withDefaults(defineProps<Props>(), {});
const topicSelectedId = ref<number | null>(null);
const emits = defineEmits(["on-save", "on-close"]);
const selectTopics = computed<IDropdownItem[]>(() => {
    return (
        (props.listTopic.map((topic) => ({
            text: topic.name,
            value: topic.id,
            active: true,
        })) as IDropdownItem[]) || []
    );
});

function selectChange(value: number) {
    topicSelectedId.value = value;
}

function handleSave() {
    emits("on-save", topicSelectedId.value);
}
</script>
<template>
    <div class="popover-topic-container">
        <div class="topic-title">
            <h4 class="title">Move Topic</h4>
            <p class="subtitle">
                <span>Scenario Name: </span>
                <span class="scenario-name">{{ scenarioName }}</span>
            </p>
        </div>
        <div class="topic-content">
            <DropdownSelect
                placeholder="Choose a topic"
                :options="selectTopics"
                @selectOption="selectChange"
            />
        </div>
        <div class="topic-controls">
            <button class="button-default" @click="emits('on-close')">
                Cancel
            </button>
            <button class="button-solid" @click="handleSave">Save</button>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.popover-topic-container {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    padding: 15px;
    gap: 15px;
    width: 550px;
    .topic-title {
        .title {
            font-size: 18px;
            font-weight: 600;
        }
        .subtitle {
            font-size: 14px;
            color: var(--neutral-500);
            text-align: center;
            .scenario-name {
                color: var(--primary-500);
                font-weight: 600;
            }
        }
    }
    .topic-controls {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        button {
            flex: 1;
        }
    }
}
</style>
