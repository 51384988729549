<template>
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.9161 11.7435C17.9733 10.9414 18 10.0324 18 9C18 6.81763 17.8807 5.18677 17.6061 3.95094C17.336 2.73554 16.9401 2.01532 16.4624 1.53762C15.9847 1.05991 15.2645 0.66405 14.0491 0.393939C12.8132 0.119285 11.1824 0 9 0C6.81763 0 5.18677 0.119285 3.95094 0.393939C2.73554 0.66405 2.01532 1.05991 1.53762 1.53762C1.05991 2.01532 0.66405 2.73554 0.393939 3.95094C0.119285 5.18677 0 6.81763 0 9C0 11.1824 0.119285 12.8132 0.393939 14.0491C0.66405 15.2645 1.05991 15.9847 1.53762 16.4624C2.01532 16.9401 2.73554 17.336 3.95094 17.6061C5.18677 17.8807 6.81763 18 9 18C10.0367 18 10.949 17.9731 11.7536 17.9154C11.7504 17.7539 11.7419 17.581 11.7323 17.3848C11.7269 17.2743 11.7211 17.1564 11.7157 17.029C11.699 16.6359 11.6884 16.1874 11.7225 15.7274C11.7901 14.8127 12.0417 13.748 12.8799 12.9071C13.7208 12.0637 14.8175 11.8053 15.7414 11.7293C16.21 11.6907 16.665 11.6968 17.0602 11.7105C17.2128 11.7158 17.3497 11.7218 17.4751 11.7272C17.6381 11.7344 17.7822 11.7407 17.9161 11.7435ZM7.2 3.6C6.70294 3.6 6.3 4.00294 6.3 4.5C6.3 4.99706 6.70294 5.4 7.2 5.4H10.8C11.2971 5.4 11.7 4.99706 11.7 4.5C11.7 4.00294 11.2971 3.6 10.8 3.6H7.2ZM4.5 8.1C4.00294 8.1 3.6 8.50294 3.6 9C3.6 9.49706 4.00294 9.9 4.5 9.9H13.5C13.9971 9.9 14.4 9.49706 14.4 9C14.4 8.50294 13.9971 8.1 13.5 8.1H4.5Z"
            fill="currentColor"
        />
        <path
            d="M13.5482 17.7051C13.5429 17.555 13.5353 17.4001 13.5279 17.2486C13.523 17.1477 13.5181 17.0482 13.5141 16.9527C13.4982 16.5795 13.4913 16.2142 13.5175 15.8602C13.5703 15.147 13.75 14.5838 14.1546 14.178C14.5567 13.7747 15.146 13.5843 15.8889 13.5232C16.2537 13.4932 16.6272 13.4966 16.9979 13.5094C17.1081 13.5132 17.2242 13.5182 17.3414 13.5233L17.3424 13.5233C17.4645 13.5286 17.5881 13.534 17.7069 13.538C17.6759 13.7155 17.6424 13.8857 17.6061 14.0491C17.336 15.2645 16.9401 15.9847 16.4624 16.4624C15.9847 16.9401 15.2645 17.336 14.0491 17.6061C13.8889 17.6417 13.722 17.6747 13.5482 17.7051Z"
            fill="currentColor"
        />
    </svg>
</template>
