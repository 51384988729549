<script lang="ts" setup>
import { computed, defineProps } from "vue";

interface Props {
  percent: number;
  point: number;
}

const props = withDefaults(defineProps<Props>(), {
  percent: 0,
  point: 0,
});

const listStars = computed<boolean[]>(() => {
  let stars = [];
  if (props.point) {
    for (let i = 0; i < 3; i++) {
      if (i < props.point) {
        stars.push(true);
      } else {
        stars.push(false);
      }
    }
    return stars;
  } else {
    return [false, false, false];
  }
});
</script>

<template>
  <div class="stars">
    <div class="stars-list">
      <img
        class="stars-lists-img"
        v-for="(star, idx) in listStars"
        :key="idx"
        :src="
          star
            ? require('@/assets/images/icons/svg/point-star.svg')
            : require('@/assets/images/icons/svg/point-star-inactive.svg')
        "
        alt="Star"
      />
    </div>
    <span class="stars-percent">{{ percent }}%</span>
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";
.stars {
  @include flexbox(row, center, center, null, null, 4px);
  @include size(fit-content, auto);
  background: $neutral-800;
  border-radius: 30px;
  padding: 8px 16px;
  &-lists {
    @include flexbox(row, center, center, null, null, 4px);
    &-img {
      @include size(16px, 16px);
    }
  }
  &-percent {
    @include typography(14px, 400, 1.5, normal, $neutral-400);
  }
}
</style>
