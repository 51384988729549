import SideBar from "@/components/layout/side-bar/side-bar.vue";
import Header from "@/components/layout/header/header.vue";
import TabSide from "@/components/base/tab-side/tab-side.vue";
import { Vue, Options } from "vue-class-component";
import { TransformedActionTypesDashboard } from "@/store/modules/dashboard/action-style";

@Options({
    components: {
        SideBar,
        Header,
        TabSide
    },
    props: {
    },
})
export default class Dashboard extends Vue {

    mounted(): void {
        this.apiGetTotalUsers()
        this.apiGetTotalPaidUsers()
        this.apiGetNumberKidsByAge()
        
    }
    public overViews: any = {
        totalUsers: { title: 'Total users', quantity: 0, image: 'users' },
        paidUsers: { title: 'Total paid users', quantity: 0, image: 'paid-users' },
        under13Users: { title: 'Total learners under 13', quantity: 0, image: 'under-13-users' },
        over13Users: { title: 'Total learners over 13', quantity: 0, image: 'over-13-users' },
    }

    public handleDetailsOverView() {

    }

    public arrayTab: any = [
        { id: 0, name: 'All', quantity: 1, isActive: true },
        { id: 1, name: 'Tab1', quantity: 2, isActive: false },
        { id: 2, name: 'Tab2', quantity: 3, isActive: false },
        { id: 3, name: 'Tab3', quantity: 4, isActive: false },
        { id: 4, name: 'Tab4', quantity: 5, isActive: false },
    ]

    public handleChangeTab(index: any) {
        if (this.arrayTab && this.arrayTab.length > 0) {
            this.arrayTab.forEach((item: any) => item.isActive = false);
            this.arrayTab[index].isActive = true;
        }

    }

    public apiGetNumberKidsByAge() {

        this.$apiAction.handleApiRequest(
            TransformedActionTypesDashboard.GET_NUMBER_KIDS_BY_AGE,
            {},
            (data: any) => {
                this.overViews.under13Users.quantity = data.less_13 || 'N/a'
                this.overViews.over13Users.quantity = data.greater_13 || 'N/a'
                // this.$toast.success('Updated successful!')
            }
        );
    }

    public apiGetTotalPaidUsers() {

        this.$apiAction.handleApiRequest(
            TransformedActionTypesDashboard.GET_TOTAL_PAID_USER,
            {},
            (data: any) => {
                this.overViews.paidUsers.quantity = data.active_subs || 'N/a'
            }
        );
    }

    public apiGetTotalUsers() {

        this.$apiAction.handleApiRequest(
            TransformedActionTypesDashboard.GET_TOTAL_USER,
            {},
            (data: any) => {
                this.overViews.totalUsers.quantity = data.total || 'N/a'

            }
        );
    }
}