interface IApiService {
    store: any; // Specify the type of store, for example, Vuex.Store if you are using Vuex

    handleApiRequest(
        actionType: string,
        payload: any,
        successCallback: (data: any) => void
    ): Promise<void>;
}
import { toast } from "vue3-toastify";

class ApiService implements IApiService {
    store: any;
    constructor(store: any) {
        this.store = store;
    }

    async handleApiRequest(
        actionType: any,
        payload: any,
        successCallback: any,
        errorCallback?: any
    ) {
        try {
            const response = await this.store.dispatch(actionType, payload);
            if (
                response.status === 200 ||
                response.status === 201 ||
                response.status === 204
            ) {
                successCallback(response?.data || { messages: "Success" });
            } else {
                toast.error(response.data.message);
                if (errorCallback) errorCallback(response.data.message);
            }
        } catch (error: any) {
            console.error("API call error:", error);
            toast.error("An error occurred while processing your request.");
            if (errorCallback) errorCallback(error.message);
        }
    }
}

export default ApiService;
