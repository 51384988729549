<script lang="ts" setup>
import { ref, onMounted, nextTick } from "vue";
import ProcessingList from "./components/child-learning-progress/ProcessingList.vue";
import StatisticCard from "./components/statistics/StatisticCard.vue";
import InsightCard from "./components/chat-insights/InsightCard.vue";
import CognitiveCard from "./components/cognitive-review/CognitiveCard.vue";
import Dropdown from "./components/common/Dropdown.vue";
import ActivityCard from "./components/activity/ActivityCard.vue";
import { WEEKS_SELECTION } from "./constants";
import { CalendarV2Icon } from "@/assets/images/icons/components";

import { useRoute, useRouter } from "vue-router";
import {
  ActivityDataFromAPI,
  CognitiveDataFromAPI,
  InsightDataFromAPI,
  LearningProcessDataFromAPI,
  ParentInformation,
  StatisticDataFromAPI,
} from "./type";
import {
  getChatInsight,
  getChildActivities,
  getCognitiveAssessmentReview,
  getLearningProgress,
  getParentInformation,
  getStatistics,
} from "./fetchService";
import ListTabs from "./components/child-tabs/ListTabs.vue";
import ParentCard from "./components/parent-info/ParentCard.vue";
import NoData from "./components/common/NoData.vue";
import Loading from "./components/common/Loading.vue";
import Back from "./components/common/Back.vue";
import ActionTypes from "@/store/action-types";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";

const route = useRoute();
const router = useRouter();
const parentId = route.query.parent;
const childId = ref<number>(0);
const parentInfo = ref<ParentInformation | null>(null);
const learningProgress = ref<LearningProcessDataFromAPI>();
const statistics = ref<StatisticDataFromAPI>();
const chatInsights = ref<InsightDataFromAPI>();
const cognitiveReview = ref<CognitiveDataFromAPI>();
const activities = ref<ActivityDataFromAPI | null>();
const filterSelected = ref<number>(1); // 1 for this week, 2 for last week
const featureSelected = ref<number | null>(null);
const isSettingData = ref<boolean>(true);
const isFilteringData = ref<boolean>(false);
const isLoading = ref<boolean>(false);

const store = useStore();

// Select filter this week or last week
async function selectFilter(value: number) {
  isFilteringData.value = true;
  filterSelected.value = value;
  if (parentId) {
    learningProgress.value = await getLearningProgress(
      parentId.toString(),
      filterSelected.value
    );
    activities.value = await getChildActivities(
      parentId.toString(),
      childId.value,
      filterSelected.value
    );
    statistics.value = await getStatistics(childId.value, value);
  }

  isFilteringData.value = false;
}

// Select child in child tabs
async function selectChild(value: number) {
  isFilteringData.value = true;
  childId.value = value;
  statistics.value = await getStatistics(value, filterSelected.value);
  chatInsights.value = await getChatInsight(value);
  cognitiveReview.value = await getCognitiveAssessmentReview(value);
  if (parentId) {
    activities.value = await getChildActivities(
      parentId.toString(),
      childId.value,
      filterSelected.value
    );
  }
  isFilteringData.value = false;
}

// Select features filter on Activity Card
async function selectFeature(value: number) {
  featureSelected.value = value;
  if (parentId) {
    activities.value = await getChildActivities(
      parentId.toString(),
      childId.value,
      filterSelected.value,
      value
    );
  }
}

async function reloadParentInfoWhenSubmitMeeting() {
  if (parentId) {
    const response = await getParentInformation(parentId.toString());
    if (response) {
      parentInfo.value = response;
    }
  }
}

async function apiUnlink(payload: any){
  return await store.dispatch(ActionTypes.UNLINK_PARENT_V1, payload)
}

async function unlinkParent() {
  isLoading.value = true;
  try {
    const res = await apiUnlink({ parent_id: parentId });
    if (res.status === 200) {
      toast.success(res.data.message);
      await nextTick();
      await router.go(-1);
    } else {
      toast.error(res.data.message);
    }
  }
  finally {
    isLoading.value = false;
  }
}

// Start to get all the data for the page (parent information, learning process, statistics, chat insight, cognitive review, activities)
async function setInitialData() {
  if (parentId) {
    const response = await getParentInformation(parentId.toString());
    if (response) {
      parentInfo.value = response;
      learningProgress.value = await getLearningProgress(
        parentId.toString(),
        filterSelected.value
      );
      if (response.children && response.children.length > 0) {
        // Get data for the first child
        childId.value = response.children[0].id;
        statistics.value = await getStatistics(
          childId.value,
          filterSelected.value
        );
        chatInsights.value = await getChatInsight(childId.value);
        cognitiveReview.value = await getCognitiveAssessmentReview(
          childId.value
        );
        activities.value = await getChildActivities(
          parentId.toString(),
          childId.value,
          filterSelected.value
        );
      }
    }
  }
  isSettingData.value = false;
}

onMounted(() => {
  setInitialData();
});
</script>

<template>
  <!-- First loading data -->
  <div v-if="isSettingData" class="report-loading">
    <Loading />
  </div>
  <!-- When the parent is not existed -->
  <div v-else-if="!isSettingData && !parentInfo" class="report-no-data">
    <NoData />
  </div>
  <div v-else class="report">
    <Back
      v-if="parentInfo"
      :name="parentInfo.display_name || parentInfo.username"
    />
    <div class="report-header">
      <Loading v-if="isLoading" />

      <ParentCard
        v-if="parentInfo"
        :data="parentInfo"
        @reload="reloadParentInfoWhenSubmitMeeting"
        @on-unlink = "unlinkParent"
      />
      <div class="report-header-title">
        <p>Child's Learning Progress</p>
        <div class="report-filtering">
          <Loading v-if="isFilteringData" />
          <Dropdown
            :Icon="CalendarV2Icon"
            :dataOptions="WEEKS_SELECTION"
            :selectedDefault="1"
            @on-selected="selectFilter"
          />
        </div>
      </div>
    </div>
    <ProcessingList v-if="learningProgress" :data="learningProgress" />
    <ListTabs
      v-if="parentInfo"
      :data="parentInfo.children"
      :selected-value="childId"
      @on-select="selectChild"
    />
    <div class="report-cards">
      <div class="report-filtering-submenu">
        <Loading v-if="isFilteringData" />
      </div>
      <StatisticCard :data="statistics" />
      <InsightCard :data="chatInsights" />
    </div>
    <CognitiveCard :data="cognitiveReview" />
    <ActivityCard :data="activities" @on-select-feature="selectFeature" />
  </div>
</template>

<style lang="scss" scoped>
@import "./styles/index.scss";
.report-loading {
  @include flexbox(row, center, center);
}

.report {
  @include flexbox(column, center, center, null, null, 24px);
  position: relative;
  height: fit-content;
  overflow: auto;
  &-header {
    @include flexbox(column, flex-start, flex-start, null, null, 12px);
    @include size(100%, 100%);
    &-title {
      @include flexbox(row, space-between, center, null, null, 24px);
      width: 100%;
      @include responsive-for(tablet) {
        @include flexbox(column, flex-start, flex-start, null, null, 16px);
      }
    }
    p {
      @include title;
      font-size: 24px;
    }
  }
  &-cards {
    width: 100%;
    position: relative;
    @include flexbox(row, space-between, flex-start, null, null, 16px);
    @include responsive-for(tablet) {
      @include flexbox(column, center, center, null, null, 16px);
    }
  }
  &-filtering {
    @include flexbox(row, flex-end, center, null, null, 8px);
    &-submenu {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      z-index: 99;
    }
  }
}
</style>
