// @ts-nocheck
import { ActionTypesPersonAi } from "./action-style";
import ApiService from "@/services/api";

async function executiveHTTP(
    url: string,
    payload: any,
    method: "GET" | "GET_PATH" | "POST" | "PUT" | "DELETE" = "GET"
): any {
    let response = null;
    switch (method) {
        case "GET":
            response = await ApiService.executeGetRequest(url, payload);
            break;
        case "GET_PATH":
            response = await ApiService.executeGetPathRequest(url, payload);
            break;
        case "POST":
            response = await ApiService.executePostRequest(url, payload);
            break;
        case "PUT":
            response = await ApiService.executePutRequest(url, payload);
            break;
        case "DELETE":
            response = await ApiService.executeDeleteRequest(url, payload);
            break;
    }
    if (response) {
        return response;
    } else {
        return null;
    }
}

export default {
    // ========================= API Version 2 =========================

    // AI Character V2
    [ActionTypesPersonAi.GET_PERSON_AIS_V2]: async ({ commit }, payload) => {
        return executiveHTTP("/api/v2/person-ais/get-list", payload);
    },
    [ActionTypesPersonAi.GET_STORY_OF_PERSON_AI_V2]: async (
        { commit },
        payload
    ) => {
        return executiveHTTP(
            `/api/v2/person-ais/get-story/${payload.person_ai_id}`,
            {}
        );
    },
    [ActionTypesPersonAi.UPDATE_PERSON_AI_DETAIL_V2]: async (
        { commit },
        payload
    ) => {
        const { id, ...data } = { ...payload };
        return executiveHTTP(`/api/v2/person-ais/${id}`, data, "PUT");
    },
    [ActionTypesPersonAi.CREATE_PERSON_AI_V2]: async ({ commit }, payload) => {
        let { video_call_configs, ..._payload } = payload;
        video_call_configs = null;
        return executiveHTTP(
            "/api/v2/person-ais",
            { ..._payload, video_call_configs },
            "POST"
        );
    },
    [ActionTypesPersonAi.GET_PERSON_AI_INFO_V2]: async (
        { commit },
        payload
    ) => {
        return executiveHTTP("/api/v2/person-ais", payload, "GET_PATH");
    },
    [ActionTypesPersonAi.UPDATE_PROMOTED_PERSON_AIS_V2]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePatchRequest(
            "/api/v2/person-ais/mark-as-promoted",
            payload
        );

        return response ? response : null;
    },

    // AI Scenario V2
    [ActionTypesPersonAi.GET_SCENARIOS_TASK_EDIT_TEMPLATE_V2]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest(
            `/api/v2/scenarios/get-task-edit-template`,
            payload
        );
        if (response) {
            return response;
        } else {
            return null;
        }
    },
    [ActionTypesPersonAi.GET_VIDEO_CALL_SCENARIO_V2]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        return executiveHTTP(`/api/v2/scenarios/${payload.id}`, {});
    },
    [ActionTypesPersonAi.CREATE_VIDEO_CALL_SCENARIO_V2]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePostRequest(
            `/api/v2/scenarios`,
            payload
        );
        if (response) {
            return response;
        } else {
            return null;
        }
    },
    [ActionTypesPersonAi.UPDATE_VIDEO_CALL_SCENARIO_V2]: async (
        { commit },
        payload
    ) => {
        const { id, created_at, creator, updated_at, creator_id, ..._payload } =
            payload;
        return executiveHTTP(`/api/v2/scenarios/${id}`, _payload, "PUT");
    },
    [ActionTypesPersonAi.UPDATE_VIDEO_CALL_SCENARIO_OPTION_2_V2]: async (
        { commit },
        payload
    ) => {
        return executiveHTTP(`/api/v2/scenarios/update-topic`, payload, "PUT");
    },
    [ActionTypesPersonAi.DELETE_VIDEO_CALL_SCENARIO_V2]: async (
        { commit },
        payload
    ) => {
        return executiveHTTP(`/api/v2/scenarios/${payload.id}`, {}, "DELETE");
    },

    // AI Topic V2
    [ActionTypesPersonAi.CREATE_VIDEO_CALL_TOPICS_V2]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const { creator_id, ..._payload } = payload;
        return executiveHTTP(`/api/v2/topics`, _payload, "POST");
    },
    [ActionTypesPersonAi.GET_VIDEO_CALL_TOPICS_V2]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest(
            `/api/v2/topics`,
            payload
        );
        if (response) {
            return response;
        } else {
            return null;
        }
    },
    [ActionTypesPersonAi.UPDATE_VIDEO_CALL_TOPICS_V2]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const { id, creator_id, ..._payload } = { ...payload };
        return executiveHTTP(`/api/v2/topics/${id}`, _payload, "PUT");
    },
    [ActionTypesPersonAi.DELETE_VIDEO_CALL_TOPIC_V2]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeDeleteRequest(
            `/api/v2/topics/${payload.topic_id}`,
            payload
        );
        if (response) {
            return response;
        } else {
            return null;
        }
    },
    [ActionTypesPersonAi.DELETE_VIDEO_CALL_TOPICS_V2]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeDeleteRequest(
            `/api/v2/topics/bulk-delete`,
            payload
        );
        if (response) {
            return response;
        } else {
            return null;
        }
    },

    // AI Utilities V2
    [ActionTypesPersonAi.AUTO_TRANSLATE_TEXT_V2]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePostRequest(
            `/api/v2/utils/translate`,
            payload
        );
        if (response) {
            return response;
        } else {
            return null;
        }
    },

    [ActionTypesPersonAi.UPLOAD_AVATAR_V2]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePostRequest(
            `/api/v2/utils/register-image`,
            payload
        );
        if (response) {
            return response;
        } else {
            return null;
        }
    },

    // ----------------------------------------- API Version 1 -----------------------------------------

    [ActionTypesPersonAi.UPLOAD_AVATAR]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePutRequest(
            `/api/v1/person-ais/upload_avatar`,
            payload
        );
        if (response) {
            return response;
        } else {
            return null;
        }
    },

    [ActionTypesPersonAi.GET_PERSON_AIS]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest(
            "/api/v1/person-ais/get-list",
            payload
        );
        if (response) {
            return response;
        } else {
            return null;
        }
    },
    [ActionTypesPersonAi.GET_ALL_AIS_PERSON_DISTINCT]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest(
            "/api/v1/person-ais/get-distinct",
            payload
        );

        return response ? response : null;
    },

    [ActionTypesPersonAi.UPDATE_PERSON_AI]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePutRequest(
            `/api/v1/person-ais/update-list/${payload.id}`,
            payload.body
        );

        return response ? response : null
    },
    [ActionTypesPersonAi.CREATE_PERSON_AI]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePostRequest(
            `/api/v1/person-ais`,
            payload
        );

        return response ? response : null
    },
    [ActionTypesPersonAi.DELETE_PERSON_AI]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeDeleteRequest(
            `/api/v1/person-ais/delete-list`,
            payload
        );

        return response ? response : null;
    },

    [ActionTypesPersonAi.EXPORT_EXCEL]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest(
            `/api/v1/person-ais/export-excel`,
            payload
        );

        return response ? response : null;
    },

    [ActionTypesPersonAi.UPLOAD_AVATAR]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePutRequest(
            `/api/v1/person-ais/upload_avatar`,
            payload
        );
        return response ? response : null;
    },

    [ActionTypesPersonAi.UPLOAD_VIDEO]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePutRequest(
            `/api/v1/person-ais/upload-video`,
            payload
        );
        return response ? response : null;
    },
    [ActionTypesPersonAi.AUTO_TRANSLATE_INFO_PROMPT]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePostRequest(
            `/api/v1/person-ais/auto-translate-v2`,
            payload
        );
        return response ? response : null;
    },
    [ActionTypesPersonAi.AUTO_TRANSLATE]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePostRequest(
            `/api/v1/person-ais/auto-translate`,
            payload
        );

        return response ? response : null
    },
    [ActionTypesPersonAi.GET_CATEGORY_LIST]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        return executiveHTTP(`/api/v1/categories/get-list`);
    },
    [ActionTypesPersonAi.CREATE_CATEGORY]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        return executiveHTTP(`/api/v1/categories`, payload, "POST");
    },
    [ActionTypesPersonAi.DELETE_CATEGORY_LIST]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        return executiveHTTP(`/api/v1/categories`, payload, "DELETE");
    },
    [ActionTypesPersonAi.UPDATE_CATEGORY]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        return executiveHTTP(
            `/api/v1/categories/${payload.id}`,
            { name: payload.name },
            "PUT"
        );
    },
    [ActionTypesPersonAi.GET_PERSON_AI_INFO]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetPathRequest(
            `/api/v1/person-ais`,
            payload
        );

        return response ? response : null
    },
    [ActionTypesPersonAi.UPDATE_PERSON_AI_DETAIL]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePutRequest(
            `/api/v1/person-ais/${payload.id}`,
            payload.body
        );

        return response ? response : null
    },
    [ActionTypesPersonAi.GET_VIDE0_CALL_TOPICS]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest(
            `/api/v1/video_call_topics`,
            payload
        );

        return response ? response : null
    },
    [ActionTypesPersonAi.GET_VIDEO_CALL_CHAT_MODELS]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest(
            `/api/v1/video_call_chat_models`
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.GET_VIDE0_CALL_VOICES]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest(
            `/api/v1/video_call_voices`
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.UPDATE_VIDEO_CALL_SCENARIO]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const newPayload = { ...payload };
        delete newPayload.id;
        const response = await ApiService.executePutRequest(
            `/api/v1/video_call_scenarios/${payload.id}`,
            newPayload
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.UPDATE_VIDEO_CALL_TOPICS]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const newPayload = { ...payload };
        delete newPayload.id;
        const response = await ApiService.executePutRequest(
            `/api/v1/video_call_topics/${payload.id}`,
            newPayload
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.CREATE_VIDEO_CALL_SCENARIO]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePostRequest(
            `/api/v1/video_call_scenarios`,
            payload
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.CREATE_VIDEO_CALL_TOPICS]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePostRequest(
            `/api/v1/video_call_topics`,
            payload
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.DELETE_VIDEO_CALL_TOPICS]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeDeleteRequest(
            `/api/v1/video_call_topics/${payload.topic_id}`,
            payload
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.DELETE_VIDEO_CALL_SCENARIO]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeDeleteRequest(
            `/api/v1/video_call_scenarios/${payload.scenario_id}`
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.AUTOGENERATE_TASK_VIDEO_CALL_SCENARIO_V2]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePostRequest(
            `/api/v2/scenarios/generate-tasks`,
            payload
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.AUTOGENERATE_CONTEXT_VIDEO_CALL_SCENARIO_V2]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePostRequest(
            `/api/v2/scenarios/generate-scenario`,
            payload
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.GET_SCENARIOS_V3]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest(
            `/api/v3/scenarios/get-list`,
            payload
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.GET_SCENARIO_V3]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest(
            `/api/v3/scenarios/${payload.scenario_id}`,
            {}
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.GET_TEMPLATE_TASKS_SCENARIO_V3]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest(
            `/api/v3/scenarios/get-task-edit-template`,
            {}
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.UPDATE_SCENARIO_V3]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const newPayload = { ...payload };
        delete newPayload.id;
        const response = await ApiService.executePutRequest(
            `/api/v3/scenarios/${payload.id}`,
            payload
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.DELETE_SCENARIOS_V3]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeDeleteRequest(
            `/api/v3/scenarios`,
            payload
        );

        return response ? response : null;
    },

    [ActionTypesPersonAi.DELETE_SCENARIO_V3]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeDeleteRequest(
            `/api/v3/scenarios/${payload.scenario_id}`,
            {}
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.MARK_FREE_SCENARIOS_V3]: async (
        { commit },
        payload
    ) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePatchRequest(
            `/api/v3/scenarios/mark-as-free`,
            payload
        );

        return response ? response : null
    },

    [ActionTypesPersonAi.GET_ALL_AIS_PERSON_VIDEO_CALL_ENABLED]: async (
        { commit },
        payload
    ) => {
        const response = await ApiService.executeGetRequest(
            "/api/v1/person-ais/get-person-ai-video-call-enabled",
            payload
        );

        return response ? response : null;
    },
};
