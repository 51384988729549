// @ts-nocheck
import { ActionTypesAdmin } from "./action-style";
import ApiService from "@/services/api";
export default {
    [ActionTypesAdmin.GET_ADMINS]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest("/api/v2/admins", payload)
        return response ? response : null;
    },
    [ActionTypesAdmin.UPDATE_ADMIN]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePutRequest(`/api/v2/admins/update-role/${payload.id}`, payload.body)
        return response ? response : null;
    },
    [ActionTypesAdmin.CREATE_ADMIN]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePostRequest(`/api/v2/admins`, payload)
        return response ? response : null;
    },
    [ActionTypesAdmin.DELETE_ADMIN]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeDeleteRequest(`/api/v2/admins/delete-list`, payload)
        return response ? response : null;
    },

    [ActionTypesAdmin.EXPORT_EXCEL]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest(`/api/v1/admins/export-excel`, payload)
        return response ? response : null;
    },
}
