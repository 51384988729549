import { IScenarioTopic, IScenarioWithLanguage } from "@/interfaces/person-ai";
import { IVocabularyItem } from "./type";

interface IValidateReturn {
    status: boolean;
    errorMessage: string | null;
}

const FIELD_NAME_TITLE: any = {
    prompt: "Prompt",
    video_call_topic_id: "Topic ID",
    person_ai_id: "Person AI ID",
    image_url: "Image URL",
    type: "Type video call",
    content: {
        name: "Scenario name",
        ai_role: "AI role",
        user_role: "User role",
        context: "Context",
        taskTitle: "Content Task",
    },
};

export function validateTranslateForm(
    contents: IScenarioTopic["content"],
    type: string
): IValidateReturn {
    for (let [language, contentValue] of Object.entries(contents)) {
        // Iterate over the entries of each content sub-object
        // if (language === "English") {
        //     const result = validateFieldEnglish(
        //         contentValue as IScenarioWithLanguage,
        //         language,
        //         type
        //     );
        //     if (!result.status) return result;
        // }
        const result = validateFieldEnglish(
            contentValue as IScenarioWithLanguage,
            language,
            type
        );
        if (!result.status) return result;
    }
    return {
        status: true,
        errorMessage: null,
    };
}

export function validateFieldEnglish(
    content: IScenarioWithLanguage,
    language: string,
    type: string = "learn_language"
): IValidateReturn {
    // Iterate over the entries of each content sub-object
    for (let [subKey, subValue] of Object.entries(content as any)) {
        // Check if the non-tasks sub-fields are empty and return an error message if so
        if (subKey !== "tasks" && !subValue) {
            return {
                status: false,
                errorMessage: `The "${
                    FIELD_NAME_TITLE["content"][subKey] || ""
                }" field of ${language} is required!`,
            };
        }

        // If the subKey is "tasks", perform validation on the tasks array
        if (subKey === "tasks" && type === "learn_language") {
            for (let task of subValue as any) {
                // Iterate over the subtasks of each task
                for (let subTask of task.subtasks as any) {
                    // Check if the "title" field of each subtask is empty and return an error message if so
                    if (!subTask["title"]) {
                        return {
                            status: false,
                            errorMessage: `The "${
                                FIELD_NAME_TITLE["content"]["taskTitle"] || ""
                            }" field at "${
                                task["label"]
                            }" of ${language} is required!`,
                        };
                    }
                }
            }
        }
    }
    return {
        status: true,
        errorMessage: null,
    };
}

export default function validateForm(
    scenario: IScenarioTopic
): IValidateReturn {
    // Iterate over the entries of the scenario object
    for (let [key, value] of Object.entries(scenario)) {
        // Skip the "image_url" key
        if (key === "image_url" || key === "gif_url" || key === "is_free")
            continue;

        // Check if the non-content fields are empty and return an error message if so
        if (key !== "content" && !value) {
            console.log("🚀 ~ key:", key);
            return {
                status: false,
                errorMessage: `The "${
                    FIELD_NAME_TITLE[key] || ""
                }" field is required!`,
            };
        }

        // If the key is "content", perform a deeper validation
        if (key === "content") {
            return validateTranslateForm(value, scenario.type);
        }
    }

    // If all validations pass, return a success status
    return {
        status: true,
        errorMessage: null,
    };
}

export function vocabularyMapping(vocabulary: any): IVocabularyItem {
    const vocabularyStandard = {
        id: undefined,
        word: "",
        type: "",
        word_phonics_pronounce: "",
        word_variant_pronounce: "",
        example_sentence: "",
        example_variant_pronounce: "",
        example_phonics_pronounce: "",
    };

    for (const [key, value] of Object.entries(vocabulary)) {
        if (vocabularyStandard.hasOwnProperty(key)) {
            //@ts-ignore
            vocabularyStandard[key] = value;
        }
    }

    return vocabularyStandard;
}
