<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.31409 13.6726C6.20083 13.9529 5.91625 14.1279 5.61607 14.0921C2.72995 13.7481 1.66669 12.759 1.66669 10.0487C1.66669 6.58937 3.39897 5.93398 8.44314 5.84533C8.9384 5.83662 9.27806 6.33772 9.09248 6.79698C8.98709 7.05779 8.73513 7.22926 8.45388 7.23426C7.58626 7.24969 6.83716 7.28201 6.18842 7.34167C5.17089 7.43526 4.50076 7.58863 4.05441 7.78732C3.65124 7.96678 3.45515 8.17025 3.32649 8.42188C3.17496 8.71827 3.05558 9.20646 3.05558 10.0487C3.05558 10.891 3.17496 11.3792 3.32649 11.6756C3.45515 11.9272 3.65124 12.1307 4.05441 12.3101C4.50076 12.5088 5.17089 12.6622 6.18842 12.7558C6.42523 12.7776 6.57759 13.0206 6.48849 13.241L6.31409 13.6726ZM10.9588 13.2996C11.0641 13.039 11.3159 12.8675 11.597 12.8623C12.4431 12.8465 13.1756 12.8143 13.8116 12.7558C14.8292 12.6622 15.4993 12.5088 15.9456 12.3101C16.3488 12.1307 16.5449 11.9272 16.6735 11.6756C16.8251 11.3792 16.9445 10.891 16.9445 10.0487C16.9445 9.20646 16.8251 8.71827 16.6735 8.42188C16.5449 8.17025 16.3488 7.96678 15.9456 7.78732C15.4993 7.58863 14.8292 7.43526 13.8116 7.34167C13.599 7.32212 13.4625 7.10383 13.5425 6.90585L13.7346 6.43044C13.8481 6.1496 14.1335 5.97455 14.4341 6.01144C17.2824 6.36088 18.3333 7.35426 18.3333 10.0487C18.3333 13.4963 16.6129 14.159 11.6084 14.2512C11.113 14.2603 10.7732 13.7591 10.9588 13.2996Z"
      fill="currentColor"
    />
    <path
      d="M6.66669 16.6667L12.5 3.33342"
      stroke="currentColor"
      stroke-width="1.38889"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
