<script lang="ts" src="./show-translate-language.ts"></script>
<style lang="scss" scoped src="./show-translate-language.scss"></style>

<template>
  <div class="modal fade" ref="show-translate-language-modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-center" style="border: 0">
          <h5 class="modal-title">8 languages</h5>
        </div>
        <div class="modal-body text-start d-grid gap-3" style="max-height: 500px; overflow-y: scroll;">
          
          <div :key="index" v-for="(item,key, index) in translateArray" class="d-flex justify-content-start gap-3">
            <img class="align-self-baseline" :src="require(`@/assets/images/icons/svg/${key}.svg`)" :alt="key" />
            <p>{{item}}</p>
          </div>

        </div>
        <div class="modal-footer d-flex justify-content-center" style="border: 0">
          <button
            type="button"
            class="button-solid"
            style="width: 250px"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
