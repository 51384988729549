import {
  StatisticDataFromAPI,
  StatisticKey,
  InsightDataFromAPI,
  InsightDataForVisualization,
  CognitiveDataFromAPI,
  CognitiveDataForVisualization,
  CognitiveKey,
  LearningProcessDataFromAPI,
  LearningProcessKey,
  LearningProcessDataForVisualization,
  CountryCode,
} from "./type";

// ******************************** ## *****************************************
// ************************* CHILD'S LEARNING PROCESS DEFAULT DATA *************************

export const LEARNING_PROCESS_DEFAULT_DATA_FROM_API: LearningProcessDataFromAPI =
  {
    total_learn_language: [
      {
        avatar_url: "https://flipjungle.com/img/defaultAvatar.ddbd8324.svg",
        user_id: 0,
        count: 0,
      },
    ],
    total_tutor_session: [
      {
        avatar_url: "https://flipjungle.com/img/defaultAvatar.ddbd8324.svg",
        user_id: 0,
        count: 0,
      },
    ],
    total_vocab_learned: [
      {
        avatar_url: "https://flipjungle.com/img/defaultAvatar.ddbd8324.svg",
        user_id: 0,
        count: 0,
      },
    ],
  };

export const LEARNING_PROCESS_DEFAULT_DATA_FOR_VISUALIZATION: LearningProcessDataForVisualization =
  {
    total_learn_language: {
      icon: "process-learn-language",

      title: "Roleplay for learn",
      description: "Total sessions with a tutor",
      type: "scenario",
      listChildren: LEARNING_PROCESS_DEFAULT_DATA_FROM_API.total_tutor_session,
    },
    total_tutor_session: {
      icon: "process-tutor-session",
      title: "Tutor Session",
      description: "Explore subjects and develop skills with tutors",
      type: "scenario",
      listChildren: LEARNING_PROCESS_DEFAULT_DATA_FROM_API.total_learn_language,
    },

    total_vocab_learned: {
      icon: "process-vocab-learned",
      title: "Vocabulary",
      description: "Total words learned by the child",
      type: "word",
      listChildren: LEARNING_PROCESS_DEFAULT_DATA_FROM_API.total_vocab_learned,
    },
  };

export const LEARNING_PROCESS_KEYS: LearningProcessKey[] = [
  "total_learn_language",
  "total_tutor_session",
  "total_vocab_learned",
];

// ******************************** ## *****************************************
// ************************* CHAT INSIGHT DEFAULT DATA *************************

// This is what the API will return
export const INSIGHT_DEFAULT_API_DATA: InsightDataFromAPI = {
  avg_negative_score: 0,
  avg_positive_score: 0,
  list_keyword: [],
  list_topic: [],
};

// We will convert the data from API to this type for visualization
export const INSIGHT_DEFAULT_VISUALIZATION_DATA: InsightDataForVisualization = {
  positiveScore: {
    icon: "insight-positive",
    title: "Positive Sentiments",
    subtitle: "",
  },
  negativeScore: {
    icon: "insight-negative",
    title: "Negative Sentiments",
    subtitle: "",
  },
  topic: {
    icon: "insight-topic",
    title: "Top Topic",
    subtitle: "",
  },
  keyword: {
    icon: "insight-keyword",
    title: "Most Used Keywords",
    subtitle: "",
  },
};

// This will be used to map the Boxes
export const INSIGHT_KEYS = Object.keys(INSIGHT_DEFAULT_VISUALIZATION_DATA);

// ******************************** ## ************************************
// ************************* CHAT STATISTICS DATA *************************

export const STATISTIC_DEFAULT_VALUE = { value: 0, goal: 0 };

// This is what the API will return
export const STATISTIC_DEFAULT_API_DATA: StatisticDataFromAPI = {
  completed_tasks: STATISTIC_DEFAULT_VALUE,
  longest_streak: STATISTIC_DEFAULT_VALUE,
  number_diary: STATISTIC_DEFAULT_VALUE,
  session_time: STATISTIC_DEFAULT_VALUE,
  tutor_session: STATISTIC_DEFAULT_VALUE,
  words_learned: STATISTIC_DEFAULT_VALUE,
};

// We will use this plus the API data to visualize
export const STATISTICS_INFO = {
  session_time: {
    description: "Time Spent Learning",
    unit: "hours",
    icon: "clock",
  },
  longest_streak: {
    description: "Longest Streak",
    unit: "days",
    icon: "calendar",
  },
  completed_tasks: {
    description: "Completed tasks",
    unit: "%",
    icon: "target",
  },
  words_learned: {
    description: "Words Learned",
    unit: "",
    icon: "book",
  },
  tutor_session: {
    description: "Tutor session",
    unit: "",
    icon: "laptop",
  },
  number_diary: {
    description: "Diary",
    unit: "",
    icon: "diary",
  },
};

// This will be used to map the Boxes
export const STATISTICS_KEYS = Object.keys(STATISTICS_INFO) as StatisticKey[];

// ******************************** ## ********************************************************
// ************************* COGNITIVE ASSESSMENT REVIEW DEFAULT DATA *************************

export const COGNITIVE_DEFAULT_API_DATA: CognitiveDataFromAPI = {
  user_id: 0,
  critical_thinking: 0,
  emotional_awareness: 0,
  creative_thinking: 0,
  communication: 0,
  problem_solving: 0,
  created_date: "2024-10-18T07:40:10.623Z",
  updated_date: "2024-10-18T07:40:10.623Z",
};

export const COGNITIVE_DEFAULT_VISUALIZATION_DATA: CognitiveDataForVisualization =
  {
    critical_thinking: {
      icon: "cognitive-critical",
      title: "Critical Thinking",
      subtitle:
        "Excellent leadership skills, can motivate and guide others effectively",
      value: "0",
    },
    emotional_awareness: {
      icon: "cognitive-emotional",
      title: "Emotional Awareness",
      subtitle:
        "Adequate ability to recognize and manage emotions, interacts well with others",
      value: "0",
    },
    communication: {
      icon: "cognitive-communication",
      title: "Communication",
      subtitle: "Needs improvement in communication for better performance",
      value: "0",
    },
    problem_solving: {
      icon: "cognitive-problem",
      title: "Problem Solving",
      subtitle:
        "Strong problem-solving abilities, can analyze and find effective solutions",
      value: "0",
    },
    creative_thinking: {
      icon: "cognitive-creative",
      title: "Creative Thinking",
      subtitle: "Highly creative, comes up with unique ideas and approaches",
      value: "0",
    },
  };

// This will be used to map the Boxes
export const COGNITIVE_KEYS = Object.keys(
  COGNITIVE_DEFAULT_VISUALIZATION_DATA
) as CognitiveKey[];
// ******************************** ## *******************************************************
// ************************* SELECT - OPTIONS VALUES FOR FILTER DATA *************************

export const WEEKS_SELECTION = [
  {
    value: 1,
    label: "This Week",
  },
  {
    value: 2,
    label: "Last Week",
  },
];

// ******************************** ## *******************************************************
// ************************* RADAR CHART - FOR COGNITIVE ASSESSMENT REVIEW *************************

export const COGNITIVE_CHART_DATA = {
  labels: [
    ["Critical Thinking"],
    ["Emotional", "Awareness"],
    ["Communication"],
    ["Problem Solving"],
    ["Creative", "Thinking"],
  ],
  backgroundColor: "#fff",
  datasets: [
    {
      fill: true,
      borderColor: "#13A7A4",
      backgroundColor: "#E9F5E94D",
      pointBackgroundColor: "#13A7A4",
      tension: 0.1,
      data: [],
    },
  ],
};

export const COGNITIVE_CHART_OPTIONS = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        title: (data: any) => {
          return data[0].raw;
        },
        label: (data: any) => {
          return null;
        },
      },
      padding: {
        top: 10,
        bottom: 0,
        left: 20,
        right: 20,
      },
      titleAlign: "center",
      titleFont: {
        weight: "500",
        size: 14,
        family: "Poppins",
      },
      cornerRadius: 10,
      backgroundColor: "rgba(0,0,0,0.5)",
      caretPadding: 15,
    },
  },
  scales: {
    r: {
      beginAtZero: true,
      max: 5,
      backgroundColor: "rgba(100, 197, 80, 0.16)",
      ticks: {
        display: false,
        stepSize: 1,
      },
      pointLabels: {
        font: {
          size: "12px",
          family: "Poppins",
        },
        padding: 16,
      },
    },
  },

  responsive: true,
  elements: {
    line: {
      borderWidth: 2,
    },
  },
};

// ******************************** ## *******************************************************
// ************************* FOR KIDS ACTIVITIES *************************

export const LIST_MOODS_AI_DIARY: any = {
  1: { name: "Frustrated", imgUrl: "ft-angry" },
  2: { name: "Curious", imgUrl: "ft-curious" },
  3: { name: "Sad", imgUrl: "ft-upset" },
  4: { name: "Encouraging", imgUrl: "ft-good" },
  5: { name: "Happy", imgUrl: "ft-happy" },
  6: { name: "Inspired", imgUrl: "ft-amazing" },
};

export const LIST_ACTIVITY_TYPES = [
  {
    label: "All",
    value: 0,
  },
  // My Chat is not use for now
  // {
  //   label: "My Chat",
  //   value: 1,
  // },
  {
    label: "My Diary",
    value: 2,
  },
  // Chat Insight is not use for now
  //   {
  //     label: "Chat Insight",
  //     value: 3,
  //   },

  // Badge is not use for now
  // {
  //   label: "Badge",
  //   value: 4,
  // },
  {
    label: "Tutor Session",
    value: 5,
  },
  {
    label: "Language Mastery",
    value: 6,
  },
  {
    label: "Practice",
    value: 7,
  },
  {
    label: "Learn Vocabulary",
    value: 8,
  },
];

// ******************************** ## *******************************************************
// ************************* FOR MEETING FORM *************************
export const COUNTRY_CODES: CountryCode[] = [
  { label: "+1 (USA)", value: "1" },
  { label: "+44 (UK)", value: "44" },
  { label: "+84 (VN)", value: "84" },
  { label: "+244 (AO)", value: "244" },  // Angola
  { label: "+229 (BJ)", value: "229" },  // Benin
  { label: "+267 (BW)", value: "267" },  // Botswana
  { label: "+226 (BF)", value: "226" },  // Burkina Faso
  { label: "+257 (BI)", value: "257" },  // Burundi
  { label: "+237 (CM)", value: "237" },  // Cameroon
  { label: "+238 (CV)", value: "238" },  // Cape Verde
  { label: "+236 (CF)", value: "236" },  // Central African Republic
  { label: "+235 (TD)", value: "235" },  // Chad
  { label: "+269 (KM)", value: "269" },  // Comoros
  { label: "+242 (CG)", value: "242" },  // Congo
  { label: "+243 (CD)", value: "243" },  // Democratic Republic of the Congo
  { label: "+253 (DJ)", value: "253" },  // Djibouti
  { label: "+240 (GQ)", value: "240" },  // Equatorial Guinea
  { label: "+291 (ER)", value: "291" },  // Eritrea
  { label: "+251 (ET)", value: "251" },  // Ethiopia
  { label: "+241 (GA)", value: "241" },  // Gabon
  { label: "+220 (GM)", value: "220" },  // Gambia
  { label: "+233 (GH)", value: "233" },  // Ghana
  { label: "+224 (GN)", value: "224" },  // Guinea
  { label: "+245 (GW)", value: "245" },  // Guinea-Bissau
  { label: "+254 (KE)", value: "254" },  // Kenya
  { label: "+266 (LS)", value: "266" },  // Lesotho
  { label: "+231 (LR)", value: "231" },  // Liberia
  { label: "+261 (MG)", value: "261" },  // Madagascar
  { label: "+265 (MW)", value: "265" },  // Malawi
  { label: "+223 (ML)", value: "223" },  // Mali
  { label: "+222 (MR)", value: "222" },  // Mauritania
  { label: "+230 (MU)", value: "230" },  // Mauritius
  { label: "+258 (MZ)", value: "258" },  // Mozambique
  { label: "+264 (NA)", value: "264" },  // Namibia
  { label: "+227 (NE)", value: "227" },  // Niger
  { label: "+234 (NG)", value: "234" },  // Nigeria
  { label: "+250 (RW)", value: "250" },  // Rwanda
  { label: "+239 (ST)", value: "239" },  // Sao Tome and Principe
  { label: "+221 (SN)", value: "221" },  // Senegal
  { label: "+248 (SC)", value: "248" },  // Seychelles
  { label: "+232 (SL)", value: "232" },  // Sierra Leone
  { label: "+252 (SO)", value: "252" },  // Somalia
  { label: "+27 (ZA)", value: "27" },    // South Africa
  { label: "+211 (SS)", value: "211" },  // South Sudan
  { label: "+249 (SD)", value: "249" },  // Sudan
  { label: "+268 (SZ)", value: "268" },  // Swaziland
  { label: "+255 (TZ)", value: "255" },  // Tanzania
  { label: "+228 (TG)", value: "228" },  // Togo
  { label: "+256 (UG)", value: "256" },  // Uganda
  { label: "+260 (ZM)", value: "260" },  // Zambia
  { label: "+263 (ZW)", value: "263" },  // Zimbabwe
  { label: "+86 (CN)", value: "86" },    // China
  { label: "+81 (JP)", value: "81" },    // Japan
  { label: "+82 (KR)", value: "82" },    // South Korea
  { label: "+850 (KP)", value: "850" },  // North Korea
  { label: "+976 (MN)", value: "976" },  // Mongolia
  { label: "+886 (TW)", value: "886" },  // Taiwan
  { label: "+43 (AT)", value: "43" },    // Austria
  { label: "+32 (BE)", value: "32" },    // Belgium
  { label: "+33 (FR)", value: "33" },    // France
  { label: "+49 (DE)", value: "49" },    // Germany
  { label: "+353 (IE)", value: "353" },  // Ireland
  { label: "+352 (LU)", value: "352" },  // Luxembourg
  { label: "+377 (MC)", value: "377" },  // Monaco
  { label: "+31 (NL)", value: "31" },    // Netherlands
  { label: "+351 (PT)", value: "351" },  // Portugal
  { label: "+41 (CH)", value: "41" },    // Switzerland
  { label: "+44 (GB)", value: "44" },    // United Kingdom
  { label: "+39 (IT)", value: "39" },    // Italy
  { label: "+30 (GR)", value: "30" },    // Greece
  { label: "+356 (MT)", value: "356" },  // Malta
  { label: "+34 (ES)", value: "34" },    // Spain
  { label: "+1 (AG)", value: "1" },      // Antigua and Barbuda
  { label: "+1 (BS)", value: "1" },      // Bahamas
  { label: "+1 (BB)", value: "1" },      // Barbados
  { label: "+501 (BZ)", value: "501" },  // Belize
  { label: "+1 (CA)", value: "1" },      // Canada
  { label: "+506 (CR)", value: "506" },  // Costa Rica
  { label: "+53 (CU)", value: "53" },    // Cuba
  { label: "+1 (DM)", value: "1" },      // Dominica
  { label: "+1 (DO)", value: "1" },      // Dominican Republic
  { label: "+503 (SV)", value: "503" },  // El Salvador
  { label: "+1 (GD)", value: "1" },      // Grenada
  { label: "+502 (GT)", value: "502" },  // Guatemala
  { label: "+509 (HT)", value: "509" },  // Haiti
  { label: "+504 (HN)", value: "504" },  // Hondura
  { label: "+1 (JM)", value: "1" },     // Jamaica
  { label: "+52 (MX)", value: "52" },    // Mexico
  { label: "+505 (NI)", value: "505" },  // Nicaragua
  { label: "+507 (PA)", value: "507" },  // Panama
  { label: "+1 (KN)", value: "1" },     // Saint Kitts and Nevis
  { label: "+1 (LC)", value: "1" },     // Saint Lucia
  { label: "+1 (VC)", value: "1" },     // Saint Vincent and the Grenadines
  { label: "+1 (TT)", value: "1" },     // Trinidad and Tobago
  { label: "+1 (US)", value: "1" },     // United States
  { label: "+54 (AR)", value: "54" },   // Argentina
  { label: "+591 (BO)", value: "591" }, // Bolivia
  { label: "+55 (BR)", value: "55" },   // Brazil
  { label: "+56 (CL)", value: "56" },   // Chile
  { label: "+57 (CO)", value: "57" },   // Colombia
  { label: "+593 (EC)", value: "593" }, // Ecuador
  { label: "+592 (GY)", value: "592" }, // Guyana
  { label: "+595 (PY)", value: "595" }, // Paraguay
  { label: "+51 (PE)", value: "51" },   // Peru
  { label: "+597 (SR)", value: "597" }, // Suriname
  { label: "+598 (UY)", value: "598" }, // Uruguay
  { label: "+58 (VE)", value: "58" },   // Venezuela
  { label: "+61 (AU)", value: "61" },   // Australia
  { label: "+679 (FJ)", value: "679" }, // Fiji
  { label: "+686 (KI)", value: "686" }, // Kiribati
  { label: "+692 (MH)", value: "692" }, // Marshall Islands
  { label: "+691 (FM)", value: "691" }, // Micronesia
  { label: "+674 (NR)", value: "674" }, // Nauru
  { label: "+64 (NZ)", value: "64" },   // New Zealand
  { label: "+680 (PW)", value: "680" }, // Palau
  { label: "+675 (PG)", value: "675" }, // Papua New Guinea
  { label: "+685 (WS)", value: "685" }, // Samoa
  { label: "+677 (SB)", value: "677" }, // Solomon Islands
  { label: "+676 (TO)", value: "676" }, // Tonga
  { label: "+688 (TV)", value: "688" }, // Tuvalu
  { label: "+678 (VU)", value: "678" }, // Vanuatu
  { label: "+213 (DZ)", value: "213" }, // Algeria
  { label: "+973 (BH)", value: "973" }, // Bahrain
  { label: "+20 (EG)", value: "20" },     // Egypt
  { label: "+964 (IQ)", value: "964" },   // Iraq
  { label: "+98 (IR)", value: "98" },     // Iran
  { label: "+972 (IL)", value: "972" },   // Israel
  { label: "+962 (JO)", value: "962" },   // Jordan
  { label: "+965 (KW)", value: "965" },   // Kuwait
  { label: "+961 (LB)", value: "961" },   // Lebanon
  { label: "+218 (LY)", value: "218" },   // Libya
  { label: "+212 (MA)", value: "212" },   // Morocco
  { label: "+968 (OM)", value: "968" },   // Oman
  { label: "+970 (PS)", value: "970" },   // Palestine
  { label: "+974 (QA)", value: "974" },   // Qatar
  { label: "+966 (SA)", value: "966" },   // Saudi Arabia
  { label: "+963 (SY)", value: "963" },   // Syria
  { label: "+216 (TN)", value: "216" },   // Tunisia
  { label: "+971 (AE)", value: "971" },   // United Arab Emirates
  { label: "+967 (YE)", value: "967" },   // Yemen
  { label: "+45 (DK)", value: "45" },     // Denmark
  { label: "+47 (NO)", value: "47" },     // Norway
  { label: "+46 (SE)", value: "46" },     // Sweden
  { label: "+358 (FI)", value: "358" },   // Finland
  { label: "+354 (IS)", value: "354" },   // Iceland
  // East Europe countries
  { label: "+375 (BY)", value: "375" },   // Belarus
  { label: "+359 (BG)", value: "359" },   // Bulgaria
  { label: "+420 (CZ)", value: "420" },   // Czech Republic
  { label: "+36 (HU)", value: "36" },     // Hungary
  { label: "+48 (PL)", value: "48" },     // Poland
  { label: "+373 (MD)", value: "373" },   // Moldova
  { label: "+40 (RO)", value: "40" },     // Romania
  { label: "+7 (RU)", value: "7" },       // Russia
  { label: "+421 (SK)", value: "421" },   // Slovakia
  { label: "+380 (UA)", value: "380" },   // Ukraine
  // Southeast Asia countries
  { label: "+673 (BN)", value: "673" },   // Brunei
  { label: "+855 (KH)", value: "855" },   // Cambodia
  { label: "+62 (ID)", value: "62" },     // Indonesia
  { label: "+856 (LA)", value: "856" },   // Laos
  { label: "+60 (MY)", value: "60" },     // Malaysia
  { label: "+95 (MM)", value: "95" },     // Myanmar
  { label: "+63 (PH)", value: "63" },     // Philippines
  { label: "+65 (SG)", value: "65" },     // Singapore
  { label: "+66 (TH)", value: "66" },     // Thailand
  // South Asia countries
  { label: "+93 (AF)", value: "93" },     // Afghanistan
  { label: "+880 (BD)", value: "880" },   // Bangladesh
  { label: "+975 (BT)", value: "975" },   // Bhutan
  { label: "+91 (IN)", value: "91" },     // India
  { label: "+960 (MV)", value: "960" },   // Maldives
  { label: "+977 (NP)", value: "977" },   // Nepal
  { label: "+92 (PK)", value: "92" },     // Pakistan
  { label: "+94 (LK)", value: "94" },     // Sri Lanka

  // Add more country codes as needed
];

