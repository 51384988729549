
class UsersConstants {
    public static dataSort = {
        learner: ['id',
            'username',
            'avatar_url',
            'display_name',
            'date_of_birth',
            'package_name',
            'package_end_date',
            'period_status',
            'parent_email',
            'gender',
            'email',
            'nationality',],
        learner_free: ['id',
            'username',
            'avatar_url',
            'display_name',
            'date_of_birth',
            'period_status',
            'parent_email',
            'gender',
            'email',
            'nationality',],
        parent: ['id',
            'username',
            'avatar_url',
            'display_name',
            'package_name',
            'package_end_date',
            'period_status',
            'email',
            'gender',
            'nationality',],
        parent_free: ['id',
            'username',
            'avatar_url',
            'display_name',
            'period_status',
            'email',
            'gender',
            'nationality',]
    }
    public static headerCell = {
        learner: {
            'id': 'Id',
            'username': 'Username',
            'display_name': 'Display Name',
            'date_of_birth': 'DOB',
            'package_name': 'Packages',
            'package_end_date': 'End Date',
            'parent_email': 'Parent email',
            'gender': 'Gender',
            'email': 'Email',
            'nationality': 'Country',
        },
        learner_free: {
            'id': 'Id',
            'username': 'Username',
            'display_name': 'Display Name',
            'date_of_birth': 'DOB',
            'parent_email': 'Parent email',
            'gender': 'Gender',
            'email': 'Email',
            'nationality': 'Country',
        },
        parent: {
            'id': 'Id',
            'username': 'Username',
            'display_name': 'Display Name',
            'package_name': 'Packages',
            'package_end_date': 'End Date',
            'email': 'Email',
            'gender': 'Gender',

            'nationality': 'Country',
        },
        parent_free: {
            'id': 'Id',
            'username': 'Username',
            'display_name': 'Display Name',
            'email': 'Email',
            'gender': 'Gender',
            'nationality': 'Country',
        }
    }

    public static allowShowHeader = {
        learner: [
            'id',
            'display_name',
            'username',
            'date_of_birth',
            'package_name',
            'package_end_date',
            'parent_email',
            'gender',
            'email',
            'nationality',
        ],
        learner_free: [
            'id',
            'display_name',
            'username',
            'date_of_birth',
            'parent_email',
            'gender',
            'email',
            'nationality',
        ],
        parent: [
            'id',
            'display_name',
            'username',
            'package_name',
            'package_end_date',
            'email',
            'gender',
            'nationality',
        ],
        parent_free: [
            'id',
            'display_name',
            'username',
            'email',
            'gender',
            'nationality',
        ]
    }

    public static allowEditList = {
        learner: ['display_name', 'gender', 'nationality'],
        parent: ['display_name', 'gender', 'nationality']
    }


    public static tabsFilter = {

    };


    public static getKeysHeaderCell() {
        return Object.keys(this.headerCell)
    }
    public static getValuesHeaderCell() {
        return Object.values(this.headerCell)
    }

    public static getKeysTabFilter() {
        return Object.keys(this.tabsFilter)
    }
    public static getValuesTabFilter() {
        return Object.values(this.tabsFilter)
    }
}

export default UsersConstants
