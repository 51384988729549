import { Auth } from 'aws-amplify';
import router from '@/router';
import { ROUTE_PATHS } from '@/constants/routeNames';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { toast } from 'vue3-toastify';
import MainService from '@/services'
class AmplifyServices {
    private congitoUser = new BehaviorSubject<any>(null);
    public setCognitoUser(user: any) {
        this.congitoUser.next(user);
    }

    constructor() {
        // Constructor can be used for initialization if needed
    }


    /**
     * Log in a user with given credentials.
     * @param {string} username - The user's username.
     * @param {string} password - The user's password.
     * @returns {Promise} A promise that resolves upon successful authentication.
     */
    async login(username: string, password: string) {
        try {
            await Auth.signIn(username, password)
                .then(async (data) => {
                    if (data) {
                        if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
                            // Sign up first time require to change password.
                            this.congitoUser.next(data);
                            router.push(ROUTE_PATHS.CHANGE_PASSWORD)
                        } else {
                            await MainService.setUserDataGlobal();
                            router.push('/')
                        }
                    }
                });
        } catch (error:any) {
            toast.error(error.message)
        }
    }

    /**
     * Sign up a new user.
     * @param {string} username - The desired username.
     * @param {string} password - The desired password.
     * @param {object} attributes - Additional attributes such as email, phone number, etc.
     * @returns {Promise} A promise that resolves upon successful signup.
     */
    async signup(username: string, password: string, attributes: any) {
        try {
            const signUpResponse = await Auth.signUp({
                username,
                password,
                attributes,
            });
            return signUpResponse;
        } catch (error) {
            console.error('Error signing up:', error);
            throw error;
        }
    }

    /**
     * Initiate a password reset for a user.
     * @param {string} username - The username of the user who forgot their password.
     * @returns {Promise} A promise that resolves when the password reset is initiated.
     */
    async forgotPassword(username: string) {
        try {
            const response = await Auth.forgotPassword(username);
            return response;
        } catch (error) {
            console.error('Error in forgot password process:', error);
            throw error;
        }
    }

    /**
     * Submits a new password for a user who has forgotten their password.
     * This function is part of the password recovery process. After the user receives a
     * verification code (usually via email), this function submits the code along with
     * the user's username and a new password.
     *
     * @param {string} username - The username of the user who forgot their password.
     * @param {string} code - The verification code sent to the user.
     * @param {string} newPassword - The new password that the user wants to set.
     *
     * @returns {Promise<void>} A promise that resolves when the password has been successfully
     *                          reset, or rejects with an error if the process fails.
     */


    async forgotPasswordSubmit(
        username: string,
        code: string,
        newPassword: string
    ) {
        try {
            const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
        } catch (err) {
            console.log(err);
        }
    }

    /**
    * Changes the password of the currently authenticated user.
    * This function first retrieves the current authenticated user and then attempts to
    * change their password. It requires the user's old password for verification and
    * a new password to set.
    *

    * @param {string} oldPassword - The current password of the user.
    * @param {string} newPassword - The new password that the user wants to set.
    *
    * @returns {Promise<void>} A promise that resolves when the password has been successfully
    *                          changed, or rejects with an error if the process fails.
    */
    async changePassword(oldPassword: string, newPassword: string) {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const data = await Auth.changePassword(user, oldPassword, newPassword);
            return data
        } catch (err: any) {
            
            if (err.message === 'Incorrect username or password.') {
                toast.error('Current password is not correct.')
                return null;
            }
            toast.error(err.message)
            return null
        }
    }


    async completeNewPassword( newPassword: string, requiredAttributes = {}) {
        try {
            return this.congitoUser.subscribe(async (value) => {

                if (!value) {
                    console.error("Cognito user is null or undefined.");
                    return;
                }

                // Complete the new password challenge
                await Auth.completeNewPassword(
                    value,
                    newPassword,
                    requiredAttributes  // the required attributes
                );

                await MainService.setUserDataGlobal();
                router.push('/')
            });
        } catch (error) {
            console.error('Error while changing password', error);
            // Handle the error, such as showing an error message to the user
        }
    }
}

export default new AmplifyServices()
