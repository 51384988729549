<style lang="scss" scoped src="./main-layout.scss"></style>
<script lang="ts" src="./main-layout.ts"></script>

<!-- <template>
    <div  class="d-flex flex-row container-fluid" style="background: #e8f3ec; gap: 24px; overflow: auto">
        <SideBar v-if="userData" :userData="userData" />
        <div class="d-flex flex-column" style="padding: 24px 24px 0 0; gap: 24px; width: 100%">
            <Header />
            <div class="pb-5" style="max-height: ( 100vh - 94px); gap:24px;" >
                <router-view />
            </div>
        </div>
    </div>
</template> -->

<template>
  <LayoutMainDashboard>
    <template #left-menu>
      <SideBar v-if="userData" :userData="userData" />
    </template>
    <div class="layout-main-dashboard">
      <Header />
      <router-view />
    </div>
  </LayoutMainDashboard>
  <div class="" style="position: fixed; z-index: 1051">
    <ModalCategory ref="modal-category" :categoryDataProps="categories" />
  </div>
  <div class="" style="position: fixed; z-index: 1051">
    <ModalTopicVideoCall ref="modal-topic" />
  </div>
</template>
