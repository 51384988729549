<script lang="ts" setup>
import { defineProps } from "vue";
import { ChildInformation } from "../../type";
import Tab from "./Tab.vue";

interface Props {
  data: ChildInformation[];
  selectedValue: number;
}
const props = withDefaults(defineProps<Props>(), {
  data: () => [],
  selectedValue: 0,
});
const emits = defineEmits(["on-select"]);

function onSelect(id: number) {
  emits("on-select", id);
}
</script>

<template>
  <div class="childTabs">
    <Tab
      v-for="item in data"
      :key="item.id"
      :id="item.id"
      :title="item.display_name"
      :image="item.avatar_url"
      :is-active="selectedValue === item.id"
      @on-select="onSelect"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";
.childTabs {
  @include flexbox(row, space-around, center, null, null, 8px);
  width: 100%;
}
</style>
