<!-- src/components/Modal.vue -->
<template>
  <div
    class="modal fade"
    tabindex="-1"
    aria-labelledby="modalTitle"
    aria-hidden="true"
    ref="modal"
    role="dialog"
    :id="id"
  >
    <div
      class="modal-dialog modal-dialog-centered custom-dialog"
      :style="{ maxWidth: maxWidth ? maxWidth : '' }"
    >
      <div class="modal-content custom-dialog-content">
        <div class="modal-header custom-dialog-header">
          <h5 class="modal-title custom-dialog-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body custom-dialog-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-dialog {
  &-header {
    width: 100%;
  }
  &-title {
    font-weight: bold;
    text-align: center;
    font-size: 20px !important;
  }
  &-body {
    width: 100%;
    font-size: 16px;
  }
}
</style>

<script setup lang="ts">
import { Modal } from "bootstrap";
import { onBeforeUnmount, onMounted, ref } from "vue";

const props = defineProps({
  title: {
    type: String,
    default: "Modal Title",
  },
  id: {
    type: String,
    default: "",
  },
  maxWidth: {
    required: false,
    type: String,
    default: "",
  },
});

const modal = ref<HTMLDivElement | null>(null);
let modalInstance: Modal | null = null;

onMounted(() => {
  const commonModal = document.getElementById(props.id);
  document.getElementsByTagName("body")[0].appendChild(commonModal as Node);
  if (modal.value) {
    modalInstance = new Modal(modal.value);
  }
});

onBeforeUnmount(() => {
  if (modalInstance) {
    modalInstance.dispose();
  }
});

const show = () => {
  modalInstance?.show();
};

const hide = () => {
  modalInstance?.hide();
};

// Expose the show and hide functions to the parent component
const modalMethods = {
  show,
  hide,
};

defineExpose(modalMethods);
</script>
