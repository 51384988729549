import { ref, onMounted, onUnmounted } from "vue";

type DEVICE_TYPE = "mobile" | "tablet" | "desktop";
export function useDeviceType() {
  const BREAKPOINTS = {
    MOBILE: 768,
    TABLET: 1024,
  };

  const deviceType = ref<DEVICE_TYPE>("desktop");

  function checkDeviceType() {
    const width = window.innerWidth;

    if (width < BREAKPOINTS.MOBILE) {
      deviceType.value = "mobile";
    } else if (width < BREAKPOINTS.TABLET) {
      deviceType.value = "tablet";
    } else {
      deviceType.value = "desktop";
    }
  }

  onMounted(() => {
    window.addEventListener("resize", checkDeviceType);
    checkDeviceType();
  });

  onUnmounted(() => {
    window.removeEventListener("resize", checkDeviceType);
  });

  return { deviceType };
}
