<script lang="ts">
export interface ActivityProps {
  id: number;
  component_id: number;
  component_name: string;
  avatar_url: string;
  description: string;
  display_name: string;
  created_date: string;
  meta_data: {
    // For Vocabulary and Pratice Sentences Only
    sub_title?: string | null;
    title?: string;
  };
  tag: {
    // For the stars
    percent?: number;
    point?: number;

    // For the tag info of the tutor of Tutor Session and Language Mastery
    person_ai_infor?: {
      person_ai_avatar: string;
      person_ai_name: string;
    };

    // For AI Diary
    mood_id?: number;

    // For Vocabulary and Pratice Sentences
    score?: string | number;
  };

  // All of these are optional
  updated_date?: string;
  deleted_at?: string | null;
  user_id?: number;
  sub_id?: number;
  is_view?: boolean;
}
</script>

<script lang="ts" setup>
import { computed, defineProps, ref } from "vue";
import ActivityStars from "./ActivityStars.vue";
import { timeDifference } from "@/utils";
import { LIST_MOODS_AI_DIARY } from "../../constants";
import ActivityTag from "./ActivityTag.vue";

const metaStars = ref(0);
const props = withDefaults(defineProps<ActivityProps>(), {
  avatar_url: "",
  description: "",
  component_id: 0,
  component_name: "",
  created_date: "",
  display_name: "",
  tag: () => ({}),
  meta_data: () => ({}),
});

// Render the title of the box base on the type of the activity
const mainTitle = computed(() => {
  switch (props.component_id) {
    case 1: // Chat Tutor - Not working yet
      return "";
    case 2: // AI Diary
      if (props.tag && props.tag.mood_id) {
        const moodId: number = props.tag.mood_id as number;
        const mood = LIST_MOODS_AI_DIARY[moodId];
        return `${props.display_name} has updated today's diary with a sad status ${mood.name}`;
      }
    case 3: // Chat Insight - Not working yet
      return "";
    case 4: // Badge - Not working yet
      return "";
    case 5: // Tutor Session
      if (props.meta_data) {
        return `${props.display_name} has completed the roleplay "${props.meta_data.title}"`;
      }
    case 6: // Language Mastery
      if (props.meta_data) {
        return `${props.display_name} has completed the roleplay "${props.meta_data.title}"`;
      }
    case 7: // Practice
      if (props.meta_data) {
        return (
          props.display_name +
          ` has completed the lesson from the incorrect sentence "${props.meta_data.title}"`
        );
      }
    case 8: // Learn Vocabulary
      if (props.meta_data) {
        return `${props.display_name} has completed the vocabulary lesson "${props.meta_data.title}"`;
      }
    default:
      return "";
  }
});

// The percent only for langauge mastery activity to show the stars
const percentData = computed(() => {
  // Check if the activity is language mastery
  if (props.component_id === 6) {
    return {
      percent: props.tag.percent,
      points: props.tag.point,
    };
  } else {
    return null;
  }
});

const dataForTag = computed(() => {
  switch (props.component_id) {
    case 1:
      if (props.tag && props.tag.person_ai_infor) {
        return {
          title: props.tag.person_ai_infor.person_ai_name,
          image: props.tag.person_ai_infor.person_ai_avatar,
        };
      }
    case 2:
      if (props.tag && props.tag.mood_id) {
        const moodId: number = props.tag.mood_id as number;
        const mood = LIST_MOODS_AI_DIARY[moodId];
        return {
          title: mood.name,
          image: mood.imgUrl,
          typeImg: "local",
        };
      }
    case 3: // Chat Insight
      if (props.tag) {
        return null;
      }
    case 4: // Badge
      if (props.tag) {
        return null;
      }
    case 5: // Tutor Session
      if (props.tag && props.tag.person_ai_infor) {
        return {
          title: props.tag.person_ai_infor.person_ai_name,
          image: props.tag.person_ai_infor.person_ai_avatar,
        };
      }
    case 6: // Language Mastery
      if (props.tag && props.tag.person_ai_infor) {
        metaStars.value = props.tag.percent || 0;
        return {
          title: props.tag.person_ai_infor.person_ai_name,
          image: props.tag.person_ai_infor.person_ai_avatar,
        };
      }
    case 7: // Practice
      if (props.tag && props.tag.score) {
        return {
          title: props.tag.score + " completed",
          image: "document",
          typeImg: "local",
        };
      }
    case 8: // Learn Vocab
      if (props.tag && props.tag.score) {
        return {
          title: props.tag.score + " completed",
          image: "book",
          typeImg: "local",
        };
      }
    default:
      return null;
  }
});

// Calculate the time base one created data (1 minute ago, 1 week ago, ....)
const timeDiff = computed(() => {
  if (props.created_date) {
    return timeDifference(props.created_date);
  }
});
</script>

<template>
  <div class="actBox">
    <div class="actBox-content">
      <div class="actBox-body">
        <div class="actBox-component">
          <div class="actBox-component-content">
            <div class="actBox-component-avatar">
              <img
                :src="
                  avatar_url
                    ? avatar_url
                    : require(`@/assets/images/icons/svg/defaultAvatar.svg`)
                "
                alt="Avatar"
              />
            </div>
            <div class="actBox-component-text">
              <p>
                {{ mainTitle }}
              </p>
            </div>
          </div>
          <div class="actBox-tags">
            <ActivityTag
              :title="dataForTag?.title"
              :image="dataForTag?.image"
              :imgType="dataForTag?.typeImg"
            />
            <!-- The stars only for langauge mastery activity -->
            <ActivityStars
              v-if="percentData"
              :percent="percentData.percent"
              :point="percentData.points"
            />
          </div>
        </div>
      </div>
      <div
        v-if="
          meta_data && Object.keys(meta_data).length > 0 && meta_data.sub_title
        "
        class="actBox-subComponent"
      >
        <div class="actBox-subComponent-left">
          <img
            v-if="component_name !== 'Practice'"
            src="@/assets/images/icons/png/book.png"
            alt="Document"
          />
          <img
            v-else
            src="@/assets/images/icons/png/document.png"
            alt="Document"
          />
        </div>
        <div class="act-sub-right">
          <p class="actBox-subComponent-right-title">
            Scenario: {{ meta_data.title }}
          </p>
          <p class="actBox-subComponent-right-description">
            <span>{{
              component_name !== "Practice" ? "Vocabulary" : "Explanation"
            }}</span
            >:
            {{ meta_data.sub_title }}
          </p>
        </div>
      </div>
      <div class="actBox-footer">
        <div class="actBox-footer-type">{{ component_name }}</div>
        <div class="actBox-footer-time">{{ timeDiff }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";

.actBox {
  @include size(100%, auto);
  background: #ffffff;
  border-radius: 16px;
  &-content {
    @include flexbox(column, center, center, null, null, 8px);
    padding: 12px;
  }
  &-body {
    @include flexbox(column, flex-start, center, null, null, 8px);
    width: 100%;
    border-radius: 16px;
    background: #ffffff29;
  }
  &-tags {
    @include flexbox(row, flex-end, center, null, null, 8px);
  }
  &-component {
    @include flexbox(row, space-between, center, null, null, 8px);
    width: 100%;
    &-content {
      @include flexbox(row, flex-start, center, null, 1, 8px);
      position: relative;
    }
    &-avatar {
      @include icon(48px, 48px);
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #75d473;
    }

    &-text {
      @include flexbox(row, flex-start, flex, null, 1, 8px);
      padding-right: 16px;
      text-align: left;
      p {
        @include typography(14px, 600, 1.5, normal, $neutral-200);
      }
    }

    @include responsive-for(tablet) {
      @include flexbox(column, flex-start, flex-start, null, null, 8px);
    }
  }

  &-subComponent {
    @include flexbox(row, flex-start, flex-start, null, null, 8px);
    width: 100%;
    &-left {
      @include icon(40px, 40px);
      @include responsive-for(mobile) {
        display: none;
      }
    }
    &-right {
      @include flexbox(column, flex-start, flex-start, null, 1, 4px);
      &-title {
        @include typography(14px, 600, 1.5, normal, $neutral-300);
        text-align: left;
      }
      &-description {
        @include typography(14px, 400, 1.5, normal, $neutral-100);
        text-align: left;
        opacity: 0.5;
      }
    }
  }

  &-footer {
    @include flexbox(row, space-between, center, null, null, 8px);
    @include typography(14px, 400, 1.5, normal, $neutral-400);
    width: 100%;
  }
}
</style>
