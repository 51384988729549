import { Vue, Options } from "vue-class-component";
import { Modal } from "bootstrap";
import AdminsConstants from "@/constants/admins";
import AmplifyServices from "@/services/amplify";
import MainUtils from "@/utils"
import { TransformedActionTypesAdmin } from "@/store/modules/admin/action-style";
@Options({
    props: {
        translateArray: Object
    },
    
    watch: {

    }
})

export default class ShowTranslateLanguage extends Vue {
    public translateArray!:any;

    public openModal() {
        const myModal = new Modal(this.$refs["show-translate-language-modal"] as any)
        myModal.show()
    }


    

    // public closeModal() {
    //     (this.$refs['close-add-admin-modal'] as any).click()
    // }

}