<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8978 4.07906C14.3386 2.51799 12.2649 1.6579 10.0557 1.65698C5.50354 1.65698 1.79871 5.36171 1.79687 9.91514C1.79626 11.3707 2.17651 12.7916 2.89927 14.0441L1.7276 18.3237L6.10575 17.1752C7.31211 17.8332 8.67025 18.18 10.0524 18.1804H10.0558C14.6075 18.1804 18.3128 14.4754 18.3145 9.92175C18.3154 7.71493 17.4572 5.64004 15.8978 4.07906ZM10.0557 16.7857H10.0529C8.82121 16.7852 7.61322 16.4541 6.55914 15.8288L6.30859 15.68L3.71053 16.3616L4.40399 13.8285L4.24072 13.5688C3.55357 12.4759 3.19071 11.2126 3.19132 9.91565C3.19275 6.13096 6.27218 3.05184 10.0585 3.05184C11.892 3.05245 13.6155 3.76737 14.9115 5.06488C16.2075 6.36239 16.9208 8.08704 16.9202 9.92124C16.9185 13.7062 13.8393 16.7857 10.0557 16.7857ZM13.821 11.6446C13.6147 11.5412 12.6001 11.0422 12.4109 10.9732C12.2219 10.9043 12.0841 10.87 11.9467 11.0765C11.8091 11.283 11.4137 11.7479 11.2932 11.8856C11.1728 12.0233 11.0526 12.0406 10.8462 11.9372C10.6397 11.834 9.97487 11.616 9.1866 10.913C8.5732 10.3658 8.15908 9.69002 8.03864 9.48352C7.9184 9.27681 8.03762 9.1759 8.12917 9.06238C8.35256 8.78497 8.57625 8.49414 8.64502 8.35651C8.71389 8.21877 8.6794 8.09823 8.62773 7.99498C8.57625 7.89172 8.16355 6.876 7.99164 6.46269C7.82399 6.06047 7.65401 6.11479 7.52726 6.10848C7.40702 6.10248 7.26939 6.10126 7.13175 6.10126C6.99422 6.10126 6.77063 6.15283 6.58142 6.35954C6.39231 6.56614 5.85927 7.06531 5.85927 8.08103C5.85927 9.09676 6.59871 10.078 6.70186 10.2157C6.80501 10.3535 8.15704 12.4378 10.2271 13.3316C10.7194 13.5444 11.1037 13.6712 11.4035 13.7664C11.8979 13.9234 12.3476 13.9012 12.7032 13.8481C13.0998 13.7888 13.9241 13.3489 14.0962 12.8669C14.2681 12.3848 14.2681 11.9717 14.2164 11.8856C14.1649 11.7995 14.0273 11.7479 13.821 11.6446Z"
      fill="currentColor"
    />
  </svg>
</template>
