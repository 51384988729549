// @ts-nocheck
type ActionTypesAdminType = {
    GET_ADMINS: string,
    UPDATE_ADMIN: string,
    CREATE_ADMIN: string,
    DELETE_ADMIN: string,
    EXPORT_EXCEL: string,
};

export const ActionTypesAdmin: ActionTypesAdminType = {
    GET_ADMINS: 'GET_ADMINS',
    UPDATE_ADMIN: 'UPDATE_ADMIN',
    CREATE_ADMIN: 'CREATE_ADMIN',
    DELETE_ADMIN: 'DELETE_ADMIN',
    EXPORT_EXCEL: 'EXPORT_EXCEL',
};


function transformObjectProperties(targetObject, prefix) {
    const transformedObject = {};

    // Iterate over the object properties
    for (const key in targetObject) {
        transformedObject[key] = `${prefix}/${targetObject[key]}`;
    }

    return transformedObject;
}
;

export const TransformedActionTypesAdmin: ActionTypesAdminType = transformObjectProperties(ActionTypesAdmin, 'admin');
