import { Vue, Options } from "vue-class-component";
import AmplifyServices from "@/services/amplify";
import MainService from "@/services"
import amplify from "@/services/amplify";
import { Amplify } from "aws-amplify";
interface userInputLogin {
    username: string,
    password: string
}

@Options({
    watch: {
        userInput: {
            handler(newVal) {
                this.handleValidInput()
            },
            deep: true
        }
    }
})

export default class LoginView extends Vue {
    public showPassword: boolean = false;
    public userInput: userInputLogin = {
        username: '',
        password: '',
    }
    public userInputAllValid = false
    public invalidMessage = {
        username: '',
        password: ''
    }
    public unmounted(): void {
        this.userInput = {
            username: '',
            password: ''
        }
    }


    public async handleLogin() {
        try {
            await AmplifyServices.login(this.userInput.username, this.userInput.password)
        } catch (error) {
            console.log(error);
        }

    }

    public handleValidInput() {
        this.userInputAllValid = false;
        this.invalidMessage = {
            username: '',
            password: ''
        }

        if (!this.userInput.username) {
            this.invalidMessage.username = `Please input your username.`;
            return;
        }

        if (!this.userInput.password) {
            this.invalidMessage.password = "Please input your password.";
            return;
        }

        this.userInputAllValid = true

    }
}
