import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { Auth } from 'aws-amplify';
import { toast } from "vue3-toastify";
import store from "@/store";
import ActionTypes from "@/store/action-types";
import ApiService from "./api-service";
import MutationTypes from "@/store/mutation-styles";
import GetterTypes from "@/store/getter-types";
import AdminsConstants from '@/constants/admins'
import UsersConstants from "@/constants/users";
import PersonAisConstants from "@/constants/person-ai";
import { TransformedActionTypesAdmin } from "@/store/modules/admin/action-style";
import { ROUTE_NAMES } from "@/constants/routeNames";
class MainService {
    apiService!: any;
    constructor() {
        this.apiService = new ApiService(store)
    }

    async setUserDataGlobal() {

        await this.apiService.handleApiRequest(
            ActionTypes.GET_USER_DATA,
            {},
            (data: any) => {
                store.commit(MutationTypes.SET_ADMIN_DATA, data)

            }
        );
    }

    async createUserMetadataFirstLogin(payload: any) {

        await this.apiService.handleApiRequest(
            TransformedActionTypesAdmin.CREATE_ADMIN,
            payload,
            (data: any) => {
                // console.log(data);
                // store.commit(MutationTypes.SET_ADMIN_DATA, data)

            }
        );
    }
    async setAllCountryGlobal() {

        await this.apiService.handleApiRequest(
            ActionTypes.GET_ALL_COUNTRY,
            {},
            (data: any) => {
                store.commit(MutationTypes.SET_ALL_COUNTRY, data)

            }
        );
    }
    reFormatAdminDatas(rawData: any) {
        return rawData.map((data: any) => {
            const newData: any = {}
            for (let item of AdminsConstants.dataSort) {
                newData[item] = data[item]

            }
            newData['is_checked'] = false
            return newData
        });
    }

    public DEFAULT_TAB_ACTIVE = 'learner';

    // reFormatUserDatas(rawData: any, tabActive: string = 'learner', routeName: string) {
    //     let dataSort:any;
    //     if(tabActive === 'learner'){
            
    //         if (routeName===ROUTE_NAMES.FREE_USERS) {
    //             dataSort = UsersConstants.dataSort.learner_free
    //         }else {
    //             dataSort = UsersConstants.dataSort.learner
    //         }

    //         return rawData.map((data: any) => {
    //             const newData: any = {}
    //             for (let item of dataSort) {
    //                 newData[item] = data[item]

    //             }
    //             newData['is_checked'] = false
    //             return newData
    //         });
    //     }else{
    //         if (routeName===ROUTE_NAMES.FREE_USERS) {
    //             dataSort = UsersConstants.dataSort.parent_free
    //         }else {
    //             dataSort = UsersConstants.dataSort.parent
    //         }
    //         return rawData.map((data: any) => {
    //             const newData: any = {}
    //             for (let item of dataSort) {
    //                 newData[item] = data[item]

    //             }
    //             newData['is_checked'] = false
    //             return newData
    //         });
    //     }

    // }

    /**
     * Reformats user data based on the active tab and route name.
     * 
     * @param rawData - Array of raw data objects to be reformatted.
     * @param tabActive - The currently active tab (default is 'learner').
     * @param routeName - The name of the current route.
     * @returns An array of transformed data objects.
     */
    reFormatUserDatas(rawData: any[], tabActive: string = this.DEFAULT_TAB_ACTIVE, routeName: string): any[] {
        // console.log(routeName);
        const dataSort = this.getDataSort(tabActive, routeName);
        return rawData.map(data => this.transformData(data, dataSort));
    }

    /**
     * Determines the sorting criteria based on the active tab and route name.
     * 
     * @param tabActive - The currently active tab.
     * @param routeName - The name of the current route.
     * @returns An array of strings representing the sorting criteria.
     */
    getDataSort(tabActive: string, routeName: string): string[] {
        if (tabActive === this.DEFAULT_TAB_ACTIVE) {
            return routeName === ROUTE_NAMES.FREE_USERS ? UsersConstants.dataSort.learner_free : UsersConstants.dataSort.learner;
        } else {
            return routeName === ROUTE_NAMES.FREE_USERS ? UsersConstants.dataSort.parent_free : UsersConstants.dataSort.parent;
        }
        return tabActive === 'learner'
            ? (routeName === ROUTE_NAMES.FREE_USERS ? UsersConstants.dataSort.learner_free : UsersConstants.dataSort.learner)
            : UsersConstants.dataSort.parent;
    }
    /**
     * Transforms a single data object based on the specified sorting criteria.
     * 
     * @param data - The data object to be transformed.
     * @param dataSort - An array of strings representing the sorting criteria.
     * @returns A transformed data object.
     */
    transformData(data: any, dataSort: string[]): any {
        const transformedData: any = {};
        for (let item of dataSort) {
            transformedData[item] = data[item];
        }
        transformedData['is_checked'] = false;
        return transformedData;
    }

    reFormatPersonAiDatas(rawData: any) {
        return rawData.map((data: any) => {
            const newData: any = {}
            for (let item of PersonAisConstants.dataSort) {
                newData[item] = data[item]

            }
            newData['video_call_feature'] = 'Video Call (Tutor Session)'
            newData['is_checked'] = false
            return newData
        });
    }
}

export default new MainService()
