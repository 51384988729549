import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "vue3-toastify/dist/index.css";
// import "bootstrap/dist/js/bootstrap.js";

import { Amplify } from "aws-amplify";
import amplifyConfig from "./config/amplify";
Amplify.configure(amplifyConfig);

import ApiService from "@/services/api-service";
const apiAction = new ApiService(store);
import { toast } from "vue3-toastify";

const MAIN_APP = createApp(App);
MAIN_APP.config.globalProperties.$apiAction = apiAction;
MAIN_APP.config.globalProperties.$toast = toast;

MAIN_APP.use(store).use(router).mount("#app");
