<template>
    <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.61935 12.51L7.81929 12.4694C8.08316 12.4159 8.08316 12.0388 7.81929 11.9853L7.61935 11.9447C7.11563 11.8426 6.72195 11.4489 6.61982 10.9452L6.57929 10.7453C6.52579 10.4814 6.14863 10.4814 6.09513 10.7453L6.0546 10.9452C5.95248 11.4489 5.55879 11.8426 5.05507 11.9447L4.85513 11.9853C4.59126 12.0388 4.59126 12.4159 4.85513 12.4694L5.05507 12.51C5.55879 12.6121 5.95248 13.0058 6.0546 13.5095L6.09513 13.7094C6.14863 13.9733 6.52579 13.9733 6.57929 13.7094L6.61982 13.5095C6.72195 13.0058 7.11563 12.6121 7.61935 12.51Z"
            fill="currentColor"
        />
        <path
            d="M6.92233 4.71789L7.87939 4.52386C8.33121 4.43223 8.33121 3.78651 7.87939 3.69489L6.92233 3.50086C5.97921 3.30964 5.24211 2.57254 5.05089 1.62942L4.85686 0.672355C4.76524 0.220543 4.11952 0.220543 4.02789 0.672355L3.83386 1.62942C3.64264 2.57254 2.90555 3.30964 1.96243 3.50086L1.00536 3.69489C0.553551 3.78651 0.553551 4.43223 1.00536 4.52386L1.96243 4.71789C2.90555 4.90911 3.64264 5.6462 3.83386 6.58932L4.02789 7.54639C4.11952 7.9982 4.76524 7.9982 4.85686 7.54639L5.05089 6.58932C5.24208 5.6462 5.97921 4.90914 6.92233 4.71789Z"
            fill="currentColor"
        />
        <path
            d="M13.7277 8.04782L14.4627 7.89879C14.8097 7.82844 14.8097 7.33251 14.4627 7.26216L13.7277 7.11313C13.0034 6.96626 12.4373 6.40023 12.2905 5.67591L12.1414 4.94091C12.0711 4.59391 11.5752 4.59391 11.5048 4.94091L11.3558 5.67591C11.2089 6.40023 10.6429 6.96629 9.91859 7.11313L9.18359 7.26216C8.83659 7.33251 8.83659 7.82844 9.18359 7.89879L9.91859 8.04782C10.6429 8.1947 11.209 8.76073 11.3558 9.48504L11.5048 10.22C11.5752 10.567 12.0711 10.567 12.1414 10.22L12.2905 9.48504C12.4373 8.76076 13.0034 8.19466 13.7277 8.04782Z"
            fill="currentColor"
        />
    </svg>
</template>
