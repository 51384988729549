import { toast } from "vue3-toastify";
import {
    AddUserIcon,
    ArrowIcon,
    AutoIcon,
    TaskIcon,
    ExclamationMarkIcon,
} from "@/assets/images/icons/components";

import {
    AccordingPanel,
    DropdownSelect,
    FLForm,
    MenuLanguagesSelection,
    NoData,
    Popover,
    PreviewOutComePrompt,
    Spinner,
} from "@/components/base";

import ModalBase from "@/components/base/modal-base/modal-base.vue";
import { languageAvailable } from "@/constants";
import PersonAisConstants from "@/constants/person-ai";
import { TLanguages } from "@/interfaces/person-ai/index";
import { TransformedActionTypesPersonAi } from "@/store/modules/person-ai/action-style";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";
import { Modal } from "bootstrap";
import { default as FJModal } from "@/components/base/modal/modal.vue";
import { Options, Vue } from "vue-class-component";
import Vocabulary from "./components/vocabulary.vue";
import TopicSelect from "./components/topic-selection.vue";
import Wrapper from "./components/wrapper.vue";
import {
    mapDataAutoGenerateToField,
    mapDataToTranslate,
    mapDataTranslateToField,
} from "./utils/translate";
import validateForm, { validateFieldEnglish } from "./utils/validate";
import { ITopic } from "./utils/type";
import { ApiRequest } from "./common/api-request";

const { FLInput } = FLForm;
@Options({
    components: {
        AddUserIcon,
        AutoIcon,
        DropdownSelect,
        NoData,
        Spinner,
        MenuLanguagesSelection,
        FLInput,
        TaskIcon,
        Splide,
        SplideSlide,
        ArrowIcon,
        SplideTrack,
        ModalBase,
        AccordingPanel,
        PreviewOutComePrompt,
        Vocabulary,
        Popover,
        TopicSelect,
        FJModal,
        Wrapper,
        ExclamationMarkIcon,
    },
    props: {
        topicDataProps: Object,
        personAiId: Number,
        listTopic: Object,
    },
    watch: {
        scenarioSelected: {
            handler(newValue, oldValue) {
                if (this.scenarioSelected?.content)
                    this.computedCheckListLanguage();
            },
            deep: true,
        },
        topicDataProps: {
            handler(newVal, oldVal) {
                this.dataTopicChange();
            },
            deep: true,
        },
    },
    emits: ["on-close-modal-scenario"],
    inject: ["updateTopicData"],
})
export default class ModalScenarioTopic extends Vue {
    public updateTopicData!: () => void;
    public scenarioSelected: any = new PersonAisConstants()
        .dataInitScenarioTopic;
    public scenariosTopicData: any = []; // List tab scenarios
    public languageSelected: TLanguages = "English";
    public checkListLanguage: string[] = [];
    public initialTemplateTaskOfScenario: any = [];
    public personAiId!: number;
    public topicDataProps!: any;
    public isShowConfirmDelete: boolean = false;
    public isShowConfirmCloseModal: boolean = false;
    public isShowTopicSelectionModal: boolean = false;
    public listTopic!: ITopic[];
    public payloadAvatar: any = {
        img_url: null,
        gif_url: null,
    };
    public myModal: any = null;
    private apiRequest: any;

    public isChangeData: boolean = false;

    public loading: any = {
        saveScenario: false,
        changeScenario: false,
        deleteScenario: false,
        translate: false,
        taskChange: false,
        autoGenerateContext: false,
        all: false,
    };
    public selectOptions: any = {
        video_call_feature: [
            // {
            //     value: "all",
            //     text: "Video Call",
            //     active: true,
            // },
            {
                text: "Video Call (Tutor Session)",
                value: "entertainment",
                active: true,
            },
            {
                text: "Video Call (Learn language)",
                value: "learn_language",
                active: true,
            },
        ],
        levels: [
            {
                value: "beginner",
                text: "Beginner",
                active: true,
            },
            {
                value: "intermediate",
                text: "Intermediate+",
                active: true,
            },
        ],
    };
    public menuSelections: any = {
        languages: languageAvailable,
    };

    // // =============== Lifecycle Hooks =============== //
    mounted() {
        // this.scenarioUpdateForTopicData = this.topicDataProps;
        this.myModal = new Modal(this.$refs["scenario-topic-modal"] as any, {
            backdrop: "static",
            keyboard: false,
        });
        this.myModal._element.addEventListener(
            "hidden.bs.modal",
            this.onResetStateComponent
        );

        this.dataTopicChange();

        this.apiRequest = new ApiRequest(this.$apiAction);
    }
    beforeUnmount() {
        this.myModal._element.removeEventListener(
            "hidden.bs.modal",
            this.onResetStateComponent
        );
    }
    // =============== Provider Store(VueX) =============== //

    // =============== Behavior Component =============== //

    private dataTopicChange() {
        if (this.topicDataProps?.scenarios?.length > 0) {
            let t = JSON.parse(JSON.stringify(this.topicDataProps));
            this.scenariosTopicData = t.scenarios;
            this.scenarioSelected = t.scenarios[0];
            this.apiGetScenario(this.scenarioSelected.id);
        } else {
            this.scenariosTopicData = [];
        }
    }

    public onChangeInput() {
        this.isChangeData = true;
    }
    public openModal() {
        this.myModal.show();
    }

    public onCloseScenarioModal() {
        if (this.myModal) this.myModal.hide();
    }
    public onCancelCloseScenarioModal() {
        this.isShowConfirmCloseModal = false;
    }
    public checkChange() {
        if (this.isChangeData) {
            this.isShowConfirmCloseModal = true;
            (this.$refs["modal-base-alert-save-change"] as any).openModal();
        } else {
            this.myModal.hide();
        }
    }

    private onResetStateComponent() {
        this.$emit("on-close-modal-scenario");
        this.scenarioSelected = new PersonAisConstants().dataInitScenarioTopic;
        this.scenariosTopicData = [];
        this.languageSelected = "English";
        this.checkListLanguage = [];
        this.initialTemplateTaskOfScenario = [];
        this.isShowConfirmCloseModal = false;
        this.isShowConfirmDelete = false;
        this.isChangeData = false;
    }

    // =============== Event Form =============== //
    public selectOptionVideoCallFeature(value: any) {
        this.scenarioSelected.type = value;
        if (value === "learn_language" && this.scenarioSelected.level === null)
            this.scenarioSelected.level = "intermediate";
    }

    public onChangeVocabulary(value: boolean) {
        this.loading.all = value;
    }
    public selectOptionLevel(value: any) {
        this.scenarioSelected.level = value;
    }
    public handleSelectLanguage(title: TLanguages) {
        this.languageSelected = title;
    }
    public handleChangeScenario(id: string | number) {
        this.scenarioSelected = this.scenariosTopicData.find(
            (s: any) => s.id === id
        );
        if (
            !this.scenarioSelected?.prompt &&
            typeof this.scenarioSelected?.id === "number"
        )
            this.apiGetScenario(this.scenarioSelected.id);
    }

    // =============== Action Button =============== //
    public updateDataScenarioInCreateAiCharacter(
        id: number,
        name: string,
        type: "create" | "delete" | "update" = "create",
        is_published?: boolean
    ) {
        switch (type) {
            case "create":
                this.topicDataProps.scenarios.push({
                    id: id,
                    name: name,
                    is_published,
                });
                break;
            case "delete":
                // this.scenarioUpdateForTopicData.scenarios =
                //     this.scenarioUpdateForTopicData.scenarios.filter(
                //         (v: any) => v.id !== id
                //     );
                this.topicDataProps.scenarios.forEach(
                    (item: any, idx: number) => {
                        if (item.id === id) {
                            this.topicDataProps.scenarios.splice(idx, 1);
                        }
                    }
                );
                break;
            case "update":
                this.topicDataProps.scenarios.forEach(
                    (item: any, idx: number) => {
                        if (item.id === id) {
                            this.topicDataProps.scenarios[idx].name = name;
                            this.topicDataProps.scenarios[idx].is_published =
                                is_published;
                        }
                    }
                );
                break;
        }
        // this.handleUpdateScenarioTopicData();
    }

    public handleOpenModalTopicSelection() {
        this.isShowTopicSelectionModal = true;
        (this.$refs["modal-topics-selection"] as any).openModal();
    }

    public async handleSaveChangeTopicForScenario(topicId: number) {
        this.isShowTopicSelectionModal = false;
        (this.$refs["modal-topics-selection"] as any).closeModal();
        this.loading.all = true;
        this.loading.saveScenario = true;

        const resultData =
            await this.apiRequest.apiRequestUpdateTopicForScenario({
                scenario_id: this.scenarioSelected.id,
                topic_id: topicId,
            });

        this.loading.all = false;
        this.loading.saveScenario = false;
        if (resultData) {
            toast.success("Move scenario successfully");
            this.updateTopicData();
            this.scenariosTopicData = this.scenariosTopicData.filter(
                (c: any) => {
                    return c.id !== this.scenarioSelected.id;
                }
            );
            if (this.scenariosTopicData.length > 0)
                this.scenarioSelected = this.scenariosTopicData[0];
            else this.scenarioSelected = null;
        } else toast.error("Move scenario failed");
    }
    public handleCloseTopicForScenario() {
        this.isShowTopicSelectionModal = false;
        (this.$refs["modal-topics-selection"] as any).closeModal();
    }

    public async handleAddScenario() {
        if (this.initialTemplateTaskOfScenario.length === 0) {
            this.loading.taskChange = true;
            const result = await this.apiGetTemplateTaskScenario();
            if (!result) return;
        }
        const intData: any = new PersonAisConstants().dataInitScenarioTopic;
        for (const [key] of Object.entries(this.scenarioSelected.content)) {
            intData.content[key] = {
                name: "",
                ai_role: "",
                user_role: "",
                context: "",
                tasks: this.initialTemplateTaskOfScenario,
            };
            intData.id = `temporary-${Date.now()}`;
        }

        const dataString = JSON.stringify(intData);
        this.scenarioSelected = JSON.parse(dataString);
        this.scenariosTopicData.push(this.scenarioSelected);
    }
    public async handleTranslate() {
        const validate = validateFieldEnglish(
            this.scenarioSelected.content["English"],
            "English",
            this.scenarioSelected.type
        );
        if (!validate.status) {
            this.$toast.error(validate.errorMessage);
            return;
        }

        this.loading.all = true;
        this.loading.translate = true;
        const { data, fieldsToMapper } = mapDataToTranslate(
            this.scenarioSelected.content["English"]
        );
        this.apiTranslateText(data, fieldsToMapper);
    }

    public async handleAutoGenerateContextInfo() {
        this.loading.all = true;
        this.loading.autoGenerateContext = true;
        const dataResult: any = await this.apiAutoGenerateContextInfo({
            person_ai_id: this.personAiId,
            video_call_topic_id: this.topicDataProps.id,
        });

        this.loading.all = false;
        this.loading.autoGenerateContext = false;

        if (!dataResult?.ai_role) {
            return this.$toast.error(
                "Context Scenario generation failed. Please try again!"
            );
        }
        this.scenarioSelected.prompt = dataResult.prompt;
        this.scenarioSelected.content["English"].name = dataResult.description;
        this.scenarioSelected.content["English"].context = dataResult.context;
        this.scenarioSelected.content["English"].ai_role = dataResult.ai_role;
        this.scenarioSelected.content["English"].user_role =
            dataResult.user_role;
    }
    public async handleAutoGenerate() {
        const { context, ai_role, user_role } =
            this.scenarioSelected.content["English"];
        if (!context || !ai_role || !user_role) {
            this.$toast.error(
                'Please add "Context","Ai Role" and "User Role" before using the "Auto Generate" feature.'
            );
            return;
        }

        this.loading.all = true;
        this.loading.translate = true;
        const dataResult: any = await this.apiAutoGenerateTaskScenario({
            context,
            ai_role,
            user_role,
        });

        this.loading.all = false;
        this.loading.translate = false;

        if (!dataResult) {
            return this.$toast.error(
                "Task generation failed. Please try again!"
            );
        }
        const { fieldsToMapper } = mapDataToTranslate(
            this.scenarioSelected.content["English"]
        );

        const dataMapper = mapDataAutoGenerateToField(
            this.scenarioSelected.content["English"],
            dataResult,
            fieldsToMapper
        );

        this.scenarioSelected.content["English"] = dataMapper;
    }

    public handleSaveDraftScenario() {
        this.loading.all = true;
        if (this.scenarioSelected.type !== "learn_language")
            this.scenarioSelected.level = null;
        if (typeof this.scenarioSelected.id === "string") {
            this.apiCreateScenario(true);
        } else if (
            typeof this.scenarioSelected.id === "number" &&
            this.scenarioSelected.id !== 0
        ) {
            this.apiUpdateScenario(true);
        }
    }
    public async handleSaveScenario() {
        const validate = validateForm(this.scenarioSelected);
        this.isChangeData = false;
        if (!validate.status) {
            this.$toast.error(validate.errorMessage);
            return;
        }

        this.loading.all = true;
        if (this.scenarioSelected.type !== "learn_language")
            this.scenarioSelected.level = null;
        if (typeof this.scenarioSelected.id === "string") {
            this.apiCreateScenario();
        } else if (
            typeof this.scenarioSelected.id === "number" &&
            this.scenarioSelected.id !== 0
        ) {
            this.apiUpdateScenario();
        }
        // this.handleUpdateScenarioTopicData();s
    }
    public handleRemoveImage(typeAction: "image_url" | "gif_url") {
        this.scenarioSelected[typeAction] = "";
    }
    public handleAvatar(
        event: any,
        type: string,
        typeAction: "image_url" | "gif_url"
    ) {
        const payloadImage = new FormData();
        const selectedFiles = (event.target as HTMLInputElement).files;
        if (selectedFiles) {
            const file = selectedFiles[0];
            if (
                file.type === "image/png" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/gif"
            ) {
                if (file.size > 500 * 1024 && typeAction === "image_url")
                    return this.$toast.error(
                        '"Thumbnail image" must be less than 500kb'
                    );
                if (file.size > 10000 * 1024 && typeAction === "gif_url")
                    return this.$toast.error(
                        '"Thumbnail gif" must be less than 10Mb'
                    );
                payloadImage.set(`image`, file);
                payloadImage.set(`field`, type);
                payloadImage.set(
                    "location",
                    typeAction === "image_url"
                        ? "scenario_image"
                        : "scenario_gif"
                );
                this.apiUpdateAvatar(payloadImage, typeAction);
            } else this.$toast.error("Invalid file");
        }
    }

    // =============== Action Modal =============== //
    public onCloseDeleteConfirmModal() {
        this.isShowConfirmDelete = false;
    }
    public async handleOpenDeleteScenarioConfirm() {
        this.isShowConfirmDelete = true;
        (this.$refs["modal-base-delete-scenario"] as any).openModal();
    }

    // =============== Api Action =============== //
    public async apiAutoGenerateContextInfo(payload: any) {
        return new Promise<any>(async (r) => {
            // setTimeout(() => {
            //     r(templateTaskTemplate);
            // }, 1000);
            await this.$apiAction.handleApiRequest(
                TransformedActionTypesPersonAi.AUTOGENERATE_CONTEXT_VIDEO_CALL_SCENARIO_V2,
                payload,
                (data: any) => {
                    r(data || null);
                },
                () => {
                    r(null);
                }
            );
        });
    }
    public async apiAutoGenerateTaskScenario(payload: any) {
        return new Promise<any>(async (r) => {
            // setTimeout(() => {
            //     r(getAutogenerateContext);
            // }, 1200);
            await this.$apiAction.handleApiRequest(
                TransformedActionTypesPersonAi.AUTOGENERATE_TASK_VIDEO_CALL_SCENARIO_V2,
                payload,
                (data: any) => {
                    r(data || null);
                },
                () => {
                    r(null);
                }
            );
        });
    }
    public async apiGetTemplateTaskScenario() {
        return new Promise<boolean>(async (resolve) => {
            await this.$apiAction.handleApiRequest(
                TransformedActionTypesPersonAi.GET_SCENARIOS_TASK_EDIT_TEMPLATE_V2,
                {},
                (data: any) => {
                    this.initialTemplateTaskOfScenario = data.tasks;
                    resolve(true);
                    this.loading.taskChange = false;
                },
                () => {
                    resolve(false);
                    this.loading.taskChange = false;
                }
            );
        });
    }
    public async apiDeleteScenario() {
        this.loading.all = true;
        this.loading.deleteScenario = true;
        await this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.DELETE_VIDEO_CALL_SCENARIO_V2,
            { id: this.scenarioSelected.id },
            (data: any) => {
                this.$toast.success("Delete scenario success!");
                this.updateDataScenarioInCreateAiCharacter(
                    this.scenarioSelected.id,
                    this.scenarioSelected.content.English.name,
                    "delete"
                );
                this.scenariosTopicData = this.scenariosTopicData.filter(
                    (c: any) => {
                        return c.id !== this.scenarioSelected.id;
                    }
                );
                if (this.scenariosTopicData[0]?.prompt)
                    this.scenarioSelected = this.scenariosTopicData[0];
                else if (this.scenariosTopicData.length > 0)
                    this.apiGetScenario(this.scenariosTopicData[0].id);
                this.loading.all = false;
                this.loading.deleteScenario = false;
                this.isShowConfirmDelete = false;
            },
            () => {
                this.$toast.error("Delete scenario failed!");
                this.loading.deleteScenario = false;
                this.isShowConfirmDelete = false;
                this.loading.all = false;
            }
        );
    }
    public async apiCreateScenario(isDraft = false) {
        const { id, ...payload } = this.scenarioSelected;
        await this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.CREATE_VIDEO_CALL_SCENARIO_V2,
            {
                ...payload,
                topic_id: this.topicDataProps.id,
                person_ai_id: this.personAiId,
                is_published: !isDraft,
            },
            (data: any) => {
                this.$toast.success("Created scenario Success!");
                this.loading.all = false;
                this.scenarioSelected = data.data;
                this.scenariosTopicData[this.scenariosTopicData.length - 1] =
                    data.data;
                this.updateDataScenarioInCreateAiCharacter(
                    data.data.id,
                    data.data.content.English.name,
                    "create",
                    !isDraft
                );
            },
            () => {
                this.$toast.error("Created scenario failed!");
                this.loading.all = false;
            }
        );
    }
    public async apiUpdateScenario(isDraft = false) {
        await this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.UPDATE_VIDEO_CALL_SCENARIO_V2,
            {
                ...this.scenarioSelected,
                topic_id: this.topicDataProps.id,
                person_ai_id: this.personAiId,
                is_published: !isDraft,
            },
            (data: any) => {
                this.$toast.success("Updated scenario Success!");
                this.loading.all = false;
                this.updateDataScenarioInCreateAiCharacter(
                    this.scenarioSelected.id,
                    this.scenarioSelected.content.English.name,
                    "update",
                    !isDraft
                );
                // this.scenarioSelected = data.data;
                // this.scenariosTopicData.push(data.data);
            },
            () => {
                this.$toast.error("Updated scenario failed!");
                this.loading.all = false;
            }
        );
    }
    public async apiGetScenario(id: number) {
        // const data = scenario;
        // this.scenariosTopicData.forEach((i: any, idx: number) => {
        //     if (i.id === data.id) {
        //         this.scenarioSelected = data;
        //         this.scenariosTopicData[idx] = { ...data };
        //         return;
        //     }
        // });

        this.loading.changeScenario = true;
        await this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.GET_VIDEO_CALL_SCENARIO_V2,
            {
                id,
            },
            (data: any) => {
                // this.scenariosTopicData = data.data;
                // let scenario = {}
                this.scenariosTopicData.forEach((i: any, idx: number) => {
                    if (i.id === data.id) {
                        this.scenarioSelected = data;
                        this.scenariosTopicData[idx] = { ...data };
                        return;
                    }
                });

                this.loading.changeScenario = false;
            },
            () => {
                this.loading.changeScenario = false;
            }
        );
    }
    public async apiTranslateText(
        content: { [key: string]: string },
        fields: { [key: string]: string }
    ) {
        await this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.AUTO_TRANSLATE_TEXT_V2,
            {
                content: content,
                non_english_raises: false,
            },
            (data: any) => {
                const dataMapped = mapDataTranslateToField(
                    this.scenarioSelected.content,
                    data,
                    fields
                );
                this.scenarioSelected.content = dataMapped;
                this.loading.all = false;
                this.loading.translate = false;
            },
            () => {
                this.loading.all = false;
                this.loading.translate = false;
            }
        );
    }

    public apiUpdateAvatar(payload: any, typeAction: "image_url" | "gif_url") {
        this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.UPLOAD_AVATAR_V2,
            payload,
            (data: any) => {
                this.scenarioSelected[typeAction] = data.image_url;
            }
        );
    }

    // =============== Utilities Helper =============== //
    public computedCheckListLanguage(): void {
        const checkListLanguage: string[] = [];

        if (!this.scenarioSelected?.content) return;

        this.menuSelections.languages.forEach((language: string) => {
            const content = this.scenarioSelected.content[language];

            // Check if the content is empty.
            if (!content) return;

            const isValid = Object.entries(content).every(([key, value]) => {
                if (!value) return false;
                if (
                    key === "tasks" &&
                    this.scenarioSelected.type === "entertainment"
                )
                    return true;
                if (key === "tasks") {
                    return (value as any[]).every((task) =>
                        (task.subtasks as any[]).every(
                            (subtask) => subtask.title
                        )
                    );
                }
                return true;
            });

            if (isValid) {
                checkListLanguage.push(language);
            }
        });

        this.checkListLanguage = checkListLanguage;
    }
}
