<script lang="ts" setup>
const emit = defineEmits(["click"]);

withDefaults(
    defineProps<{
        text: string;
        loading?: boolean;
        disabled?: boolean;
        class?: string;
        // click: (payload: MouseEvent) => void;
    }>(),
    {
        text: "Submit",
        loading: false,
        disabled: false,
        class: "",
    }
);

const click = () => {
    emit("click"); // Emitting the 'increment' event with the updated counter value
};
</script>
<template>
    <button
        @click="click"
        :class="`button-solid ${loading || disabled ? 'disabled' : ' '} ${
            $props.class
        }}`"
    >
        <span
            v-if="loading"
            class="me-2 spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
        />
        {{ text }}
    </button>
</template>

<style lang="scss" scoped>
.button-solid {
    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: none;
    }
}
</style>
