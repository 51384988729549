// @ts-nocheck
type ActionTypesDashboardType = {
    GET_NUMBER_KIDS_BY_AGE: string,
    GET_TOTAL_PAID_USER: string,
    GET_TOTAL_USER: string,
};

export const ActionTypesDashboard: ActionTypesDashboardType = {
    GET_NUMBER_KIDS_BY_AGE: 'GET_NUMBER_KIDS_BY_AGE',
    GET_TOTAL_PAID_USER: 'GET_TOTAL_PAID_USER',
    GET_TOTAL_USER: 'GET_TOTAL_USER',
};


function transformObjectProperties(targetObject, prefix) {
    const transformedObject = {};

    // Iterate over the object properties
    for (const key in targetObject) {
        transformedObject[key] = `${prefix}/${targetObject[key]}`;
    }

    return transformedObject;
}
;

export const TransformedActionTypesDashboard: ActionTypesDashboardType = transformObjectProperties(ActionTypesDashboard, 'dashboard');
