const messageValidate: any = {
    image_url_primary_detail: "Avatar image is required.",
    image_url_secondary_detail: "Middle school detail is required.",
    image_url_high_detail: "High school detail is required.",
    image_url_primary_avatar: "Details image is required.",
    image_url_secondary_avatar: "Middle school avatar is required.",
    image_url_high_avatar: "High school avatar is required.",
    type: "Character for feature is required.",
    category: "Category is required.",
    education: "Education is required.",
    name: "Character Name is required.",
    inspired_character: "Inspired character is required.",
    number_used: "Total chats character is required",
    title: "Title is required.",
    description: "Description is required.",
    quote: "Quote is required.",
    welcome_messages: "Welcome message for first entry required.",
    system_prompt: "General of character prompt required.",
    guideline_next_question_1:
        "Guideline next question for first entry: Question 1 is required.",
    guideline_next_question_2:
        "Guideline next question for first entry: Question 2 is required.",
    guideline_next_question_3:
        "Guideline next question for first entry: Question 3 is required.",
    video_call_prompt: "Video prompt is required.",
    listening_video: "Video listening is required.",
    responding_video: "Video responding is required.",
    // video_call_video_topics: "Must have least one topic",
    story_of_character: "Story of character is required",
};

export function checkValidateForm(
    form: any,
    messageKeys: { [key: string]: string },
    isReadyToSave: boolean,
    isCheckboxChecked: boolean,
): string | null {
    const requiredFields = [
        {
            key: "image_url_primary_detail",
            message: messageValidate.image_url_primary_detail,
        },
        {
            key: "image_url_primary_avatar",
            message: messageValidate.image_url_primary_avatar,
        },
        { key: "type", message: messageValidate.type },
        { key: "category_id", message: messageValidate.category },
        { key: "education", message: messageValidate.education },
        { key: "name", message: messageValidate.name, trim: true },
        {
            key: "inspired_character",
            message: messageValidate.inspired_character,
            trim: true,
        },
        {
            key: "number_used",
            message: messageValidate.number_used,
            isLength: true,
        },
        {
            key: "system_prompt",
            message: messageValidate.system_prompt,
            trim: true,
        },
    ];

    for (const field of requiredFields) {
        if (
            (field.trim && !form[field.key]?.trim()) ||
            (!field.trim && !form[field.key]) ||
            (field.isLength && form[field.key]?.length === 0)
        ) {
            messageKeys[field.key] = field.message;
            isReadyToSave = false;
            return field.message;
        }
    }

    const languages = [
        "English",
        "German",
        "French",
        "Spanish",
        "Italian",
        "Portuguese",
        "Polish",
        "Hindi",
        "Korean",
        "Japanese",
    ];
    const languageFields = [
        "title",
        "description",
        "quote",
        "welcome_messages",
        "guideline_next_question_1",
        "guideline_next_question_2",
        "guideline_next_question_3",
    ];

    for (const lang of languages) {
        for (const field of languageFields) {
            if (!form[field][lang]?.trim()) {
                messageKeys[field] = `"${lang}" ${messageValidate[field]}`;
                isReadyToSave = false;
                return `"${lang}" ${messageValidate[field]}`;
            }
        }
    }

    if (isCheckboxChecked) {
        const videoFields = [
            "prompt",
            "listening_video",
            "responding_video",
            "story_of_character",
        ];
        for (const field of videoFields) {
            if (!form.video_call_configs[field]) {
                messageKeys[`video_call_${field}`] =
                    messageValidate[`video_call_${field}`];
                isReadyToSave = false;
                return messageValidate[`video_call_${field}`];
            }
        }
        if (form.video_call_configs.topic_ids?.length < 1) {
            messageKeys.video_call_video_topics =
                messageValidate.video_call_video_topics;
            isReadyToSave = false;
            return messageValidate.video_call_video_topics;
        }
    }

    return null;
}

// const messageValidate = {
//     image_url_primary_detail: "Avatar image is required.",
//     image_url_secondary_detail: "Middle school detail is required.",
//     image_url_high_detail: "High school detail is required.",
//     image_url_primary_avatar: "Details image is required.",
//     image_url_secondary_avatar: "Middle school avatar is required.",
//     image_url_high_avatar: "High school avatar is required.",
//     type: "Character for feature is required.",
//     category: "Category is required.",
//     education: "Education is required.",
//     name: "Character Name is required.",
//     inspired_character: "Inspired character is required.",
//     number_used: "Total chats character is required",
//     title: "Title is required.",
//     description: "Description is required.",
//     quote: "Quote is required.",
//     welcome_messages: "Welcome message for first entry required.",
//     system_prompt: "General of character prompt required.",
//     guideline_next_question_1:
//         "Guideline next question for first entry: Question 1 is required.",
//     guideline_next_question_2:
//         "Guideline next question for first entry: Question 2 is required.",
//     guideline_next_question_3:
//         "Guideline next question for first entry: Question 3 is required.",
//     video_call_prompt: "Video prompt is required.",
//     listening_video: "Video listening is required.",
//     responding_video: "Video responding is required.",
//     video_call_video_topics: "Must have least one topic",
// };

// export function checkValidateForm(
//     form: any,
//     messageKeys: { [key: string]: string },
//     isReadyToSave: boolean
// ): string | null {
//     if (!form.image_url_primary_detail) {
//         messageKeys["image_url_primary_detail"] =
//             messageValidate["image_url_primary_detail"];
//         isReadyToSave = false;
//         return messageValidate["image_url_primary_detail"];
//     }

//     // if (!form.image_url_secondary_detail) {
//     //     messageKeys["image_url_secondary_detail"] =
//     //         messageValidate["image_url_secondary_detail"];
//     //     isReadyToSave = false;
//     //     return messageValidate["image_url_secondary_detail"];
//     // }

//     // if (!form.image_url_high_detail) {
//     //     messageKeys["image_url_high_detail"] =
//     //         messageValidate["image_url_high_detail"];
//     //     isReadyToSave = false;
//     //     return messageValidate["image_url_high_detail"];
//     // }

//     // Image avatar

//     if (!form.image_url_primary_avatar) {
//         messageKeys["image_url_primary_avatar"] =
//             messageValidate["image_url_primary_avatar"];
//         ("Elementary school avatar is required.");
//         isReadyToSave = false;
//         return messageValidate["image_url_primary_avatar"];
//     }

//     // if (!form.image_url_secondary_avatar) {
//     //     messageKeys["image_url_secondary_avatar"] =
//     //         messageValidate["image_url_secondary_avatar"];
//     //     isReadyToSave = false;
//     //     return messageValidate["image_url_secondary_avatar"];
//     // }

//     // if (!form.image_url_high_avatar) {
//     //     messageKeys["image_url_high_avatar"] =
//     //         messageValidate["image_url_high_avatar"];
//     //     isReadyToSave = false;
//     //     return messageValidate["image_url_high_avatar"];
//     // }

//     // INFOMATION

//     if (!form.type) {
//         messageKeys["type"] = messageValidate["type"];
//         isReadyToSave = false;
//         return messageValidate["type"];
//     }

//     if (!form.category_id) {
//         messageKeys["category"] = messageValidate["category"];
//         isReadyToSave = false;
//         return messageValidate["category"];
//     }

//     if (!form.education) {
//         messageKeys["education"] = messageValidate["education"];
//         isReadyToSave = false;
//         return messageValidate["education"];
//     }

//     form.name = form.name;
//     if (!form.name.trim()) {
//         messageKeys["name"] = messageValidate["name"];
//         isReadyToSave = false;
//         return messageValidate["name"];
//     }

//     if (!form.inspired_character.trim()) {
//         messageKeys["inspired_character"] =
//             messageValidate["inspired_character"];
//         isReadyToSave = false;
//         return messageValidate["inspired_character"];
//     }

//     if (form.number_used?.length === 0) {
//         messageKeys["number_used"] = messageValidate["number_used"];
//         isReadyToSave = false;
//         return messageValidate["number_used"];
//     }

//     if (!form.system_prompt.trim()) {
//         messageKeys["system_prompt"] = messageValidate["system_prompt"];
//         isReadyToSave = false;
//         return messageValidate["system_prompt"];
//     }

//     const languages = [
//         "English",
//         "German",
//         "French",
//         "Spanish",
//         "Italian",
//         "Portuguese",
//         "Polish",
//         "Hindi",
//         "Korean",
//         "Japanese",
//     ];
//     for (let i = 0; i < languages.length; i++) {
//         if (!form.title[languages[i]].trim()) {
//             messageKeys["title"] =
//                 `"${languages[i]}" ` + messageValidate["title"];
//             isReadyToSave = false;
//             return `"${languages[i]}" ` + messageValidate["title"];
//         }

//         if (!form.description[languages[i]].trim()) {
//             messageKeys["description"] =
//                 `"${languages[i]}" ` + messageValidate["description"];
//             isReadyToSave = false;
//             return `"${languages[i]}" ` + messageValidate["description"];
//         }

//         if (!form.quote[languages[i]].trim()) {
//             messageKeys["quote"] =
//                 `"${languages[i]}" ` + messageValidate["quote"];
//             isReadyToSave = false;
//             return `"${languages[i]}" ` + messageValidate["quote"];
//         }

//         if (!form.welcome_messages[languages[i]].trim()) {
//             messageKeys["welcome_messages"] =
//                 `"${languages[i]}" ` + messageValidate["welcome_messages"];
//             isReadyToSave = false;
//             return `"${languages[i]}" ` + messageValidate["welcome_messages"];
//         }
//         if (!form.guideline_next_question_1[languages[i]].trim()) {
//             messageKeys["guideline_next_question_1"] =
//                 `"${languages[i]}" ` +
//                 messageValidate["guideline_next_question_1"];
//             isReadyToSave = false;
//             return (
//                 `"${languages[i]}" ` +
//                 messageValidate["guideline_next_question_1"]
//             );
//         }

//         if (!form.guideline_next_question_2[languages[i]].trim()) {
//             messageKeys["guideline_next_question_2"] =
//                 `"${languages[i]}" ` +
//                 messageValidate["guideline_next_question_2"];
//             isReadyToSave = false;
//             return (
//                 `"${languages[i]}" ` +
//                 messageValidate["guideline_next_question_2"]
//             );
//         }

//         if (!form.guideline_next_question_3[languages[i]].trim()) {
//             messageKeys["guideline_next_question_3"] =
//                 `"${languages[i]}" ` +
//                 messageValidate["guideline_next_question_3"];
//             isReadyToSave = false;
//             return (
//                 `"${languages[i]}" ` +
//                 messageValidate["guideline_next_question_3"]
//             );
//         }
//     }

//     // VIDEO CALL
//     if (!form.is_video_call_enabled) return null;

//     if (!form.video_call_configs.prompt) {
//         messageKeys["video_call_prompt"] = messageValidate["video_call_prompt"];
//         isReadyToSave = false;
//         return messageValidate["video_call_prompt"];
//     }

//     if (!form.video_call_configs.listening_video) {
//         messageKeys["listening_video"] = messageValidate["listening_video"];
//         isReadyToSave = false;
//         return messageValidate["listening_video"];
//     }

//     if (!form.video_call_configs.responding_video) {
//         messageKeys["responding_video"] = messageValidate["responding_video"];
//         isReadyToSave = false;
//         return messageValidate["responding_video"];
//     }
//     // video_call_configs.topic_ids.length
//     if (form.video_call_configs.topic_ids?.length < 1) {
//         messageKeys["video_call_video_topics"] =
//             messageValidate["video_call_video_topics"];
//         isReadyToSave = false;
//         return messageValidate["video_call_video_topics"];
//     }
//     return null;
// }

// // if (!this.AIInfo.image_url_primary_detail) {
// //    this.invalidMessage.image_url_primary_detail =
// //        "Elementary school detail is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.image_url_secondary_detail) {
// //    this.invalidMessage.image_url_secondary_detail =
// //        "Middle school detail is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.image_url_high_detail) {
// //    this.invalidMessage.image_url_high_detail =
// //        "High school detail is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // // Image avatar

// // if (!this.AIInfo.image_url_primary_avatar) {
// //    this.invalidMessage.image_url_primary_avatar =
// //        "Elementary school avatar is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.image_url_secondary_avatar) {
// //    this.invalidMessage.image_url_secondary_avatar =
// //        "Middle school avatar is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.image_url_high_avatar) {
// //    this.invalidMessage.image_url_high_avatar =
// //        "High school avatar is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // // INFOMATION

// // if (!this.AIInfo.type) {
// //    this.invalidMessage.type = "Character for feature is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.category_id) {
// //    this.invalidMessage.category = "Category is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.education) {
// //    this.invalidMessage.education = "Education is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // this.AIInfo.name = this.AIInfo.name;
// // if (!this.AIInfo.name.trim()) {
// //    this.invalidMessage.name = "Character Name is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.inspired_character.trim()) {
// //    this.invalidMessage.inspired_character =
// //        "Inspired character is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (this.AIInfo.number_used?.length === 0) {
// //    this.invalidMessage.number_used =
// //        "Total chats character is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.title.English.trim()) {
// //    this.invalidMessage.title = "Title is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.description.English.trim()) {
// //    this.invalidMessage.description = "Description is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.quote.English.trim()) {
// //    this.invalidMessage.quote = "Quote is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.welcome_messages.English.trim()) {
// //    this.invalidMessage.welcome_messages =
// //        "Welcome message for first entry is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.system_prompt.trim()) {
// //    this.invalidMessage.system_prompt =
// //        "General of character prompt is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.guideline_next_question_1.English.trim()) {
// //    this.invalidMessage.guideline_next_question_1 =
// //        "Guideline next question for first entry: Question 1 is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.guideline_next_question_2.English.trim()) {
// //    this.invalidMessage.guideline_next_question_2 =
// //        "Guideline next question for first entry: Question 2 is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.guideline_next_question_3.English.trim()) {
// //    this.invalidMessage.guideline_next_question_3 =
// //        "Guideline next question for first entry: Question 3 is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // // VIDEO CALL
// // if (!this.AIInfo.video_call_configs.is_enabled) return;

// // if (!this.AIInfo.video_call_configs.prompt) {
// //    this.invalidMessage.video_call_prompt = "Video prompt is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.video_call_configs.listening_video) {
// //    this.invalidMessage.listening_video =
// //        "Video listening is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (!this.AIInfo.video_call_configs.responding_video) {
// //    this.invalidMessage.responding_video =
// //        "Video responding is required.";
// //    this.readyToSave = false;
// //    return;
// // }

// // if (this.AIInfo.video_call_configs.topic_ids?.length < 1) {
// //    this.invalidMessage.video_call_video_topics =
// //        "Must have least one topic";
// //    this.readyToSave = false;
// //    return;
// // }
