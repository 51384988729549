<template>
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 2 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.74992 0.867188C1.74992 0.452974 1.41413 0.117188 0.999919 0.117188C0.585705 0.117188 0.249919 0.452974 0.249919 0.867188V6.3217C0.249919 6.73592 0.585705 7.0717 0.999919 7.0717C1.41413 7.0717 1.74992 6.73592 1.74992 6.3217V0.867188ZM0.985746 8.13973C0.483672 8.13973 0.0766602 8.54674 0.0766602 9.04882C0.0766602 9.55089 0.483672 9.9579 0.985746 9.9579H1.01372C1.51579 9.9579 1.92281 9.55089 1.92281 9.04882C1.92281 8.54674 1.51579 8.13973 1.01372 8.13973H0.985746Z"
            fill="currentColor"
        />
    </svg>
</template>
