import {
    IScenarioTopic,
    IScenarioWithLanguage,
    ITaskOfScenario,
} from "@/interfaces/person-ai";

interface IDataTranslate {
    [key: string]: string;
}

interface IDataAutoGenerate {
    [key: string]: string;
}
interface IDataAutoMapper {}

function generateFiled(content: IScenarioWithLanguage) {
    let dataReturn: any = {};
    if (content.tasks) {
        content.tasks.forEach((tk: any, idx) => {
            for (let i = 0; i < tk.subtasks.length; i++) {
                dataReturn[
                    `tasksTitle_${tk.id}_${i}`
                ] = `tasks[${idx}].subtasks[${i}].title`;
            }
        });
    }
    return {
        name: "name",
        ai_role: "ai_role",
        user_role: "user_role",
        context: "context",
        ...dataReturn,
    };
}

function getValueByPath(obj: IScenarioWithLanguage, path: string) {
    // Split the path into an array of keys
    const keys: any = path.split(/[\.\[\]]/).filter((key) => key !== "");
    // Traverse the object
    let current: any = obj;
    for (let key of keys) {
        if (isFinite(key)) {
            // If the key is a number, convert it to an integer for array indexing
            key = parseInt(key, 10);
        }

        if (current[key] === undefined) {
            return undefined; // Return undefined if the key does not exist
        }
        current = current[key];
    }

    // Return the value found at the end of the path
    return current;
}

// Update the value of the object using the referent type technique. There is no need to return data
function setValueByPath(
    obj: IScenarioWithLanguage | ITaskOfScenario[],
    path: string,
    value: any
) {
    // Split the path into an array of keys
    const keys: any = path.split(/[\.\[\]]/).filter((key) => key !== "");

    // Traverse the object
    let current: any = obj;
    for (let i = 0; i < keys.length; i++) {
        let key: any = keys[i];

        if (isFinite(key)) {
            // If the key is a number, convert it to an integer for array indexing
            key = parseInt(key, 10);
        }

        // If it's the last key, set the value
        if (i === keys.length - 1) {
            current[key] = value;
        } else {
            // If the key doesn't exist, create an object or array
            if (current[key] === undefined) {
                current[key] = isFinite(keys[i + 1]) ? [] : {};
            }
            current = current[key];
        }
    }
}

export function mapDataTranslateToField(
    content: IScenarioTopic["content"],
    data: IDataTranslate,
    fields: IDataTranslate
): IScenarioTopic["content"] {
    let dataReturn = content;
    for (let [l, value] of Object.entries(dataReturn)) {
        for (let [k, v] of Object.entries(fields)) {
            //@ts-ignore
            setValueByPath(value as any, fields[k], data[l][k]);
        }
    }
    return dataReturn;
}

export function mapDataAutoGenerateToField(
    content: ITaskOfScenario[],
    data: IDataAutoGenerate,
    fields: IDataAutoGenerate
): ITaskOfScenario[] {
    let dataReturn = content;
    const { ai_role, context, name, user_role, ...fieldsMapper } = fields;

    for (let [key, value] of Object.entries(content)) {
        if (key === "tasks") {
            for (let [k, v] of Object.entries(fieldsMapper)) {
                setValueByPath(content as any, v, data[k]);
            }
        }
    }
    return dataReturn;
}

export function mapDataToTranslate(fieldsEnglish: IScenarioWithLanguage): {
    data: IDataTranslate;
    fieldsToMapper: IDataTranslate;
} {
    const dataReturn: IDataTranslate = {};
    const fields = generateFiled(fieldsEnglish);
    for (let [key, value] of Object.entries(fields) as any) {
        dataReturn[key] = getValueByPath(fieldsEnglish, value);
    }
    return {
        data: dataReturn,
        fieldsToMapper: fields,
    };
}
