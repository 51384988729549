import { Vue, Options } from "vue-class-component";
import ModalBase from "@/components/base/modal-base/modal-base.vue";
import { ROUTE_NAMES } from "@/constants/routeNames";
import ActionTypes from "@/store/action-types";
import { ModalCategory } from "@/components/base/modals";
import { inject } from "vue";
import { CloseIcon, OffIcon } from "@/assets/images/icons/components";
@Options({
  components: {
    ModalBase,
    ModalCategory,
    CloseIcon,
    OffIcon,
  },
  props: {
    userData: Object,
    triggerSignal: Boolean,
  },
  emits: ["toggle"],
  watch: {
    triggerSignal: {
      handler(val) {
        this.toggleSidebar();
      },
    },
  },
})
export default class SideBarMobile extends Vue {
  public routeName: any = "";
  public isUserTab: any = false;
  public isShowSubMenu: any = {
    [ROUTE_NAMES.AI_CHARACTER]: false,
    [ROUTE_NAMES.SCENARIOS]: false,
  };
  public ROUTE_NAMES = ROUTE_NAMES;
  public userData!: any;

  public handleOpenModalCategory: any = inject("handleOpenModalCategory");
  public handleOpenModalTopic: any = inject("handleOpenModalTopic");
  public handleOpenModalBase() {
    (this.$refs["modal-base"] as any).openModal();
  }

  public routesArray: any = [
    {
      name: "MAIN",
      object: [
        ROUTE_NAMES.DASHBOARD,
        ROUTE_NAMES.ADMINS,
        ROUTE_NAMES.ADVISORS,
        ROUTE_NAMES.ADVISOR_DASHBOARD,
        // ROUTE_NAMES.NOTE_FOR_PARENT,
        // ROUTE_NAMES.CALENDAR,
        ROUTE_NAMES.USERS,
      ],
    },
    {
      name: "PAGE",
      object: [
        // ROUTE_NAMES.SUBSCRIPTION,
        ROUTE_NAMES.AI_CHARACTER,
        ROUTE_NAMES.SCENARIOS,
        // ROUTE_NAMES.MISSION,
        ROUTE_NAMES.ROLEPLAY_PROMPT,
      ],
      // object: [ROUTE_NAMES.SUBSCRIPTION, ROUTE_NAMES.PLUGIN, ROUTE_NAMES.AI_CHARACTER, ROUTE_NAMES.MISSION, ROUTE_NAMES.BLOG, ROUTE_NAMES.ROLEPLAY_PROMPT],
    },
    // {
    //   name: "OTHERS",
    //   object: ["logout"],
    // },
  ];
  public triggerSignal?: boolean;
  public isSidebarOpen: boolean = false;

  public async beforeMount() {
    this.routeName = this.$route.name;
    this.setSideBar(this.userData.role_name);
    // this.apiGetCategoryList();
  }

  mounted(): void {}

  public formatRoute(route: string) {
    return route
      .split(/[-_]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  public async handleTab(route: any) {
    if (route === ROUTE_NAMES.AI_CHARACTER) {
      this.routeName = ROUTE_NAMES.AI_CHARACTER;
      this.isShowSubMenu[ROUTE_NAMES.AI_CHARACTER] =
        !this.isShowSubMenu[ROUTE_NAMES.AI_CHARACTER];
      this.$router.push("/" + route);
      return;
    }
    if (route === ROUTE_NAMES.SCENARIOS) {
      this.routeName = ROUTE_NAMES.SCENARIOS;
      this.isShowSubMenu[ROUTE_NAMES.SCENARIOS] =
        !this.isShowSubMenu[ROUTE_NAMES.SCENARIOS];
      this.$router.push("/" + route);
      return;
    }
    if (route === ROUTE_NAMES.USERS) {
      this.isUserTab = !this.isUserTab;
      this.$router.push("/" + ROUTE_NAMES.PAID_USERS);
      this.routeName = ROUTE_NAMES.PAID_USERS;
      return;
    }
    this.$router.push("/" + route);

    this.routeName = route;
  }

  public handleModalBase() {
    (this.$refs["modal-base"] as any).openModal();
  }

  public handleSignOut() {
    this.$store.dispatch(ActionTypes.AUTH_SIGN_OUT);
  }

  //Assign display permissions by role
  public setSideBar(roleName: string) {
    const requiredFields = ['description', 'display_name', 'email', 'nationality', 'phone'];
    const hasEmptyField = requiredFields.some(field => !this.userData[field]);

    switch (roleName) {
      case "product":
        this.routesArray = [
          {
            name: "MAIN",
            object: [ROUTE_NAMES.DASHBOARD, ROUTE_NAMES.USERS],
          },
          {
            name: "PAGE",
            object: [
              ROUTE_NAMES.AI_CHARACTER,
              ROUTE_NAMES.SCENARIOS,
              ROUTE_NAMES.ROLEPLAY_PROMPT,
            ],
          },
        ];
        break;
      case "marketing":
        this.routesArray = [
          {
            name: "MAIN",
            object: [ROUTE_NAMES.DASHBOARD, ROUTE_NAMES.USERS],
          },
          {
            name: "PAGE",
            object: [
              ROUTE_NAMES.SUBSCRIPTION,
              ROUTE_NAMES.AI_CHARACTER,
              // ROUTE_NAMES.MISSION,
            ],
          },
        ];
        break;
      case "advisor":
          if(hasEmptyField){
            this.routesArray = []
          }
          else {
            this.routesArray = [
              {
                name: "MAIN",
                object: [
                  ROUTE_NAMES.ADVISOR_DASHBOARD,
                ],
              },
            ];
          }
        break;
      case "owner":
        this.routesArray = [
          {
            name: "MAIN",
            object: [
              ROUTE_NAMES.DASHBOARD,
              ROUTE_NAMES.ADMINS,
              ROUTE_NAMES.USERS,
            ],
          },
          {
            name: "PAGE",
            object: [
              ROUTE_NAMES.AI_CHARACTER,
              ROUTE_NAMES.SCENARIOS,
              ROUTE_NAMES.ROLEPLAY_PROMPT,
            ],
          },
        ];
        break;
      default:
        break;
    }
  }

  // public async handleOpenModalCategory() {
  //     (this.$refs["modal-category"] as any).openModal();
  // }

  public toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }
}
