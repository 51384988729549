<template>
  <textarea
    class="meeting-area"
    v-model="text"
    @input="extractMeetingInfo"
    placeholder="Paste the meeting information here with correct format in English"
    rows="5"
  ></textarea>
</template>

<script lang="ts" setup>
import { ref, defineEmits } from "vue";
import { MeetingDetails } from "../../type";

const text = ref<string>("");
const emit = defineEmits<{
  (e: "update:meetingDetails", meetingDetails: MeetingDetails): void;
}>();

const extractMeetingInfo = () => {
  // Regular expressions for matching different fields
  const titleRegex = /^(.+?)\n/; // Captures title on the first line
  const timeRegex =
    /(\w+,\s\w+\s\d+\s·\s[\d:]+(?:am|pm)?\s(?:–|-)\s[\d:]+(?:am|pm)?)/i; // Matches time range
  const timezoneRegex = /Time zone:\s([A-Za-z/_]+)/; // Matches timezone
  const linkRegex = /(https:\/\/meet\.google\.com\/[a-z\-]+)/; // Matches Google Meet link

  // Extracting data using regex
  const titleMatch = text.value.match(titleRegex);
  const timeMatch = text.value.match(timeRegex);
  const timezoneMatch = text.value.match(timezoneRegex);
  const linkMatch = text.value.match(linkRegex);

  // Extracting and formatting the results
  const title = titleMatch ? titleMatch[1].trim() : "";
  const time_info = timeMatch ? timeMatch[1].trim() : "";
  const time_zone = timezoneMatch ? timezoneMatch[1].trim() : "";
  const meeting_link = linkMatch ? linkMatch[1].trim() : "";

  const meetingDetails: MeetingDetails = {
    title,
    time_info,
    time_zone,
    meeting_link,
  };

  emit("update:meetingDetails", meetingDetails);
};

const parseText = () => {
  const lines = text.value.split("\n");
  const title = lines[0]?.trim();
  const time_info = lines[1]?.trim();
  const time_zone = lines[2]?.replace("Time zone:", "").trim();
  const meeting_link = lines[4]?.replace("Video call link:", "").trim();

  const meetingDetails: MeetingDetails = {
    title,
    time_info,
    time_zone,
    meeting_link,
  };

  emit("update:meetingDetails", meetingDetails);
};
</script>

<style lang="scss">
.meeting-area {
  width: 100%;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid #ebebeb;
  background: #ffffff;
  font-size: 16px !important;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
