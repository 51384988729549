<script lang="ts" setup>
import { computed } from "vue";
import ActivityBox, { ActivityProps } from "./ActivityBox.vue";
import { SquaresIcon } from "@/assets/images/icons/components";
import { LIST_ACTIVITY_TYPES } from "../../constants";
import CardContainer from "../common/CardContainer.vue";
import Dropdown from "../common/Dropdown.vue";
import NoData from "../common/NoData.vue";
import { ActivityDataFromAPI } from "../../type";

interface Props {
  data: ActivityDataFromAPI | null;
  isLoadingMore?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  data: null,
  isLoadingMore: false,
});

const emits = defineEmits(["on-select-feature", "on-load"]);

const listActivities = computed<ActivityProps[]>(() => {
  if (props.data?.data) {
    return props.data.data;
  }
  return [];
});
const totalCount = computed<number>(() => {
  if (props.data?.total) {
    return props.data.total;
  }
  return 0;
});

function selectActivityType(value: string) {
  console.log("🚀 ~ Activity Type ~ value:", value);
  emits("on-select-feature", value);
}

function loadMoreActivities() {
  console.log("🚀 Loading More");
  emits("on-load");
}
</script>

<template>
  <CardContainer>
    <div class="actCard">
      <div class="actCard-title">
        <p>All activities</p>
      </div>
      <div
        class="actCard-controls"
        v-if="listActivities && listActivities.length > 0"
      >
        <div class="actCard-filters">
          <Dropdown
            :Icon="SquaresIcon"
            :dataOptions="LIST_ACTIVITY_TYPES"
            :labelPlaceholder="'All features'"
            :selectedDefault="0"
            @on-selected="selectActivityType"
          />
        </div>
      </div>
      <div
        v-if="listActivities && listActivities.length > 0"
        class="actCard-list"
      >
        <ActivityBox
          v-for="item in listActivities"
          :key="item.id"
          v-bind="item"
        />
      </div>
      <div class="actCard-not-found" v-else>
        <NoData />
      </div>
      <div
        v-if="listActivities && listActivities.length < totalCount"
        class="actCard-load-more"
      >
        <button
          v-if="!isLoadingMore"
          class="button-outline"
          @click="loadMoreActivities"
        >
          Load More
        </button>
        <button v-else class="button-outline">Loading...</button>
      </div>
    </div>
  </CardContainer>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";

.actCard {
  @include flexbox(column, center, center, null, null, 16px);
  &-title {
    @include title;
    width: 100%;
    p {
      text-align: left;
    }
  }
  &-list {
    @include flexbox(column, flex-start, flex-start, null, null, 12px);
    width: 100%;
    height: 100%;
    z-index: 99;
    overflow: auto;
  }
  &-controls {
    @include flexbox(row, space-between, center);
    width: 100%;
  }
  &-filters {
    @include flexbox(row, flex-start, center, wrap, null, 8px);
  }
  &-load-more {
    @include flexbox(row, center, center);
    margin: 1rem 0;
    width: 100%;
  }
  &-not-found {
    @include flexbox(row, center, center);
    width: 100%;
  }
}
</style>
