import ApiService from "@/services/api";
import { AxiosResponse } from "axios";

class MainUtils {
  constructor() {}

  isValidEmail(email: string) {
    // Regular expression pattern for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailPattern.test(email);
  }

  async downloadFile(url: string, filename: string) {
    // Create an anchor element
    const anchor: any = document.createElement("a");
    anchor.style.display = "none";
    document.body.appendChild(anchor);

    // Set the URL and filename
    anchor.href = url;
    anchor.download = filename;

    // Trigger a click event on the anchor to initiate the download
    anchor.click();

    // Remove the anchor element
    document.body.removeChild(anchor);
  }

  convertDatetimeFormat(datetimeString: string) {
    if (!datetimeString) {
      return "N/A";
    }
    const date = new Date(datetimeString + "Z");
    const options: any = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Use 24-hour format
      timeZone: "Etc/GMT-7",
    };
    return date.toLocaleString("en-US", options);
  }
}
export default new MainUtils();

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function timeDifference(timeString: string) {
  const pastDate = new Date(timeString + "Z");
  const now = new Date();

  // Calculate the difference in milliseconds
  const diffMs = now.getTime() - pastDate.getTime();

  // Convert milliseconds to different units
  const diffSeconds = Math.floor(diffMs / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffWeeks = Math.floor(diffDays / 7);
  const diffMonths = Math.floor(diffDays / 30); // Approximation, assuming 30 days in a month
  const diffYears = Math.floor(diffDays / 365); // Approximation, assuming 365 days in a year

  // Return the appropriate time difference
  if (diffMinutes < 60) {
    return `${diffMinutes} minutes ago`;
  } else if (diffHours < 24) {
    return `${diffHours} hours ago`;
  } else if (diffDays < 7) {
    return `${diffDays} days ago`;
  } else if (diffWeeks < 4) {
    return `${diffWeeks} weeks ago`;
  } else if (diffMonths < 12) {
    return `${diffMonths} months ago`;
  } else {
    return `${diffYears} years ago`;
  }
}

export const fetchWrapper = async (url: string, params = {}) => {
  try {
    const response = (await ApiService.executeGetRequest(
      url,
      params
    )) as AxiosResponse;
    if (response.status === 200) {
      return {
        isSuccess: true,
        data: response.data,
      };
    } else {
      return {
        isSuccess: false,
        error: response.data?.message || "Unknown error occurred",
        data: null,
      };
    }
  } catch (error) {
    return {
      isSuccess: false,
      error: error || "Unknown error occurred",
      data: null,
    };
  }
};
