<script lang="ts" src="./update-topic-for-scenario"></script>
<style lang="scss" src="./update-topic-for-scenario.scss"></style>
<template>
    <div class="modal" ref="update-topic-for-scenario" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <!-- Header -->
                <div
                    class="d-flex flex-row justify-content-between w-100 header"
                >
                    <p
                        class="title"
                        style="color: #222; font-size: 18px; font-weight: 600"
                    >
                        Select Topic
                    </p>
                    <button
                        id="close-modal-video-topic"
                        ref="close-add-admin-modal"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>

                <!-- Body -->
                <div v-if="scenarioEditProps?.id" class="content-box">
                    <p>Scenario: {{ scenarioEditProps.name }}</p>
                    <DropdownSelect
                        placeholder="Choose a topic"
                        :options="topicsDataProps.map((i: any) => ({
                                value: i.id,
                                text: i.name,
                                active: true,
                            }))"
                        @selectOption="handleSelectedChangeTopic"
                    />
                    <ButtonSolid
                        @click="handleSaveScenario"
                        text="Save"
                        :loading="loading"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
