<script src="./vocabulary.ts" lang="ts"></script>
<template>
    <!-- Loading scenario -->
    <AccordingPanel
        idTarget="vocabulary"
        className="accordion-vocabulary vocabulary-container small-button"
        :isToggleHeader="false"
    >
        <template #title>
            <div class="vc-header">
                <h2 class="vc-hd-title">
                    <span class="text-title">Vocabulary and Sentence</span>
                    <span class="title-warning">
                        (Please complete creating your scenario before setting
                        up the vocabulary)
                    </span>
                </h2>
                <button
                    class="button-solid border-radius-12"
                    @click="handleSaveVocabulary"
                >
                    Save
                </button>
            </div>
        </template>

        <template #content>
            <template v-if="loading.getVocabulary || loading.autoGenerate">
                <Spinner type="overlay" />
            </template>
            <div
                class="bd-hd-actions"
                :class="loading.autoGenerate ? 'disabled' : ''"
            >
                <DropdownSelect
                    placeholder="Choose language"
                    :options="selectOptions.languagesDropdown"
                    @selectOption="onChangeSelectLanguageAutoGenerate"
                    class="language-dropdown"
                >
                    <template #icon>
                        <WebsiteOutlineIcon class="website-icon" />
                    </template>
                </DropdownSelect>
                <button
                    @click="handleAutoGenerate"
                    class="button-solid border-radius-12"
                    :disabled="!enabled"
                >
                    <AutoIcon v-if="!loading.autoGenerate" />
                    <span
                        v-else
                        class="me-2 spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    />
                    Auto generate
                </button>
                <button
                    v-if="languageGeneratedFail.length > 0"
                    @click="handleAutoGenerateForFail"
                    class="button-outline border-radius-12"
                    :disabled="!enabled"
                >
                    <AutoIcon />
                    Regenerate for Failure
                </button>
            </div>
            <div
                v-if="enabled"
                class="hd-bottom"
                :class="[
                    loading.getVocabulary || loading.autoGenerate
                        ? 'disabled'
                        : '',
                ]"
            >
                <MenuLanguagesSelection
                    :menuSelections="selectOptions.languages"
                    :loading="loading.autoGenerate"
                    :languageSelected="languageSelected"
                    :checkList="checkListLanguage"
                    :enabledTranslate="false"
                    :enabledWarningIcon="true"
                    @handleSelectLanguage="handleSelectLanguage"
                />
            </div>
            <div
                v-if="enabled"
                :class="[
                    loading.getVocabulary || loading.autoGenerate
                        ? 'disabled'
                        : '',
                ]"
                class="vc-form"
            >
                <template
                    v-if="refVocabulary"
                    v-for="(v, i) in refVocabulary[languageSelected]"
                    :key="i"
                >
                    <VocabularyItem
                        :vocabulary="v"
                        @on-change-input="onUpdateVocabulary"
                        :index="i"
                    />
                </template>
            </div>
            <div
                v-if="enabled"
                :class="loading.getVocabulary ? 'disabled' : ''"
                class="vc-footer"
            >
                <button
                    class="button-solid border-radius-12"
                    @click="handleSaveVocabulary"
                >
                    Save vocabulary
                </button>
            </div>
        </template>
    </AccordingPanel>
</template>
<style lang="scss" scoped>
.vocabulary-container {
    padding: 15px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border-radius: var(--radius-md);
    /* position: ; */
    overflow: visible;
    box-shadow: none;
    background-color: white;
    padding: 0;
    .language-dropdown {
        width: 200px;
        text-align: left;
        .website-icon {
            color: var(--neutral-400);
        }
    }
    .vc-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        .vc-hd-title {
            font-size: 16px;
            text-transform: uppercase;
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            text-align: left;
            gap: 5px;

            .title-warning {
                color: var(--warning-500);
                text-transform: capitalize;
                font-size: 13px;
            }
        }
    }
    .bd-hd-actions {
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;
    }
    .vc-form {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .vc-footer {
        display: flex;
        gap: 10px;
        justify-content: center;

        button {
            width: 180px;
        }
    }
}
</style>
