// @ts-nocheck
type ActionTypesPersonAiType = {
    GET_PERSON_AIS: string;
    UPDATE_PERSON_AI: string;
    CREATE_PERSON_AI: string;
    DELETE_PERSON_AI: string;
    EXPORT_EXCEL: string;
    UPLOAD_AVATAR: string;
    UPLOAD_VIDEO: string;
    AUTO_TRANSLATE: string;
    AUTO_TRANSLATE_INFO_PROMPT: string;
    GET_PERSON_AI_INFO: string;
    UPDATE_PERSON_AI_DETAIL: string;
    GET_VIDE0_CALL_TOPICS: string;
    GET_VIDE0_CALL_VOICES: string;
    UPDATE_VIDEO_CALL_SCENARIO: string;
    UPDATE_VIDEO_CALL_TOPICS: string;
    CREATE_VIDEO_CALL_SCENARIO: string;
    CREATE_VIDEO_CALL_TOPICS: string;
    DELETE_VIDEO_CALL_TOPICS: string;
    DELETE_VIDEO_CALL_SCENARIO: string;
    GET_VIDEO_CALL_CHAT_MODELS: string;
    GET_ALL_AIS_PERSON_DISTINCT: string;
    GET_ALL_AIS_PERSON_VIDEO_CALL_ENABLED: string

    // Category
    GET_CATEGORY_LIST: string;
    CREATE_CATEGORY: string;
    UPDATE_CATEGORY: string;
    DELETE_CATEGORY_LIST: string;

    // Sample Chat
    GET_SAMPLE_CHAT_V1: string;
    CREATE_SAMPLE_CHAT_V1: string;
    UPDATE_SAMPLE_CHAT_V1: string;
    GENERATE_SAMPLE_CHAT_V1: string;

    //Link_Request
    CREATE_LINK_REQUEST_V1: string;
    PRE_CHECK_CREATE_LINK_REQUEST_V1: string;

    //Advisor
    GET_LIST_PARENT_V1: string;
    LIST_PARENT_BY_QUERY: string;
    GET_PARENT_BY_ID:string;

    // ========================= API Version 2 =========================

    // AI Character V2
    GET_PERSON_AI_INFO_V2: string;
    GET_STORY_OF_PERSON_AI_V2: string;
    GET_PERSON_AIS_V2: string;
    UPDATE_PERSON_AI_DETAIL_V2: string;
    UPDATE_PROMOTED_PERSON_AIS_V2: string;
    CREATE_PERSON_AI_V2: string;

    // AI Topic V2
    GET_VIDEO_CALL_TOPICS_V2: string;
    DELETE_VIDEO_CALL_TOPIC_V2: string;
    DELETE_VIDEO_CALL_TOPICS_V2: string;
    UPDATE_VIDEO_CALL_TOPICS_V2: string;
    CREATE_VIDEO_CALL_TOPICS_V2: string;

    // AI Scenario V2
    GET_VIDEO_CALL_SCENARIO_V2: string;
    UPDATE_VIDEO_CALL_SCENARIO_V2: string;
    UPDATE_VIDEO_CALL_SCENARIO_OPTION_2_V2: string;
    CREATE_VIDEO_CALL_SCENARIO_V2: string;
    GET_SCENARIOS_TASK_EDIT_TEMPLATE_V2: string;
    DELETE_VIDEO_CALL_SCENARIO_V2: string;
    AUTOGENERATE_TASK_VIDEO_CALL_SCENARIO_V2: string;
    AUTOGENERATE_CONTEXT_VIDEO_CALL_SCENARIO_V2: string;

    // Utilities V2
    UPLOAD_AVATAR_V2: string;
    AUTO_TRANSLATE_TEXT_V2: string;

    // ========================= API Version 3 =========================
    // AI Scenario V3
    GET_SCENARIOS_V3: string;
    GET_SCENARIO_V3: string;
    UPDATE_SCENARIO_V3: string;
    DELETE_SCENARIOS_V3: string;
    DELETE_SCENARIO_V3: string;
    MARK_FREE_SCENARIOS_V3: string;
    GET_TEMPLATE_TASKS_SCENARIO_V3: string;
};

export const ActionTypesPersonAi: ActionTypesPersonAiType = {
    // ========================= API Version 1 =========================

    GET_PERSON_AIS: "GET_PERSON_AIS",
    UPDATE_PERSON_AI: "UPDATE_PERSON_AI",
    CREATE_PERSON_AI: "CREATE_PERSON_AI",
    DELETE_PERSON_AI: "DELETE_PERSON_AI",
    EXPORT_EXCEL: "EXPORT_EXCEL",
    UPLOAD_AVATAR: "UPLOAD_AVATAR",
    UPLOAD_VIDEO: "UPLOAD_VIDEO",
    AUTO_TRANSLATE: "AUTO_TRANSLATE",
    AUTO_TRANSLATE_INFO_PROMPT: "AUTO_TRANSLATE_INFO_PROMPT",
    GET_PERSON_AI_INFO: "GET_PERSON_AI_INFO",
    UPDATE_PERSON_AI_DETAIL: "UPDATE_PERSON_AI_DETAIL",
    GET_VIDE0_CALL_TOPICS: "GET_VIDE0_CALL_TOPICS",
    GET_VIDE0_CALL_VOICES: "GET_VIDE0_CALL_VOICES",
    UPDATE_VIDEO_CALL_SCENARIO: "UPDATE_VIDEO_CALL_SCENARIO",
    UPDATE_VIDEO_CALL_TOPICS: "UPDATE_VIDEO_CALL_TOPICS",
    CREATE_VIDEO_CALL_SCENARIO: "CREATE_VIDEO_CALL_SCENARIO",
    CREATE_VIDEO_CALL_TOPICS: "CREATE_VIDEO_CALL_TOPICS",
    DELETE_VIDEO_CALL_TOPICS: "DELETE_VIDEO_CALL_TOPICS",
    DELETE_VIDEO_CALL_SCENARIO: "DELETE_VIDEO_CALL_SCENARIO",
    GET_VIDEO_CALL_CHAT_MODELS: "GET_VIDEO_CALL_CHAT_MODELS",
    GET_ALL_AIS_PERSON_DISTINCT: "GET_ALL_AIS_PERSON_DISTINCT",
    GET_ALL_AIS_PERSON_VIDEO_CALL_ENABLED: "GET_ALL_AIS_PERSON_VIDEO_CALL_ENABLED",

    // Category
    GET_CATEGORY_LIST: "GET_CATEGORY_LIST",
    CREATE_CATEGORY: "CREATE_CATEGORY",
    UPDATE_CATEGORY: "UPDATE_CATEGORY",
    DELETE_CATEGORY_LIST: "DELETE_CATEGORY_LIST",

    // ========================= API Version 2 =========================

    // AI Character V2
    GET_PERSON_AIS_V2: "GET_PERSON_AIS_V2",
    GET_STORY_OF_PERSON_AI_V2: "GET_STORY_OF_PERSON_AI_V2",
    GET_PERSON_AI_INFO_V2: "GET_PERSON_AI_INFO_V2",
    UPDATE_PERSON_AI_DETAIL_V2: "UPDATE_PERSON_AI_DETAIL_V2",
    UPDATE_PROMOTED_PERSON_AIS_V2: "UPDATE_PROMOTED_PERSON_AIS_V2",
    CREATE_PERSON_AI_V2: "CREATE_PERSON_AI_V2",

    // AI Topic V2
    GET_VIDEO_CALL_TOPICS_V2: "GET_VIDEO_CALL_TOPICS_V2",
    DELETE_VIDEO_CALL_TOPIC_V2: "DELETE_VIDEO_CALL_TOPIC_V2",
    DELETE_VIDEO_CALL_TOPICS_V2: "DELETE_VIDEO_CALL_TOPICS_V2",
    UPDATE_VIDEO_CALL_TOPICS_V2: "UPDATE_VIDEO_CALL_TOPICS_V2",
    CREATE_VIDEO_CALL_TOPICS_V2: "CREATE_VIDEO_CALL_TOPICS_V2",

    // AI Scenario V2
    GET_VIDEO_CALL_SCENARIO_V2: "GET_VIDEO_CALL_SCENARIO_V2",
    UPDATE_VIDEO_CALL_SCENARIO_V2: "UPDATE_VIDEO_CALL_SCENARIO_V2",
    UPDATE_VIDEO_CALL_SCENARIO_OPTION_2_V2:
        "UPDATE_VIDEO_CALL_SCENARIO_OPTION_2_V2",
    CREATE_VIDEO_CALL_SCENARIO_V2: "CREATE_VIDEO_CALL_SCENARIO_V2",
    GET_SCENARIOS_TASK_EDIT_TEMPLATE_V2: "GET_SCENARIOS_TASK_EDIT_TEMPLATE_V2",
    DELETE_VIDEO_CALL_SCENARIO_V2: "DELETE_VIDEO_CALL_SCENARIO_V2",
    AUTOGENERATE_TASK_VIDEO_CALL_SCENARIO_V2:
        "AUTOGENERATE_TASK_VIDEO_CALL_SCENARIO_V2",
    AUTOGENERATE_CONTEXT_VIDEO_CALL_SCENARIO_V2:
        "AUTOGENERATE_CONTEXT_VIDEO_CALL_SCENARIO_V2",

    // Utilities V2
    UPLOAD_AVATAR_V2: "UPLOAD_AVATAR_V2",
    AUTO_TRANSLATE_TEXT_V2: "AUTO_TRANSLATE_TEXT_V2",

    // ========================= API Version 3 =========================
    // AI Scenario V3
    GET_SCENARIOS_V3: "GET_SCENARIOS_V3",
    GET_SCENARIO_V3: "GET_SCENARIO_V3",
    UPDATE_SCENARIO_V3: "UPDATE_SCENARIO_V3",
    DELETE_SCENARIOS_V3: "DELETE_SCENARIOS_V3",
    DELETE_SCENARIO_V3: "DELETE_SCENARIO_V3",
    MARK_FREE_SCENARIOS_V3: "MARK_FREE_SCENARIOS_V3",
    GET_TEMPLATE_TASKS_SCENARIO_V3: "GET_TEMPLATE_TASKS_SCENARIO_V3",
};

function transformObjectProperties(targetObject, prefix) {
    const transformedObject = {};

    // Iterate over the object properties
    for (const key in targetObject) {
        transformedObject[key] = `${prefix}/${targetObject[key]}`;
    }

    return transformedObject;
}
export const TransformedActionTypesPersonAi: ActionTypesPersonAiType =
    transformObjectProperties(ActionTypesPersonAi, "personAi");
