<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.9998 15.7063C11.4406 15.7063 10.9719 16.175 10.9719 16.7341C10.9719 17.2933 11.4406 17.762 11.9998 17.762C12.5383 17.762 13.0276 17.2933 13.0029 16.7588C13.0276 16.1709 12.563 15.7063 11.9998 15.7063Z"
            fill="currentColor"
        />
        <path
            d="M21.5133 19.3488C22.1588 18.2347 22.1629 16.9067 21.5215 15.7967L15.0832 4.64682C14.446 3.52443 13.2948 2.8584 12.0039 2.8584C10.7129 2.8584 9.56174 3.52854 8.92449 4.64271L2.47796 15.8049C1.8366 16.9273 1.84071 18.2635 2.49029 19.3776C3.13166 20.4795 4.27871 21.1414 5.56144 21.1414H18.4216C19.7084 21.1414 20.8637 20.4712 21.5133 19.3488ZM20.1155 18.543C19.7578 19.1597 19.1246 19.5256 18.4175 19.5256H5.55733C4.85841 19.5256 4.22938 19.168 3.87991 18.5636C3.52634 17.951 3.52223 17.2192 3.8758 16.6025L10.3223 5.44441C10.6718 4.83183 11.2967 4.47003 12.0039 4.47003C12.7069 4.47003 13.3359 4.83594 13.6854 5.44852L20.1278 16.6066C20.4732 17.2069 20.469 17.9304 20.1155 18.543Z"
            fill="currentColor"
        />
        <path
            d="M11.7449 8.49093C11.2557 8.63072 10.9514 9.07474 10.9514 9.61332C10.9761 9.93811 10.9966 10.267 11.0213 10.5918C11.0912 11.8293 11.1611 13.0421 11.231 14.2797C11.2557 14.699 11.5804 15.0032 11.9998 15.0032C12.4192 15.0032 12.7481 14.6784 12.7686 14.255C12.7686 14.0001 12.7686 13.7657 12.7933 13.5067C12.8385 12.7132 12.8878 11.9198 12.9331 11.1263C12.9577 10.6124 13.003 10.0985 13.0276 9.58454C13.0276 9.39953 13.003 9.23508 12.9331 9.07063C12.7234 8.61016 12.2341 8.37581 11.7449 8.49093Z"
            fill="currentColor"
        />
    </svg>
</template>
