<script lang="ts" setup>
import { AccordingPanel, FLForm } from "@/components/base";
import { IVocabularyItem, TVocabularyItemFields } from "../utils/type";
import { ref } from "vue";
import { maxCharacterLengthFields } from "../utils/constants";
const { FLInput } = FLForm;
const fields = {
    id: "id",
    word: "Word",
    type: "Type",
    word_phonics_pronounce: "WVP for phonics",
    word_variant_pronounce: "Word variant pronounce (WVP)",
    example_sentence: "Example sentence",
    example_variant_pronounce: "Example variant pronounce",
    example_phonics_pronounce: "Example phonics pronounce",
};
interface Props {
    vocabulary: IVocabularyItem;
    index: number;
}
const props = defineProps<Props>();
const emits = defineEmits(["on-change-input"]);
const onChangeInput = () => {
    emits("on-change-input", props.vocabulary);
    // console.log("on-change-input");
};
// const emits = defineEmits(['update:modelValue']);

// const updateValue = (event) => {
//   emits('update:modelValue', event.target.value);
// };

function getMaxCharacterLength(key: TVocabularyItemFields) {
    // Max length 250 characters
    if (maxCharacterLengthFields[250].includes(key)) return 250;

    // Max length 60 characters
    if (maxCharacterLengthFields[60].includes(key)) return 60;

    return 250;
}

function getLengthFiled(k: any) {
    //@ts-ignore
    return props.vocabulary[k]?.length || 0;
}
</script>

<template>
    <AccordingPanel
        :idTarget="`vocabulary-item-${index}`"
        className="accordion-vocabulary-item small-button"
    >
        <template #title>
            <div class="title-input">Vocabulary {{ index + 1 }}</div>
        </template>
        <template #content>
            <div class="vocabulary-input-controls">
                <template v-for="(v, k) in fields as {}" :key="k">
                    <FLInput
                        v-if="k !== 'id'"
                        :label="v"
                        require
                        :hint="`${getLengthFiled(k)}/${getMaxCharacterLength(
                            k
                        )} characters`"
                        :error="
                            getLengthFiled(k) > getMaxCharacterLength(k)
                                ? `The content is too long ${getLengthFiled(
                                      k
                                  )}/${getMaxCharacterLength(k)} characters`
                                : null
                        "
                    >
                        <input
                            :class="[
                                [
                                    'example_sentence',
                                    'example_variant_pronounce',
                                ].includes(k)
                                    ? ''
                                    : 'input-inline',
                                //@ts-ignore
                                k.includes('pronounce') ? 'sans-serif' : '',
                            ]"
                            :data-cy="`vocabulary-${vocabulary.id}`"
                            v-model="vocabulary[k]"
                            :maxlength="getMaxCharacterLength(k)"
                            placeholder="Enter your text"
                            @change="onChangeInput"
                        />
                    </FLInput>
                </template>
            </div>
        </template>
    </AccordingPanel>
</template>

<style lang="scss">
.sans-serif {
    font-family: sans-serif;
}
.accordion.accordion-vocabulary-item {
    background-color: var(--second-01-day, #f7f8f7);
    box-shadow: none;
    .title-input {
        color: var(--primary-500);
    }
}
.vocabulary-input-controls {
    display: flex;
    flex: auto;
    gap: 15px;
    flex-wrap: wrap;

    .input-inline {
        flex: 1;
    }
}
</style>
