<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { Modal } from "bootstrap";

const myModal: any = ref(null);
const emits = defineEmits(["on-close", "on-open"]);
function openModal() {
    myModal.value.show();
    emits("on-open");
}

function closeModal() {
    if (myModal.value) myModal.value.hide();
}
defineExpose({
    closeModal,
    openModal,
});
onMounted(() => {
    myModal.value = new Modal(myModal.value, {
        backdrop: "static",
        keyboard: false,
    });
    myModal.value._element.addEventListener(
        "hidden.bs.modal",
        emits("on-close")
    );
});
</script>
<template>
    <div class="modal" ref="myModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <!-- Header -->
                <div
                    v-if="$slots.title"
                    class="d-flex flex-row justify-content-between w-100 header"
                >
                    <slot name="title"></slot>
                    <button
                        id="close-modal"
                        type="button"
                        class="btn-close"
                        aria-label="Close"
                        @click="closeModal"
                    ></button>
                </div>

                <!-- Body -->
                <div class="content-box">
                    <slot name="body"></slot>
                </div>

                <!-- Footer -->
                <slot name="footer"> </slot>
            </div>
        </div>
    </div>
</template>
