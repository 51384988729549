<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.875 7.875H4.125C3.4875 7.875 3 7.3875 3 6.75C3 6.1125 3.4875 5.625 4.125 5.625H19.875C20.5125 5.625 21 6.1125 21 6.75C21 7.3875 20.5125 7.875 19.875 7.875Z"
      fill="#222222"
    />
    <path
      d="M19.875 13.125H4.125C3.4875 13.125 3 12.6375 3 12C3 11.3625 3.4875 10.875 4.125 10.875H19.875C20.5125 10.875 21 11.3625 21 12C21 12.6375 20.5125 13.125 19.875 13.125Z"
      fill="#222222"
    />
    <path
      d="M19.875 18.375H4.125C3.4875 18.375 3 17.8875 3 17.25C3 16.6125 3.4875 16.125 4.125 16.125H19.875C20.5125 16.125 21 16.6125 21 17.25C21 17.8875 20.5125 18.375 19.875 18.375Z"
      fill="#222222"
    />
  </svg>
</template>
