<script lang="ts" setup>
import { computed, defineProps } from "vue";
import ProcessingBox from "./ProcessingBox.vue";

import {
  LearningProcessDataForVisualization,
  LearningProcessDataFromAPI,
} from "../../type";
import {
  LEARNING_PROCESS_DEFAULT_DATA_FOR_VISUALIZATION,
  LEARNING_PROCESS_DEFAULT_DATA_FROM_API,
  LEARNING_PROCESS_KEYS,
} from "../../constants";
interface Props {
  data: LearningProcessDataFromAPI;
}
const props = withDefaults(defineProps<Props>(), {
  data: () => LEARNING_PROCESS_DEFAULT_DATA_FROM_API,
});
const emits = defineEmits(["update-filter"]);
// Covert the data from the API to the data suitable for visualization
const dataForVisualization = computed<LearningProcessDataForVisualization>(
  () => {
    let visualizationData: LearningProcessDataForVisualization =
      LEARNING_PROCESS_DEFAULT_DATA_FOR_VISUALIZATION;
    if (props.data) {
      for (let i = 0; i < LEARNING_PROCESS_KEYS.length; i++) {
        const key = LEARNING_PROCESS_KEYS[i];
        const value = props.data[key];
        if (value) {
          visualizationData[key].listChildren = value;
        } else {
          visualizationData[key].listChildren = [];
        }
      }

      return visualizationData;
    }
    return LEARNING_PROCESS_DEFAULT_DATA_FOR_VISUALIZATION;
  }
);
</script>

<template>
  <div class="procList">
    <div class="procList-content">
      <ProcessingBox
        v-for="item in LEARNING_PROCESS_KEYS"
        :key="item"
        v-bind="dataForVisualization[item]"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";
.procList {
  @include size(100%, 100%);
  &-content {
    @include flexbox(row, flex-start, flex-start, nowrap, null, 16px);
    @include size(100%, 100%);
    @include responsive-for(tablet) {
      @include size(fit-content, 100%);
    }
  }

  overflow: auto;
}
</style>
