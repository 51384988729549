// @ts-nocheck
type GetterTypesPersonAiType = {
    GET_ALLOW_EDIT_LIST: string,
    GET_PERSON_AI_EDIT_ID: string,
};

export const GetterTypesPersonAi: GetterTypesPersonAiType = {
    GET_ALLOW_EDIT_LIST: 'GET_ALLOW_EDIT_LIST',
    GET_PERSON_AI_EDIT_ID: 'GET_PERSON_AI_EDIT_ID',
};


function transformObjectProperties(targetObject, prefix) {
    const transformedObject = {};

    // Iterate over the object properties
    for (const key in targetObject) {
        transformedObject[key] = `${prefix}/${targetObject[key]}`;
    }

    return transformedObject;
}
;

export const TransformedGetterTypesPersonAi: GetterTypesPersonAiType = transformObjectProperties(GetterTypesPersonAi, 'personAi');
