// @ts-nocheck
type MutationTypesTableType = {
    RESET_STATE: string,
    ADD_SELECTED_ROW: string,
    REMOVE_SELECTED_ROW: string,
    RESET_SELECTED_ROW: string,
    SELECT_All_ROW: string,
    SET_TABLE_DATA: string,
    QUERY_TABLE_DATA: string,
    SET_PAYLOAD_EDIT: string,
    SET_ALLOW_EDIT_LIST: string,
    SET_ADD_ACTION: string,
    SET_ALLOW_SHOW_HEADER: string,
    SET_LIST_IDS_DELETE: string,
    SET_HEADER_ARRAY: string,
};

export const MutationTypesTable: MutationTypesTableType = {
    RESET_STATE: 'RESET_STATE',
    ADD_SELECTED_ROW: 'ADD_SELECTED_ROW',
    REMOVE_SELECTED_ROW: 'REMOVE_SELECTED_ROW',
    RESET_SELECTED_ROW: 'RESET_SELECTED_ROW',
    SELECT_All_ROW: 'SELECT_All_ROW',
    SET_TABLE_DATA: 'SET_TABLE_DATA',
    QUERY_TABLE_DATA: 'QUERY_TABLE_DATA',
    SET_PAYLOAD_EDIT: 'SET_PAYLOAD_EDIT',
    SET_ALLOW_EDIT_LIST: 'SET_ALLOW_EDIT_LIST',
    SET_ADD_ACTION: 'SET_ADD_ACTION',
    SET_ALLOW_SHOW_HEADER: 'SET_ALLOW_SHOW_HEADER',
    SET_LIST_IDS_DELETE: 'SET_LIST_IDS_DELETE',
    SET_HEADER_ARRAY: 'SET_HEADER_ARRAY',
};


function transformObjectProperties(targetObject, prefix) {
    const transformedObject = {};

    // Iterate over the object properties
    for (const key in targetObject) {
        transformedObject[key] = `${prefix}/${targetObject[key]}`;
    }

    return transformedObject;
}
;

export const TransformedMutationTypesTable: MutationTypesTableType = transformObjectProperties(MutationTypesTable, 'table');
