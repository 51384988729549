import ModalTopicVideoCall from
'../../modal-topic-video-call/modal-topic-video-call';
<script lang="ts" src="./video-call-topic.ts"></script>
<style lang="scss" scoped src="./video-call-topic.scss"></style>

<template>
    <ModalBase
        ref="modal-base-delete-topic"
        title="Delete this topic"
        btnAction="Yes"
        btnCancel="Cancel"
        :description="
            isMultipleDelete
                ? `Are you sure you want to delete ${topicsDataSelected.length} topics?`
                : `Are you sure you want to delete this topic?`
        "
        :isDanger="true"
        @handleClickBtnAction="onDeleteTopic"
        @handleClickCancel="handleCancelDeleteTopic"
    />
    <div
        class="modal"
        ref="modal-topic-video-call"
        tabindex="-1"
        :style="{ opacity: isShowPopupConfirm ? '0' : '1' }"
    >
        <div class="modal-dialog modal-dialog-centered">
            <!-- List Video Topic -->
            <div v-if="isStateModal === 'list'" class="modal-content">
                <div
                    class="d-flex flex-row justify-content-between w-100 header"
                >
                    <p style="color: #222; font-size: 18px; font-weight: 600">
                        Video topic list
                    </p>
                    <button
                        id="close-modal-video-topic"
                        ref="close-add-admin-modal"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>

                <p class="description-topic">
                    Topics come with badges assigned (managed in the Badge tab
                    on the left admin panel menu).
                </p>

                <div
                    class="d-flex flex-column w-100"
                    style="overflow: auto; gap: 24px"
                >
                    <div
                        class="topic-item justify-content-start"
                        @click="handleAddNewTopic"
                    >
                        <img src="@/assets/images/icons/svg/add.svg" />
                        <p
                            style="
                                color: #169c8a;
                                font-size: 14px;
                                font-weight: 500;
                            "
                        >
                            Add new topic
                        </p>
                    </div>
                    <div class="topics-container">
                        <div
                            v-for="(item, _index) in topicsData"
                            :style="
                                topicsDataSelected.includes(item?.id)
                                    ? 'background: #E8F3EC'
                                    : ''
                            "
                            :key="_index"
                            class="topic-item"
                        >
                            <p style="width: 100%; text-align: start">
                                <label :htmlFor="`topic-item-${item.id}`">
                                    <input
                                        :id="`topic-item-${item.id}`"
                                        type="checkbox"
                                        style="display: none"
                                        @click="(e: any) =>
                                                handleCheckChooseTopic(
                                                    item,
                                                    e.target.checked
                                                )"
                                    />
                                    <img
                                        v-if="
                                            topicsDataSelected.includes(
                                                item?.id
                                            )
                                        "
                                        src="@/assets/images/icons/svg/check-input.svg"
                                        style="cursor: pointer"
                                    />
                                    <img
                                        v-else
                                        src="@/assets/images/icons/svg/uncheck-input.svg"
                                        style="cursor: pointer"
                                    />
                                </label>
                                <span>
                                    <img
                                        class="image-topic"
                                        v-if="item?.image_url"
                                        :src="item?.image_url"
                                    />
                                    <img
                                        v-else
                                        style="width: 30px; margin: 0 4px"
                                        src="@/assets/images/icons/svg/edit-image-gray.svg"
                                    />
                                </span>
                                {{ item?.name }}
                            </p>
                            <i
                                class="bi bi-pencil"
                                style="cursor: pointer"
                                @click="handleEditTopic(item)"
                            ></i>
                        </div>
                    </div>
                </div>

                <div
                    v-if="topicsDataSelected.length > 0"
                    class="d-flex flex-row justify-content-between w-100"
                    style="gap: 16px"
                >
                    <button
                        class="button-delete"
                        @click="handleOpenDeleteTopicConfirm(true)"
                    >
                        Delete
                    </button>
                    <div
                        class="d-flex flex-row justify-content-end w-100"
                        style="gap: 16px"
                    >
                        <button
                            class="button-cancel"
                            @click="handleUnSelectAllTopic"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            <!-- End List Video Topic -->

            <!-- Create new topic -->
            <div v-else class="modal-content" style="height: fit-content">
                <div class="component-create-new-topic">
                    <div
                        class="d-flex flex-row justify-content-between w-100 header"
                    >
                        <p
                            style="
                                color: #222;
                                font-size: 25px;
                                font-weight: 600;
                                text-align: center;
                                flex: auto;
                                margin-bottom: 16px !important;
                            "
                        >
                            {{
                                isStateModal === "create"
                                    ? "Create new topic"
                                    : "Edit topic"
                            }}
                        </p>
                        <button
                            class="btn-close"
                            aria-label="Close"
                            @click="isStateModal = 'list'"
                        ></button>
                    </div>

                    <div class="create-new-avatar">
                        <label style="cursor: pointer">
                            <div class="circle-avatar">
                                <template v-if="topicForm.image_url">
                                    <img
                                        class="img-avatar"
                                        :src="topicForm.image_url"
                                        alt="image_url_primary_detail"
                                    />
                                    <div
                                        @click="handleRemoveImage"
                                        class="d-flex align-items-center icon-remove"
                                    >
                                        <img
                                            src="@/assets/images/icons/svg/delete-white.svg"
                                            alt="delete-icon"
                                        />
                                    </div>
                                </template>
                                <template v-else>
                                    <input
                                        style="display: none"
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        @change="
                                            handleAvatar(
                                                $event,
                                                'medium_detail'
                                            )
                                        "
                                    />
                                    <div>
                                        <img
                                            class="align-middle"
                                            width="50"
                                            src="@/assets/images/icons/svg/edit-image-gray.svg"
                                            alt="edit-image-gray"
                                        />
                                        <p
                                            class="align-middle"
                                            style="
                                                font-size: 12px;
                                                color: var(
                                                    --neutral-05-day,
                                                    #adadad
                                                );
                                            "
                                        >
                                            Upload image
                                        </p>
                                    </div>
                                </template>
                            </div>
                        </label>
                        <div class="hint">Recommend: file PNG</div>
                    </div>

                    <div class="input-name">
                        <label class="text-left"> Name Modal </label>
                        <input
                            v-model="topicForm.name.English"
                            @blur="
                                topicForm.name.English =
                                    topicForm.name.English.trim()
                            "
                            class="input-name-topic"
                            placeholder="Enter name topic..."
                        />
                    </div>

                    <div class="footer-actions">
                        <button
                            v-if="isStateModal === 'edit'"
                            class="button-delete"
                            :loading="loading.submitForm"
                            @click="handleOpenDeleteTopicConfirm(false)"
                        >
                            Delete
                        </button>
                        <ButtonSolid
                            :text="isStateModal === 'edit' ? 'Save' : 'Create'"
                            @click="handleCreateNewEditTopic"
                            :loading="loading.submitForm"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
./video-call-topic.js./video-call-topic.js
