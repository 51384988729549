<script type="text/javascript" src="./scenario-topic.ts"></script>
<style lang="scss" src="./scenario-topic.scss"></style>
<template>
    <ModalBase
        ref="modal-base-delete-scenario"
        title="Delete this scenario"
        btnAction="Yes"
        btnCancel="Cancel"
        description="Are you sure you want to delete this scenario?"
        :isDanger="true"
        @handleClickBtnAction="apiDeleteScenario"
        @handleClickCancel="onCloseDeleteConfirmModal"
    />

    <ModalBase
        ref="modal-base-alert-save-change"
        title="Exit this scenarios"
        btnAction="Yes"
        btnCancel="No"
        description="Do you want to exit and don't save changes?"
        :isDanger="true"
        @handleClickBtnAction="onCloseScenarioModal"
        @handleClickCancel="onCancelCloseScenarioModal"
    />

    <!-- @handleClickBtnAction="" -->

    <div class="modal" ref="scenario-topic-modal" tabindex="-1">
        <div
            v-show="
                !isShowConfirmDelete &&
                !isShowConfirmCloseModal &&
                !isShowTopicSelectionModal
            "
            class="modal-dialog modal-dialog-centered"
        >
            <div class="modal-content scenario">
                <!-- Header -->
                <div
                    class="d-flex flex-row justify-content-between w-100 header"
                >
                    <p style="color: #222; font-size: 18px; font-weight: 500">
                        {{ topicDataProps?.name }}
                    </p>
                    <button
                        id="close-modal-scenario"
                        type="button"
                        class="btn-close"
                        aria-label="Close"
                        @click="checkChange"
                    ></button>
                </div>

                <!-- Body -->
                <div class="content-box" :class="loading.all ? 'disabled' : ''">
                    <!-- Menu Scenario -->
                    <div class="menu-scenarios">
                        <div class="left">
                            <Splide
                                id="splide-scenarios"
                                v-if="scenariosTopicData.length > 0"
                                :has-track="false"
                                :options="{
                                    rewind: true,
                                    gap: '1rem',
                                    perPage: 6,
                                    arrows: true,
                                    pagination: false,
                                    autoWidth: true,
                                    padding: {
                                        left:
                                            scenariosTopicData.length > 6
                                                ? '1.5rem'
                                                : 0,
                                        right: '2.5rem',
                                    },
                                }"
                                aria-label=""
                            >
                                <div
                                    :style="{
                                        visibility:
                                            scenariosTopicData.length > 6
                                                ? 'visible'
                                                : 'hidden',
                                    }"
                                    class="splide__arrows"
                                >
                                    <button
                                        class="splide__arrow splide__arrow--prev"
                                    >
                                        <ArrowIcon />
                                    </button>
                                    <button
                                        class="splide__arrow splide__arrow--next"
                                    >
                                        <ArrowIcon />
                                    </button>
                                </div>

                                <SplideTrack>
                                    <SplideSlide
                                        v-for="(
                                            item, index
                                        ) in scenariosTopicData"
                                    >
                                        <button
                                            @click="
                                                handleChangeScenario(item.id)
                                            "
                                            class="menu-item"
                                            :class="
                                                scenarioSelected.id === item.id
                                                    ? 'active'
                                                    : ''
                                            "
                                        >
                                            Scenario {{ index + 1 }}
                                            <ExclamationMarkIcon
                                                v-if="!item.is_published"
                                            />
                                        </button>
                                    </SplideSlide>
                                </SplideTrack>
                            </Splide>
                        </div>
                        <div class="right">
                            <button
                                :disabled="
                                    loading.all ||
                                    loading.changeScenario ||
                                    loading.taskChange
                                "
                                @click="handleAddScenario"
                                class="button-outline"
                                style="min-width: 170px"
                            >
                                <AddUserIcon />
                                Add scenario
                            </button>
                        </div>
                    </div>

                    <!-- Content Scenario -->
                    <div class="content-scenario">
                        <!-- Loading scenario -->
                        <template
                            v-if="
                                loading.save ||
                                loading.changeScenario ||
                                loading.taskChange
                            "
                        >
                            <Spinner type="overlay" />
                        </template>

                        <!-- Scenario have data -->
                        <template
                            v-else-if="
                                scenariosTopicData.length > 0 &&
                                scenarioSelected?.content
                            "
                        >
                            <!-- General info Scenario -->
                            <AccordingPanel
                                idTarget="general-info"
                                className="accordion-general-info scenario small-button"
                            >
                                <template #title>
                                    <div class="text-title">
                                        <span>General Info: </span>
                                        <span class="name-scenario">
                                            {{
                                                scenarioSelected.content[
                                                    "English"
                                                ].name
                                            }}
                                        </span>
                                    </div>
                                </template>
                                <template #content>
                                    <div class="general-info-container">
                                        <div class="gn-info-top">
                                            <div class="col-left">
                                                <div
                                                    class="text-start d-flex flex-column input-control"
                                                >
                                                    <label class="input-label">
                                                        Roleplay type
                                                    </label>
                                                    <DropdownSelect
                                                        :key="
                                                            scenarioSelected.id
                                                        "
                                                        placeholder="Choose a type"
                                                        :defaultSelected="
                                                            scenarioSelected.type
                                                        "
                                                        :options="
                                                            selectOptions.video_call_feature
                                                        "
                                                        @selectOption="
                                                            selectOptionVideoCallFeature
                                                        "
                                                    />
                                                </div>
                                                <div
                                                    v-if="
                                                        scenarioSelected.type ===
                                                        'learn_language'
                                                    "
                                                    class="text-start d-flex flex-column input-control"
                                                    style="flex: 1"
                                                >
                                                    <label class="input-label">
                                                        Level
                                                    </label>
                                                    <DropdownSelect
                                                        placeholder="Choose level for scenario"
                                                        :defaultSelected="
                                                            scenarioSelected.level
                                                        "
                                                        :options="
                                                            selectOptions.levels
                                                        "
                                                        @selectOption="
                                                            selectOptionLevel
                                                        "
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-right">
                                                <!-- Avatar -->
                                                <div
                                                    class="scenario-avatars"
                                                    style="margin-top: 8px"
                                                >
                                                    <div
                                                        v-for="(v, k) in [
                                                            'image_url',
                                                            'gif_url',
                                                        ]"
                                                        :key="k"
                                                        class="avatar-control"
                                                    >
                                                        <label
                                                            style="
                                                                cursor: pointer;
                                                            "
                                                        >
                                                            <template
                                                                v-if="
                                                                    scenarioSelected[
                                                                        v
                                                                    ]
                                                                "
                                                            >
                                                                <img
                                                                    class="img-avatar"
                                                                    :src="
                                                                        scenarioSelected[
                                                                            v
                                                                        ]
                                                                    "
                                                                    alt="image avatar"
                                                                />
                                                                <div
                                                                    @click="
                                                                        handleRemoveImage(
                                                                            v
                                                                        )
                                                                    "
                                                                    class="icon-remove"
                                                                >
                                                                    <img
                                                                        src="@/assets/images/icons/svg/delete-white.svg"
                                                                        alt="delete-icon"
                                                                    />
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                <input
                                                                    style="
                                                                        display: none;
                                                                    "
                                                                    type="file"
                                                                    :accept="`.png, .jpg, .jpeg, ${
                                                                        v ===
                                                                        'gif_url'
                                                                            ? '.gif'
                                                                            : ''
                                                                    }`"
                                                                    @change="
                                                                        handleAvatar(
                                                                            $event,
                                                                            'medium_detail',
                                                                            v
                                                                        )
                                                                    "
                                                                />
                                                                <div>
                                                                    <img
                                                                        class="align-middle"
                                                                        width="35"
                                                                        src="@/assets/images/icons/svg/edit-image-gray.svg"
                                                                        alt="edit-image-gray"
                                                                    />
                                                                    <p
                                                                        class="align-middle"
                                                                        style="
                                                                            font-size: 12px;
                                                                            color: var(
                                                                                --neutral-05-day,
                                                                                #adadad
                                                                            );
                                                                        "
                                                                    >
                                                                        Thumbnail
                                                                        {{
                                                                            v ===
                                                                            "image_url"
                                                                                ? "image"
                                                                                : "gif"
                                                                        }}
                                                                    </p>
                                                                </div>
                                                            </template>
                                                        </label>
                                                        <div class="hint">
                                                            <span
                                                                class="normal"
                                                            >
                                                                Thumbnail
                                                                {{
                                                                    v ===
                                                                    "image_url"
                                                                        ? "image"
                                                                        : "gif"
                                                                }}
                                                            </span>
                                                            <p
                                                                class="text-alert"
                                                            >
                                                                {{
                                                                    v ===
                                                                    "image_url"
                                                                        ? "Recommend: 500 x 500px, Max: 500KB"
                                                                        : "Recommend: 500 x 500px, Max: 10Mb"
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Scenario Prompt Field -->
                                        <!-- <AccordingPanel
                                            idTarget="general-info-prompt"
                                            className="accordion-general-info-prompt small-button"
                                            :isToggleHeader="false"
                                        >
                                            <template #title>
                                                <div
                                                    class="general-info-header"
                                                >
                                                    <span class="text-title"
                                                        >Prompt</span
                                                    >
                                                </div>
                                            </template>
                                            <template #content>
                                                <FLInput
                                                    require
                                                    label="Prompt"
                                                    :hint="`${
                                                        scenarioSelected.prompt
                                                            ?.length || 0
                                                    }/3000 characters`"
                                                >
                                                    <textarea
                                                        data-cy="prompt"
                                                        v-model="
                                                            scenarioSelected.prompt
                                                        "
                                                        :maxlength="3000"
                                                        :rows="4"
                                                        @change="onChangeInput"
                                                    />
                                                </FLInput>
                                                <PreviewOutComePrompt
                                                    :prompt="
                                                        scenarioSelected.prompt
                                                    "
                                                />
                                            </template>
                                        </AccordingPanel> -->
                                    </div>
                                </template>
                            </AccordingPanel>

                            <!-- Scenario content -->
                            <AccordingPanel
                                idTarget="scenario-content"
                                className="accordion-scenario-content scenario small-button"
                            >
                                <template #title>
                                    <span class="text-title"
                                        >Scenario content</span
                                    >
                                </template>
                                <template #content>
                                    <!-- Scenario Menu Language -->
                                    <MenuLanguagesSelection
                                        :menuSelections="
                                            menuSelections.languages
                                        "
                                        :loading="loading.translate"
                                        :languageSelected="languageSelected"
                                        :checkList="checkListLanguage"
                                        @handleTranslate="handleTranslate"
                                        @handleSelectLanguage="
                                            handleSelectLanguage
                                        "
                                    />

                                    <!-- Context info fields -->
                                    <AccordingPanel
                                        idTarget="context-info"
                                        className="accordion-context-info scenario small-button"
                                        :isToggleHeader="false"
                                    >
                                        <template #title>
                                            <div
                                                class="scenario-accordion-header"
                                            >
                                                <span class="text-title"
                                                    >Context Info</span
                                                >
                                                <button
                                                    @click.stop="
                                                        handleAutoGenerateContextInfo
                                                    "
                                                    :class="`
                                ${
                                    languageSelected !== 'English'
                                        ? 'disabled-item'
                                        : ''
                                }
                                ${
                                    loading.autoGenerateContext
                                        ? 'disabled-item'
                                        : ''
                                }`"
                                                    class="button-solid border-radius-12"
                                                    style="min-width: 170px"
                                                >
                                                    <span
                                                        v-if="
                                                            loading.autoGenerateContext
                                                        "
                                                        class="me-2 spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    <AutoIcon v-else />

                                                    Auto generate
                                                </button>
                                            </div>
                                        </template>
                                        <template #content>
                                            <AccordingPanel
                                                idTarget="general-info-prompt"
                                                className="accordion-general-info-prompt scenario small-button"
                                                :isToggleHeader="false"
                                            >
                                                <template #title>
                                                    <div
                                                        class="general-info-header"
                                                    >
                                                        <span class="text-title"
                                                            >Prompt</span
                                                        >
                                                    </div>
                                                </template>
                                                <template #content>
                                                    <FLInput
                                                        require
                                                        label="Prompt"
                                                        :hint="`${
                                                            scenarioSelected
                                                                .prompt
                                                                ?.length || 0
                                                        }/3000 characters`"
                                                    >
                                                        <textarea
                                                            data-cy="prompt"
                                                            v-model="
                                                                scenarioSelected.prompt
                                                            "
                                                            :maxlength="3000"
                                                            :rows="4"
                                                            @change="
                                                                onChangeInput
                                                            "
                                                        />
                                                    </FLInput>
                                                    <PreviewOutComePrompt
                                                        :prompt="
                                                            scenarioSelected.prompt
                                                        "
                                                    />
                                                </template>
                                            </AccordingPanel>
                                            <div
                                                style="
                                                    display: flex;
                                                    width: 100%;
                                                    flex-direction: column;
                                                    gap: 15px;
                                                "
                                            >
                                                <!-- Scenario Name Field -->
                                                <FLInput
                                                    require
                                                    label="Scenario name"
                                                    :hint="`${
                                                        scenarioSelected
                                                            .content[
                                                            languageSelected
                                                        ].name?.length || 0
                                                    }/100 characters`"
                                                >
                                                    <input
                                                        data-cy="name"
                                                        v-model="
                                                            scenarioSelected
                                                                .content[
                                                                languageSelected
                                                            ].name
                                                        "
                                                        :maxlength="100"
                                                        @change="onChangeInput"
                                                    />
                                                </FLInput>

                                                <div
                                                    style="
                                                        display: flex;
                                                        flex-direction: row;
                                                        flex: 1;
                                                        width: 100%;
                                                        gap: 20px;
                                                    "
                                                >
                                                    <!-- Scenario User Role Field -->

                                                    <FLInput
                                                        require
                                                        label="User Role"
                                                        :hint="`${
                                                            scenarioSelected
                                                                .content[
                                                                languageSelected
                                                            ].user_role
                                                                ?.length || 0
                                                        }/40 characters`"
                                                    >
                                                        <input
                                                            data-cy="user_role"
                                                            v-model="
                                                                scenarioSelected
                                                                    .content[
                                                                    languageSelected
                                                                ].user_role
                                                            "
                                                            :maxlength="40"
                                                            @change="
                                                                onChangeInput
                                                            "
                                                        />
                                                    </FLInput>

                                                    <!-- Scenario AI Role Field -->
                                                    <FLInput
                                                        require
                                                        label="AI Role"
                                                        :hint="`${
                                                            scenarioSelected
                                                                .content[
                                                                languageSelected
                                                            ].ai_role?.length ||
                                                            0
                                                        }/40 characters`"
                                                    >
                                                        <input
                                                            data-cy="ai_role"
                                                            v-model="
                                                                scenarioSelected
                                                                    .content[
                                                                    languageSelected
                                                                ].ai_role
                                                            "
                                                            :maxlength="40"
                                                            @change="
                                                                onChangeInput
                                                            "
                                                        />
                                                    </FLInput>
                                                </div>

                                                <!-- Scenario Context Field -->
                                                <FLInput
                                                    require
                                                    label="Context"
                                                    :hint="`${
                                                        scenarioSelected
                                                            .content[
                                                            languageSelected
                                                        ].context?.length || 0
                                                    }/3000 characters`"
                                                >
                                                    <textarea
                                                        data-cy="context"
                                                        v-model="
                                                            scenarioSelected
                                                                .content[
                                                                languageSelected
                                                            ].context
                                                        "
                                                        :rows="4"
                                                        :maxlength="3000"
                                                        @change="onChangeInput"
                                                    ></textarea>
                                                </FLInput>
                                            </div>
                                        </template>
                                    </AccordingPanel>

                                    <!-- Scenario Task Roleplay for Learning Language -->
                                    <AccordingPanel
                                        v-if="
                                            scenarioSelected.type ===
                                            'learn_language'
                                        "
                                        idTarget="tasks"
                                        className="accordion-tasks scenario"
                                        :isToggleHeader="false"
                                    >
                                        <template #title>
                                            <div
                                                class="scenario-accordion-header"
                                            >
                                                <span class="text-title"
                                                    >Tasks (Roleplay for
                                                    learning language)</span
                                                >
                                                <button
                                                    @click.stop="
                                                        handleAutoGenerate
                                                    "
                                                    :class="`
                                ${
                                    languageSelected !== 'English'
                                        ? 'disabled-item'
                                        : ''
                                }
                                ${loading.translate ? 'disabled-item' : ''}`"
                                                    class="button-solid border-radius-12"
                                                    style="min-width: 170px"
                                                >
                                                    <span
                                                        v-if="loading.translate"
                                                        class="me-2 spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    <AutoIcon v-else />

                                                    Auto generate
                                                </button>
                                            </div>
                                        </template>
                                        <template #content>
                                            <template
                                                v-for="(
                                                    task, index
                                                ) in scenarioSelected.content[
                                                    languageSelected
                                                ].tasks"
                                                :key="
                                                    index.toString() +
                                                    languageSelected
                                                "
                                            >
                                                <div
                                                    class="task-group fl-input-control"
                                                >
                                                    <label class="input-label">
                                                        <i class="icon-require"
                                                            >* </i
                                                        >{{ task.label }}</label
                                                    >

                                                    <!-- Render Task Field -->
                                                    <template
                                                        v-for="(
                                                            _task, i
                                                        ) in task.subtasks"
                                                        :key="
                                                            i.toString() +
                                                            'subtasks'
                                                        "
                                                    >
                                                        <FLInput
                                                            require
                                                            :hint="`${
                                                                _task.title
                                                                    ?.length ||
                                                                0
                                                            }/60 characters`"
                                                        >
                                                            <template #icon>
                                                                <!-- <TaskIcon /> -->
                                                                <span
                                                                    class="icon-task"
                                                                    :class="[
                                                                        _task
                                                                            .title
                                                                            ?.length
                                                                            ? 'active'
                                                                            : '',
                                                                    ]"
                                                                >
                                                                    {{ i + 1 }}
                                                                </span>
                                                            </template>
                                                            <input
                                                                :data-cy="`task-${task.id}-${i}`"
                                                                v-model="
                                                                    _task.title
                                                                "
                                                                :maxlength="60"
                                                                placeholder="Enter a task"
                                                                @change="
                                                                    onChangeInput
                                                                "
                                                            />
                                                        </FLInput>
                                                    </template>
                                                </div>
                                            </template>
                                        </template>
                                    </AccordingPanel>
                                </template>
                            </AccordingPanel>

                            <!-- Vocabulary -->
                            <Vocabulary
                                v-if="
                                    scenarioSelected.type === 'learn_language'
                                "
                                @on-save-vocabulary="onChangeVocabulary"
                                :enabled="
                                    scenarioSelected.id &&
                                    typeof scenarioSelected.id === 'number'
                                "
                                :scenarioData="
                                    scenarioSelected?.content['English']
                                        ?.ai_role && {
                                        id: scenarioSelected.id,
                                        ai_role:
                                            scenarioSelected.content['English']
                                                .ai_role,
                                        context:
                                            scenarioSelected.content['English']
                                                .context,
                                        user_role:
                                            scenarioSelected.content['English']
                                                .ai_role,
                                        level: scenarioSelected.level,
                                    }
                                "
                            />
                        </template>

                        <!-- Scenario No data -->
                        <template
                            v-else-if="!loading.changeScenario && !loading.all"
                        >
                            <NoData
                                textBtn="Add scenario"
                                @click="handleAddScenario"
                            />
                        </template>
                    </div>
                </div>

                <!-- Footer -->
                <div class="action-controls" style="gap: 16px">
                    <div class="left">
                        <button
                            v-if="typeof scenarioSelected.id === 'number'"
                            class="button-delete"
                            :disabled="loading.all"
                            :loading="loading.deleteScenario"
                            @click="handleOpenDeleteScenarioConfirm"
                        >
                            Remove
                        </button>
                    </div>
                    <div class="right">
                        <button
                            v-if="typeof scenarioSelected.id === 'number'"
                            class="button-default"
                            :disabled="loading.all"
                            @click="handleOpenModalTopicSelection"
                        >
                            Move topic
                        </button>

                        <button
                            :disabled="
                                loading.taskChange ||
                                loading.translate ||
                                loading.saveScenario ||
                                loading.all ||
                                !scenarioSelected?.prompt
                            "
                            class="button-outline"
                            @click="handleSaveDraftScenario"
                        >
                            Save Draft
                        </button>
                        <button
                            :disabled="
                                loading.taskChange ||
                                loading.translate ||
                                loading.saveScenario ||
                                loading.all ||
                                !scenarioSelected?.prompt
                            "
                            class="button-solid"
                            @click="handleSaveScenario"
                        >
                            Publish
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FJModal
        v-if="typeof scenarioSelected.id === 'number'"
        ref="modal-topics-selection"
        class="modal-topic-selection"
    >
        <template #body>
            <Wrapper>
                <TopicSelect
                    :listTopic="listTopic"
                    :scenarioName="
                        scenarioSelected?.content?.English.name || ''
                    "
                    @on-save="handleSaveChangeTopicForScenario"
                    @on-close="handleCloseTopicForScenario"
                />
            </Wrapper>
        </template>
    </FJModal>
</template>
