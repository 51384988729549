import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { Amplify } from "aws-amplify";
import MainLayout from "@/views/main-layout/main-layout.vue";
import LoginView from "@/views/login/login.vue";
import { ROUTE_NAMES, ROUTE_PATHS } from "@/constants/routeNames";
import store from "@/store";
import { TransformedMutationTypesTable } from "@/store/modules/table/mutation-style";
import Dashboard from "@/views/dashboard/dashboard.vue";
import CreateAiCharacter from "@/components/layout/create-ai-character/create-ai-character.vue";
import ViewDetail from "@/components/layout/learner-report/learner-report.vue"
import ActionTypes from "@/store/action-types";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: MainLayout,
        meta: {
            requiresAuth: true,
        },

        // redirect: { name: "dashboard" },
        children: [
            {
                path: ROUTE_NAMES.DASHBOARD,
                name: ROUTE_NAMES.DASHBOARD,
                component: Dashboard,
            },
            {
                path: ROUTE_NAMES.ADMINS,
                name: ROUTE_NAMES.ADMINS,
                component: () => import("@/views/admins/admins.vue"),
            },
            {
                path: ROUTE_NAMES.ADVISOR_DASHBOARD,
                name: ROUTE_NAMES.ADVISOR_DASHBOARD,
                component: ()=>import("@/views/advisor-dashboard/index.vue"),
                meta: { roles: ["advisor"] }
            },
            {
                path: "/parent-information",
                name: ROUTE_NAMES.PARENT_INFORMATION,
                component: ViewDetail,
            },
            {
                path: ROUTE_NAMES.ADVISORS,
                name: ROUTE_NAMES.ADVISORS,
                component: ()=> import("@/views/advisors/index.vue"),
            },
            {
                path: ROUTE_NAMES.USERS,
                name: ROUTE_NAMES.USERS,
                // component: () => import('@/views/users/paid-users/paid-users.vue'),
                children: [
                    {
                        path: "paid-users",
                        name: ROUTE_NAMES.PAID_USERS,
                        component: () => import("@/views/users/users.vue"),
                        beforeEnter: async () => {
                            await store.commit(
                                TransformedMutationTypesTable.RESET_STATE
                            );
                            await store.dispatch(
                                TransformedMutationTypesTable.QUERY_TABLE_DATA,
                                { is_free: false }
                            );
                        },
                    },
                    {
                        path: "free-users",
                        name: ROUTE_NAMES.FREE_USERS,
                        component: () => import("@/views/users/users.vue"),
                        beforeEnter: async () => {
                            await store.commit(
                                TransformedMutationTypesTable.RESET_STATE
                            );
                            await store.dispatch(
                                TransformedMutationTypesTable.QUERY_TABLE_DATA,
                                { is_free: true }
                            );
                        },
                    },
                ],
            },

            {
                path: ROUTE_NAMES.SUBSCRIPTION,
                name: ROUTE_NAMES.SUBSCRIPTION,
                component: () =>
                    import("@/views/subscription/subscription.vue"),
            },
            {
                path: ROUTE_NAMES.PLUGIN,
                name: ROUTE_NAMES.PLUGIN,
                component: () => import("@/views/plugin/plugin.vue"),
            },
            {
                path: ROUTE_NAMES.AI_CHARACTER,
                name: ROUTE_NAMES.AI_CHARACTER,
                component: () =>
                    import("@/views/ai-character/ai-character.vue"),
            },
            {
                path: 'ai-character/add',
                name: ROUTE_NAMES.CREATE_CHARACTER,
                component: CreateAiCharacter,
            },
            {
                path: ROUTE_NAMES.SCENARIOS,
                name: ROUTE_NAMES.SCENARIOS,
                component: () => import("@/views/scenarios/scenarios.vue"),
            },
            {
                path: 'scenarios/add',
                name: ROUTE_NAMES.CREATE_SCENARIOS,
                component: () =>  import("@/components/layout/create-edit-scenario/scenario.vue"),
            },
            {
                path: ROUTE_NAMES.ROLEPLAY_PROMPT,
                name: ROUTE_NAMES.ROLEPLAY_PROMPT,
                component: () =>
                    import("@/views/roleplay-prompt/roleplay-prompt.vue"),
            },
            {
                path: ROUTE_NAMES.MISSION,
                name: ROUTE_NAMES.MISSION,
                component: () => import("@/views/mission/index.vue"),
            },
            {
                path: ROUTE_NAMES.BLOG,
                name: ROUTE_NAMES.BLOG,
                component: () => import("@/views/blog/blog.vue"),
            },
            {
                path: ROUTE_NAMES.REPORT,
                name: ROUTE_NAMES.REPORT,
                component: () => import("@/views/report/report.vue"),
            },
            {
                path: ROUTE_NAMES.PROFILE,
                name: ROUTE_NAMES.PROFILE,
                component: () => import("@/views/profile/profile.vue"),
            },
            {
                path: "/test",
                name: "test",
                component: () => import("@/views/test/Test.vue"),
            },
        ],
    },
    {
        path: ROUTE_PATHS.LOGIN,
        name: ROUTE_NAMES.LOGIN,
        component: LoginView,
    },
    {
        path: ROUTE_PATHS.CHANGE_PASSWORD,
        name: ROUTE_NAMES.CHANGE_PASSWORD,
        component: () => import("@/views/change-password/change-password.vue"),
    },

    // Catch-all route
    {
        path: "/:pathMatch(.*)*", // This is a wildcard route
        redirect: "/", // Redirect to the home page
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    let res;
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        try {
            await Amplify.Auth.currentAuthenticatedUser();
            let userData = store.getters.GET_USER_DATA;
            if (!userData) {
                res = await store.dispatch(ActionTypes.GET_USER_DATA);
                userData = res.data;
            }

            const userRole = store.getters.GET_USER_ROLE;
            const requiredFields = ['description', 'display_name', 'email', 'nationality', 'phone'];
            const hasEmptyField = requiredFields.some(field => !userData[field]);

            if (to.path === ROUTE_PATHS.ADVISOR_DASHBOARD && hasEmptyField) {
                return next(ROUTE_NAMES.PROFILE);
            } else if (to.path === "/") {
                const advisorRoute = to.matched[0].children.find((child) => {
                    return (child.meta?.roles as string[]).some((role: string) =>
                        userRole.includes(role),
                    );
                });
                if (advisorRoute) {
                    return hasEmptyField ? next(ROUTE_NAMES.PROFILE) : next(ROUTE_NAMES.ADVISOR_DASHBOARD);
                } else {
                    next(ROUTE_NAMES.DASHBOARD);
                }
            } else {
                next();
            }
        } catch (error) {
            next(ROUTE_PATHS.LOGIN)
        }
    } else {
        next();
    }
});


export default router;
