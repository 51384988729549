<template>
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.8081 22.3191C12.8581 22.544 12.9061 22.7612 12.9525 22.9713C13.2202 24.1834 13.4349 25.1558 13.6789 25.9617C13.9637 26.9024 14.2609 27.515 14.6454 27.9431C15.3823 28.7635 16.6802 29.1667 19.9998 29.1667C23.3195 29.1667 24.6174 28.7635 25.3542 27.9431C25.7388 27.515 26.036 26.9024 26.3208 25.9617C26.5648 25.1558 26.7795 24.1834 27.0472 22.9713C27.0936 22.7613 27.1416 22.544 27.1916 22.3191C27.8397 19.4041 27.412 17.9526 26.44 17.139C25.9138 16.6986 25.1432 16.3642 24.0549 16.1451C22.9688 15.9263 21.6234 15.8333 19.9998 15.8333C18.3763 15.8333 17.0308 15.9263 15.9448 16.1451C14.8565 16.3642 14.0858 16.6986 13.5597 17.139C12.5877 17.9526 12.1599 19.4041 12.8081 22.3191Z"
            stroke="currentColor"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M19.9998 20V25M16.6665 20V25M23.3332 20V25"
            stroke="currentColor"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.6666 11.7255C24.7114 11.946 26.7569 12.7468 27.9297 13.4527C28.324 13.69 28.4513 14.2021 28.2139 14.5964C27.9766 14.9907 27.4646 15.118 27.0702 14.8807C25.9839 14.2269 23.7128 13.3333 19.9999 13.3333C16.2747 13.3333 13.9405 14.2322 12.9501 14.8679C12.5628 15.1166 12.0473 15.0042 11.7987 14.6169C11.55 14.2296 11.6624 13.714 12.0497 13.4654C13.1733 12.7441 15.2775 11.9448 18.3333 11.7252V10.8333C18.3333 10.3731 18.7064 10 19.1666 10H20.8333C21.2935 10 21.6666 10.3731 21.6666 10.8333V11.7255Z"
            fill="currentColor"
        />
    </svg>
</template>
