<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.41663 5.48622C2.41663 6.25543 2.45882 6.82237 2.55324 7.24722C2.64577 7.66357 2.77819 7.8961 2.92719 8.0451C3.07619 8.1941 3.30872 8.32652 3.72507 8.41905C4.14992 8.51347 4.71686 8.55566 5.48607 8.55566C6.25528 8.55566 6.82222 8.51347 7.24708 8.41905C7.66342 8.32652 7.89595 8.1941 8.04495 8.0451C8.19396 7.8961 8.32637 7.66357 8.4189 7.24722C8.51332 6.82237 8.55552 6.25543 8.55552 5.48622C8.55552 4.71701 8.51332 4.15007 8.4189 3.72521C8.32637 3.30887 8.19396 3.07634 8.04495 2.92734C7.89595 2.77833 7.66342 2.64592 7.24708 2.55339C6.82222 2.45897 6.25528 2.41677 5.48607 2.41677C4.71686 2.41677 4.14992 2.45897 3.72507 2.55339C3.30872 2.64592 3.07619 2.77833 2.92719 2.92734C2.77819 3.07634 2.64577 3.30887 2.55324 3.72521C2.45882 4.15007 2.41663 4.71701 2.41663 5.48622Z"
      stroke="#666666"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.49996 14.5141C2.49996 15.2814 2.54216 15.8411 2.63459 16.257C2.72492 16.6634 2.85176 16.8797 2.98611 17.014C3.12046 17.1484 3.33671 17.2752 3.74314 17.3655C4.15904 17.458 4.71876 17.5002 5.48607 17.5002C6.25338 17.5002 6.8131 17.458 7.229 17.3655C7.63543 17.2752 7.85168 17.1484 7.98603 17.014C8.12038 16.8797 8.24722 16.6634 8.33755 16.257C8.42998 15.8411 8.47218 15.2814 8.47218 14.5141C8.47218 13.7467 8.42998 13.187 8.33755 12.7711C8.24722 12.3647 8.12038 12.1484 7.98603 12.0141C7.85168 11.8797 7.63543 11.7529 7.229 11.6626C6.8131 11.5701 6.25338 11.5279 5.48607 11.5279C4.71876 11.5279 4.15904 11.5701 3.74314 11.6626C3.33671 11.7529 3.12046 11.8797 2.98611 12.0141C2.85176 12.1484 2.72492 12.3647 2.63459 12.7711C2.54216 13.187 2.49996 13.7467 2.49996 14.5141Z"
      stroke="#666666"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.5278 5.48622C11.5278 6.25353 11.57 6.81325 11.6624 7.22915C11.7527 7.63558 11.8796 7.85183 12.0139 7.98618C12.1483 8.12053 12.3645 8.24737 12.771 8.3377C13.1869 8.43013 13.7466 8.47233 14.5139 8.47233C15.2812 8.47233 15.8409 8.43013 16.2568 8.3377C16.6633 8.24737 16.8795 8.12053 17.0139 7.98618C17.1482 7.85183 17.2751 7.63558 17.3654 7.22915C17.4578 6.81325 17.5 6.25353 17.5 5.48622C17.5 4.71891 17.4578 4.15919 17.3654 3.74329C17.2751 3.33686 17.1482 3.12061 17.0139 2.98626C16.8795 2.85191 16.6633 2.72507 16.2568 2.63474C15.8409 2.54231 15.2812 2.50011 14.5139 2.50011C13.7466 2.50011 13.1869 2.54231 12.771 2.63474C12.3645 2.72507 12.1483 2.85191 12.0139 2.98626C11.8796 3.12061 11.7527 3.33686 11.6624 3.74329C11.57 4.15919 11.5278 4.71891 11.5278 5.48622Z"
      stroke="#666666"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.5278 14.5141C11.5278 15.2814 11.57 15.8411 11.6624 16.257C11.7527 16.6634 11.8796 16.8797 12.0139 17.014C12.1483 17.1484 12.3645 17.2752 12.771 17.3655C13.1869 17.458 13.7466 17.5002 14.5139 17.5002C15.2812 17.5002 15.8409 17.458 16.2568 17.3655C16.6633 17.2752 16.8795 17.1484 17.0139 17.014C17.1482 16.8797 17.2751 16.6634 17.3654 16.257C17.4578 15.8411 17.5 15.2814 17.5 14.5141C17.5 13.7467 17.4578 13.187 17.3654 12.7711C17.2751 12.3647 17.1482 12.1484 17.0139 12.0141C16.8795 11.8797 16.6633 11.7529 16.2568 11.6626C15.8409 11.5701 15.2812 11.5279 14.5139 11.5279C13.7466 11.5279 13.1869 11.5701 12.771 11.6626C12.3645 11.7529 12.1483 11.8797 12.0139 12.0141C11.8796 12.1484 11.7527 12.3647 11.6624 12.7711C11.57 13.187 11.5278 13.7467 11.5278 14.5141Z"
      stroke="#666666"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
