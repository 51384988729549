import ButtonSolid from "@/components/base/buttons/primary/solid.vue";
import ModalBase from "@/components/base/modal-base/modal-base.vue";
import PersonAisConstants from "@/constants/person-ai";
import { ITopic, ITopicInList } from "@/interfaces/person-ai";
import { TransformedActionTypesPersonAi } from "@/store/modules/person-ai/action-style";
import { TransformedMutationTypesPersonAi } from "@/store/modules/person-ai/mutation-style";
import { Modal } from "bootstrap";
import { toRaw } from "vue";
import { Options, Vue } from "vue-class-component";

@Options({
    components: {
        ModalBase,
        ButtonSolid,
    },
    props: {
        // topicsDataProps: Object,
        // personAiEditId: Number,
    },
    // watch: {
    //     topicsDataProps: {
    //         handler(newVal, oldVal) {
    //             this.topicsData = newVal;
    //             // console.log("🚀 ~ handler ~ this.topicsData:", this.topicsData);
    //         },
    //         deep: true,
    //     },
    // },
})
export default class VideoCallTopic extends Vue {
    // public topicsDataProps!: any;
    public isStateModal: "list" | "create" | "edit" = "list";
    public topicForm: ITopic = new PersonAisConstants().dataInitTopic;
    // public personAiEditId!: number;
    public topicsData: ITopicInList[] = [];
    public topicsDataSelected: any = [];
    public payloadAvatar: any = null;
    public isMultipleDelete: boolean = false;
    public loading: any = {
        submitForm: false,
    };

    public beforeMount() {
        console.log("-------->");
        this.apiGetTopicList();
    }
    public isShowPopupConfirm: boolean = false;

    private resetStateCop() {
        this.isStateModal = "list";
        this.topicForm = new PersonAisConstants().dataInitTopic;
        this.topicsDataSelected = [];
        this.isMultipleDelete = false;
    }

    public apiUpdateAvatar(type: string) {
        this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.UPLOAD_AVATAR_V2,
            this.payloadAvatar,
            (data: any) => {
                this.topicForm.image_url = data.image_url;
            }
        );
    }

    public updateTopicDataDelete(dataReturn: any) {
        this.topicsData.forEach((item: any, idx: number) => {
            for (let v of dataReturn.statuses) {
                if (item.id !== v.id && v.status === "success") {
                    this.topicsData.splice(idx, 1);
                }
            }
        });
    }

    private updateTopicsData(
        action: "Create" | "Update" | "Delete",
        payload: any
    ) {
        if (this.topicsData.length > 0)
            switch (action) {
                case "Delete":
                    this.topicsData.forEach((item: any, idx: number) => {
                        if (item.id === payload.id) {
                            this.topicsData.splice(idx, 1);
                        }
                    });
                    break;

                case "Update":
                    const index = this.topicsData.findIndex(
                        (item: any) => item.id === this.topicForm.id
                    );
                    if (index !== -1) {
                        const data = {
                            name: this.topicForm.name.English,
                            image_url: this.topicForm.image_url,
                            topic_type: this.topicForm.topic_type,
                            id: this.topicForm.id,
                        };
                        this.topicsData[index] = data;
                        this.topicsData.forEach((item: any, idx: number) => {
                            if (item.id === this.topicForm.id) {
                                this.topicsData[idx] = data;
                            }
                        });
                    }
                    break;

                case "Create":
                    const data = {
                        ...payload.data,
                        name: payload.data.name["English"],
                        scenarios: [],
                        isChecked: false,
                    };
                    // this.topicsData.push(data);
                    this.topicsData.push(data);
                    break;
            }
    }

    public async apiTranslateName(callBack: (names: any) => void) {
        return this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.AUTO_TRANSLATE_TEXT_V2,
            {
                content: {
                    name: this.topicForm.name.English,
                },
                non_english_raises: false,
            },
            (data: any) => {
                let names: any = {};
                if (data) {
                    // this.topicForm.name = data.name;
                    for (let t in data) {
                        names[t] = data[t]["name"];
                    }
                }

                callBack(names);
            },
            () => {
                this.loading.submitForm = false;
            }
        );
    }

    private async actionDone(data: any, type: "Create" | "Update" | "Delete") {
        this.$toast.success(`${type} topic success!`);
        this.isStateModal = "list";
        this.updateTopicsData(type, data);
        this.topicForm = new PersonAisConstants().dataInitTopic;
        // await this.$store.commit(
        //     TransformedMutationTypesPersonAi.SET_EDIT_TOPIC_VIDEO_CALL,
        //     true
        // );
    }
    private async apiRequestTopic(
        type: "Create" | "Update" | "Delete",
        payload: any
    ) {
        let action = TransformedActionTypesPersonAi.CREATE_VIDEO_CALL_TOPICS_V2;
        switch (type) {
            case "Update":
                action =
                    TransformedActionTypesPersonAi.UPDATE_VIDEO_CALL_TOPICS_V2;
                break;

            case "Delete":
                action =
                    TransformedActionTypesPersonAi.DELETE_VIDEO_CALL_TOPIC_V2;
                break;
        }
        this.loading.submitForm = true;

        if (type === "Delete") {
            await this.$apiAction.handleApiRequest(
                action,
                { topic_id: this.topicForm.id },
                (data: any) => {
                    this.actionDone(data, type);
                    this.loading.submitForm = false;
                },
                () => {
                    this.loading.submitForm = false;
                }
            );
            return;
        }
        this.apiTranslateName(async (names: any) => {
            this.topicForm.name = names;
            await this.$apiAction.handleApiRequest(
                action,
                this.topicForm,
                (data: any) => {
                    if (data) {
                        this.actionDone(data, type);
                    } else {
                        this.$toast.error(`${type} topic fail!`);
                    }
                    this.loading.submitForm = false;
                },
                () => {
                    this.loading.submitForm = false;
                }
            );
        });
    }

    private async apiRequestDeleteMultipleTopic(payload: any) {
        await this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.DELETE_VIDEO_CALL_TOPICS_V2,
            payload,
            async (data: any) => {
                if (data.success_count === 0) {
                    this.$toast.error(`Delete topics failed!`);
                } else {
                    this.$toast.success(
                        `Delete ${data.success_count}/${this.topicsDataSelected.length} topics success!`
                    );
                    this.isStateModal = "list";
                    for (let i = 0; i < this.topicsData.length; i++) {
                        for (let v of data.statuses) {
                            if (
                                this.topicsData[i].id === v.id &&
                                v.status === "success"
                            ) {
                                // Update value of props with reference type.
                                this.topicsData.splice(i, 1);
                                i--;
                            }
                        }
                    }
                    this.resetStateCop();
                }
                this.loading.submitForm = false;
            },
            () => {
                this.loading.submitForm = false;
            }
        );
    }

    public onDeleteTopic() {
        this.isShowPopupConfirm = false;

        if (this.isMultipleDelete) {
            this.apiRequestDeleteMultipleTopic({
                topic_ids: this.topicsDataSelected,
            });
        } else this.apiRequestTopic("Delete", {});
    }

    public handleRemoveImage() {
        this.topicForm.image_url = "";
    }

    public handleAddNewTopic() {
        this.isStateModal = "create";
        this.topicForm = new PersonAisConstants().dataInitTopic;
    }

    public handleCancelModal() {}

    public openModal() {
        const myModal: any = new Modal(
            this.$refs["modal-topic-video-call"] as any
        );
        myModal.show();
        myModal._element.addEventListener("hidden.bs.modal", () => {
            this.resetStateCop();
        });
    }

    public handleAvatar(event: any, type: string) {
        this.payloadAvatar = new FormData();
        const selectedFiles = (event.target as HTMLInputElement).files;
        if (selectedFiles) {
            const file = selectedFiles[0];
            if (
                file.type === "image/png" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg"
            ) {
                this.payloadAvatar.set(`image`, file);
                this.payloadAvatar.set(`field`, type);
                this.payloadAvatar.set("location", "topic_image");
                this.apiUpdateAvatar(type);
            } else this.$toast.error("Invalid file");
        }
    }

    public handleOpenDeleteTopicConfirm(isMultiple = false) {
        this.isMultipleDelete = isMultiple;
        this.isShowPopupConfirm = true;
        (this.$refs["modal-base-delete-topic"] as any).openModal();
    }

    public async handleCreateNewEditTopic() {
        if (this.topicForm.name.English === "") {
            this.$toast.error("Please enter the topic name");
            return;
        }
        if (this.isStateModal === "create") {
            this.loading.submitForm = true;
            this.apiRequestTopic("Create", {
                name: this.topicForm.name,
            });
        } else
            this.apiRequestTopic("Update", {
                id: this.topicForm.id,
                name: this.topicForm.name,
            });
    }

    public handleUnSelectAllTopic() {
        this.topicsDataSelected = [];
    }

    public handleCheckChooseTopic(item: any, checked: boolean) {
        if (this.topicsDataSelected.includes(item.id) && !checked)
            this.topicsDataSelected = this.topicsDataSelected.filter(
                (topicId: any) => topicId !== item.id
            );
        else this.topicsDataSelected.push(item.id);
    }

    public handleEditTopic(item: any) {
        const t = toRaw(item);
        this.topicForm.id = t.id;
        this.topicForm.image_url = t.image_url;
        this.topicForm.topic_type = t.topic_type;
        this.topicForm.name.English = t.name;
        this.isStateModal = "edit";
    }

    public handleCancelDeleteTopic() {
        this.isShowPopupConfirm = false;
    }

    public apiGetTopicList() {
        this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.GET_VIDEO_CALL_TOPICS_V2,
            {
                person_ai_id: null,
                populate_scenario: false,
            },
            (data: any) => {
                this.topicsData = data.data;
            }
        );
    }
}
