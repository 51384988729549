import { languageAvailable } from "@/constants";
import { IVocabulary, IVocabularyItem } from "./type";

export default class Vocabulary {
    public static initVocabularies(): IVocabularyItem[] {
        const vc: IVocabularyItem[] = [];
        for (let i = 1; i < 6; i++) {
            vc.push({
                id: `id-temporary-${i}`,
                word: "",
                type: "",
                word_variant_pronounce: "",
                example_sentence: "",
                word_phonics_pronounce: "",
                example_variant_pronounce: "",
                example_phonics_pronounce: "",
            });
        }
        return vc;
    }
    public static initVocabulary(): IVocabulary {
        const vc: any = {};
        languageAvailable.forEach((i) => {
            vc[i] = this.initVocabularies();
        });
        return vc;
    }
}
