
import { Auth } from "aws-amplify";

// @ts-nocheck
const amplifyConfig = {
    Auth: {
        region: process.env.VUE_APP_COGNITO_AWS_REGION,
        userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID,
        mandatorySignIn: false,
        oauth: {
            //Use for social login
            // domain: process.env.VUE_APP_USER_POOL_DOMAIN,
            // scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
            // redirectSignIn: process.env.VUE_APP_REDIRECT_SIGNIN_URL, // Make sure to use the exact URL
            // redirectSignOut: process.env.VUE_APP_REDIRECT_SIGNOUT_URL, // Make sure to use the exact URL
            // responseType: "token",
            // urlOpener: (url: any, redirectSignIn: any) => {




            //     // In the parent window
            //     const left = (window.screen.width - 600) / 2;
            //     const top = (window.screen.height - 549) / 2;
            //     const windowProxy = window.open(url, "center window",
            //         `resizable = yes, width=600,height=549,top=${top},left=${left}`);

            //     const checkUrlFunc = setInterval(async () => {
            //         try {
            //             const currentUser = await Auth.currentAuthenticatedUser();
            //             if (currentUser) {
            //                 windowProxy?.close()
            //                 window.location.reload()
            //                 clearIntervalFunc()
            //             }
            //         } catch (error) {
            //         }
            //     }, 1000)

            //     function clearIntervalFunc() {
            //         clearInterval(checkUrlFunc)
            //     }
            //     return Promise.resolve(windowProxy)
            // }
        },

    }
}

export default amplifyConfig