import { fetchWrapper } from "@/utils";
import {
  ActivityDataFromAPI,
  CognitiveDataFromAPI,
  InsightDataFromAPI,
  LearningProcessDataFromAPI,
  ParentInformation,
  StatisticDataFromAPI,
} from "./type";
import {
  COGNITIVE_DEFAULT_API_DATA,
  INSIGHT_DEFAULT_API_DATA,
  LEARNING_PROCESS_DEFAULT_DATA_FROM_API,
  STATISTIC_DEFAULT_API_DATA,
} from "./constants";
import ApiService from "@/services/api";
import { AxiosResponse } from "axios";

export const getParentInformation = async (parentId: string) => {
  const result = await fetchWrapper(`/api/v1/advisor/get-parent/${parentId}`);
  if (result.isSuccess) {
    return result.data as ParentInformation;
  }
  return null;
};

export const getLearningProgress = async (
  parentId: string,
  filter: number
): Promise<LearningProcessDataFromAPI> => {
  const result = await fetchWrapper(
    `/api/v1/user-progress-activity/get-child-learning-progress?parent_id=${parentId}&options_filter_days=${filter}`
  );
  if (result.isSuccess) {
    return result.data as LearningProcessDataFromAPI;
  }
  return LEARNING_PROCESS_DEFAULT_DATA_FROM_API;
};

export const getStatistics = async (
  userId: number,
  filter: number
): Promise<StatisticDataFromAPI> => {
  const result = await fetchWrapper(
    `/api/v1/user-progress-activity/get-user-activity-profile?user_id=${userId}&options_filter_days=${filter}`
  );
  if (result.isSuccess) {
    return result.data as StatisticDataFromAPI;
  }
  return STATISTIC_DEFAULT_API_DATA;
};

export const getChatInsight = async (
  userId: number
): Promise<InsightDataFromAPI> => {
  const result = await fetchWrapper(
    `/api/v1/user-progress-activity/get-child-sentiment?user_id=${userId}`
  );
  if (result.isSuccess) {
    return result.data as InsightDataFromAPI;
  }
  return INSIGHT_DEFAULT_API_DATA;
};

export const getCognitiveAssessmentReview = async (
  userId: number
): Promise<CognitiveDataFromAPI> => {
  const result = await fetchWrapper(
    `/api/v1/user-progress-activity/get-latest-progress?user_id=${userId}`
  );
  if (result.isSuccess) {
    return result.data as CognitiveDataFromAPI;
  }
  return COGNITIVE_DEFAULT_API_DATA;
};

export const getChildActivities = async (
  parentId: string,
  userId: number,
  filter: number,
  component?: number
): Promise<ActivityDataFromAPI | null> => {
  const result = await fetchWrapper(
    `/api/v1/user-progress-activity/get-all-activity-user?parent_id=${parentId}&page=1&per_page=100&user_id=${userId}&options_filter_days=${filter}${
      component ? `&component_id=${component}` : ""
    }`
  );
  if (result.isSuccess) {
    return result.data as ActivityDataFromAPI;
  }
  return null;
};

export const sendMeetingRequest = async (
  parent_id: number,
  advisor_id: number,
  phone: string,
  meetingDetails: object
) => {
  try {
    const response = (await ApiService.executePostRequest(
      "/api/v1/meeting-information",
      {
        parent_id,
        advisor_id,
        phone,
        provider: "Google Meet",
        ...meetingDetails,
      }
    )) as AxiosResponse;
    if (response.status === 200) {
      return {
        isSuccess: true,
      };
    } else {
      return {
        isSuccess: false,
        error: response.data?.message || "Unknown error occurred",
        data: null,
      };
    }
  } catch (error) {
    return {
      isSuccess: false,
      error: error || "Unknown error occurred",
      data: null,
    };
  }
};

export const deleteMeetingRequest = async (meetingId: number) => {
  try {
    const response = (await ApiService.executeDeleteRequest(
      `/api/v1/meeting-information/${meetingId}`,
      {}
    )) as AxiosResponse;
    if (response.status === 200) {
      return {
        isSuccess: true,
      };
    } else {
      return {
        isSuccess: false,
        error: response.data?.message || "Unknown error occurred",
        data: null,
      };
    }
  } catch (error) {
    return {
      isSuccess: false,
      error: error || "Unknown error occurred",
      data: null,
    };
  }
};
