import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f0737e3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal fade",
  id: "modal-base",
  ref: "modal-base",
  tabindex: "-1"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "body" }
const _hoisted_6 = {
  class: "d-flex flex row w-100",
  style: {"align-items":"flex-start","gap":"8px","align-self":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.description), 1),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.isDanger)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "footer-danger",
                "data-bs-dismiss": "modal",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickBtnAction && _ctx.handleClickBtnAction(...args)))
              }, _toDisplayString(_ctx.btnAction), 1))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: "footer-secondary",
            "data-bs-dismiss": "modal",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClickCancel && _ctx.handleClickCancel(...args)))
          }, _toDisplayString(_ctx.btnCancel), 1),
          (!_ctx.isDanger)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "footer-primary",
                "data-bs-dismiss": "modal",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClickBtnAction && _ctx.handleClickBtnAction(...args)))
              }, _toDisplayString(_ctx.btnAction), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 512))
}