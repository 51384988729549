<script lang="ts" setup>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";
interface Props {
  Icon?: any;
  prefix?: string;
  dataOptions: {
    value: any;
    label: string;
    active?: boolean;
    imgUrl?: string;
  }[];
  selectedDefault?: string | number;
  labelPlaceholder?: string;
  allowSearch?: boolean;
}
const props = withDefaults(defineProps<Props>(), {});
const selected = ref<any>(props.selectedDefault || null);
const searchQuery = ref<string>("");
const emits = defineEmits(["on-selected"]);
const showDropdownMenu = ref(false);
const dropdownMenuRef = ref<any>(null);
function handleSelectChange(value: any) {
  selected.value = value;
  emits("on-selected", value);
  showDropdownMenu.value = false;
  searchQuery.value = "";
}
const handleClickOutside = (event: any) => {
  if (dropdownMenuRef.value && !dropdownMenuRef.value.contains(event.target)) {
    showDropdownMenu.value = false;
  }
};

const getLabel = (value: any) => {
  for (let i = 0; i < props.dataOptions.length; i++) {
    if (props.dataOptions[i].value === value) {
      return props.dataOptions[i].label;
    }
  }
};
onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});
onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

watch(
  () => props.selectedDefault,
  (val: any) => {
    selected.value = val;
  },
  { deep: true }
);

const filteredOptions = computed(() => {
  return props.dataOptions.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});
</script>
<template>
  <div
    ref="dropdownMenuRef"
    class="customDropdown-control box-shadow-sm"
    style="position: relative"
  >
    <div class="sort" style="border: none">
      <div
        class="customDropdown-toggle"
        type="button"
        @click="showDropdownMenu = !showDropdownMenu"
      >
        <a
          style="
            color: var(--neutral-darkest, #0a0a0a);
            font-size: 14px;
            font-family: Poppins;
            font-style: normal;
          "
        >
          <component v-if="Icon" :is="Icon"></component>
          {{ prefix ? prefix : "" }}
          <span :class="prefix ? 'bold' : ''">{{
            selected
              ? getLabel(selected)
              : labelPlaceholder
              ? labelPlaceholder
              : "Choose option"
          }}</span>
        </a>
      </div>
    </div>
    <div
      v-if="showDropdownMenu"
      class="customDropdown-menu"
      ref="popoverDropdownMenuRef"
    >
      <template v-if="allowSearch">
        <div class="search-control">
          <i class="bi bi-search"></i>

          <input
            v-if="allowSearch"
            class="input-search"
            type="text"
            v-model="searchQuery"
            placeholder="Search..."
          />
        </div>

        <a
          class="customDropdown-item"
          v-for="(item, index) in filteredOptions"
          :class="item?.active === undefined || item?.active ? '' : 'disabled'"
          :key="index"
          @click="handleSelectChange(item.value)"
        >
          <span class="customDropdown-item-img" v-if="item.imgUrl">
            <img
              style="width: 100%; height: 100%; object-fit: cover"
              :src="item.imgUrl"
              alt="Avartar"
            />
          </span>
          <span> {{ item.label }}</span>
        </a>
      </template>

      <a
        v-else
        class="customDropdown-item"
        v-for="(item, index) in filteredOptions"
        :class="item?.active === undefined || item?.active ? '' : 'disabled'"
        :key="index"
        @click="handleSelectChange(item.value)"
      >
        <span class="customDropdown-item-img" v-if="item.imgUrl">
          <img
            style="width: 100%; height: 100%; object-fit: cover"
            :src="item.imgUrl"
            alt="Avartar"
          />
        </span>
        <span> {{ item.label }}</span>
      </a>
    </div>
  </div>
</template>
<style lang="scss">
.customDropdown-control {
  font-family: "Poppins";
  border: 1px solid #ebebeb;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  height: 40px;

  background: #ffffff;
  border-radius: 12px;
  flex: none;
  flex-grow: 0;
  position: relative;
}
.customDropdown-toggle::after {
  display: inline-block;
  content: "";
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 2px solid #adadad;
  border-left: 2px solid #adadad;
  width: 8px;
  height: 8px;
  transform: rotate(-45deg);
  margin: 0.25rem 0.5rem;
}
.customDropdown-menu {
  position: absolute;
  display: flex;
  background-color: #ffffff;
  border-radius: 16px;
  flex-direction: column;
  box-shadow: 4px 8px 32px 0px rgba(0, 0, 0, 0.08);
  gap: 8px;
  right: 0;
  top: 110%;
  max-height: 450px;
  overflow: auto;
  width: fit-content;
  padding: 0.5rem;
}

.customDropdown-item {
  height: 100%;
  cursor: pointer;
  border-radius: 8px;
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #666666;
  text-decoration: none;
  .customDropdown-item-img {
    display: inline-block;
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-right: 8px;
    border-radius: 50%;
  }
  span {
    font-size: 14px;
  }
  img {
    width: 24px;
  }
  &:focus,
  &:active {
    background: none;
  }
  &:hover {
    background: #f8f9fa;
  }
}

.search-control {
  font-family: "Poppins";

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  margin: 0 12px;
  gap: 8px;

  height: 40px;

  background: #f5f5f5;
  border-radius: 10px;

  flex: none;
  flex-grow: 1;
  input {
    border: 0;
    width: 100%;
    background: transparent;
    font-family: Poppins;
    font-size: 14px;
    &:focus {
      outline: none;
    }
  }
}

.btn_dropdown {
  border-width: 0;
  background-color: transparent;
}
.bold {
  font-weight: 600;
}

@media (max-width: 576px) {
  .customDropdown-menu {
    left: 0;
  }

  .customDropdown-menu {
    max-height: 250px;
  }
}
</style>
