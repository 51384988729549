<template>
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="14.2844"
            y="13.7142"
            width="0.0114286"
            height="0.0114286"
            transform="rotate(-90 14.2844 13.7142)"
            stroke="currentColor"
            stroke-width="4.57143"
            stroke-linejoin="round"
        />
        <rect
            x="6.28442"
            y="13.7142"
            width="0.0114286"
            height="0.0114286"
            transform="rotate(-90 6.28442 13.7142)"
            stroke="currentColor"
            stroke-width="4.57143"
            stroke-linejoin="round"
        />
        <rect
            x="22.2844"
            y="13.7142"
            width="0.0114286"
            height="0.0114286"
            transform="rotate(-90 22.2844 13.7142)"
            stroke="currentColor"
            stroke-width="4.57143"
            stroke-linejoin="round"
        />
    </svg>
</template>
