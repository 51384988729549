import { CloseIcon, StickIcon } from "@/assets/images/icons/components";
import ButtonSolid from "@/components/base/buttons/primary/solid.vue";
import { FLInput } from "@/components/base/form";
import ModalBase from "@/components/base/modal-base/modal-base.vue";
import PersonAisConstants from "@/constants/person-ai";
import { ITopic, ITopicInList } from "@/interfaces/person-ai";
import { TransformedActionTypesPersonAi } from "@/store/modules/person-ai/action-style";
import { Modal } from "bootstrap";
import { Options, Vue } from "vue-class-component";

@Options({
    components: {
        ModalBase,
        ButtonSolid,
        FLInput,
        CloseIcon,
        StickIcon,
    },
    props: {
        categoryDataProps: Object,
    },
    watch: {
        categoryDataProps: {
            handler(newVal, oldVal) {
                this.updateCategoryFromProps();
            },
            deep: true,
        },
    },
})
export default class Category extends Vue {
    public isStateModal: "list" | "create" | "edit" = "list";
    public categoryDataProps!: any;
    public categoriesData: any = [];
    public categoryUpdate: any = [];
    public categoryEdit: any = {};
    public categoryForm: any = {
        value: null,
        text: "",
    };
    public isShowPopupConfirm: boolean = false;
    public loading: any = {
        submitForm: false,
    };

    public topicForm: ITopic = new PersonAisConstants().dataInitTopic;
    public personAiEditId!: number;
    public topicsData: ITopicInList[] = [];
    public categoryDataSelected: any = [];
    public payloadAvatar: any = null;
    // public isMultipleDelete: boolean = false;

    // =============== Lifecycle Hooks =============== //
    mounted() {
        this.updateCategoryFromProps();
    }

    // =============== Provider Store(VueX) =============== //
    // =============== Behavior Component =============== //
    public updateCategoryFromProps() {
        this.categoriesData = JSON.parse(
            JSON.stringify(this.categoryDataProps)
        );
        this.categoryUpdate = this.categoryDataProps;
    }
    private resetStateCop() {
        this.isStateModal = "list";
        this.categoryForm = {
            value: null,
            text: "",
        };
        this.categoryDataSelected = [];
    }
    public handleUnSelectAll() {
        this.categoryDataSelected = [];
    }
    // =============== Event Form =============== //
    public handleCheckChooseCategory(item: any, checked: boolean) {
        if (this.categoryDataSelected.includes(item.value) && !checked)
            this.categoryDataSelected = this.categoryDataSelected.filter(
                (categoryId: any) => categoryId !== item.value
            );
        else this.categoryDataSelected.push(item.value);
    }
    public handleSaveCategoryForm() {
        if (!this.categoryForm.text)
            return this.$toast.error("Please enter category!");

        this.apiRequestCategory(
            this.isStateModal === "create" ? "Create" : "Update"
        );
    }
    public handleEditCategory(item: any) {
        this.isStateModal = "edit";
        this.categoryForm = {
            value: item.value,
            text: item.text,
        };
    }
    public handleCancelSaveCategoryForm() {
        this.isStateModal = "list";
        this.categoryForm = {
            value: null,
            text: "",
        };
    }

    // =============== Action Button =============== //
    public onDeleteCategory() {
        this.isShowPopupConfirm = false;
        this.apiRequestCategory("Delete");
    }
    public handleAddNewCategory() {
        this.isStateModal = "create";
        this.categoryForm = {
            value: null,
            text: "",
        };
    }

    // =============== Action Modal =============== //
    public openModal() {
        const myModal: any = new Modal(this.$refs["modal-category"] as any);
        myModal.show();
        myModal._element.addEventListener("hidden.bs.modal", () => {
            this.resetStateCop();
        });
    }
    public handleOpenDeleteCategoryConfirm() {
        // this.isMultipleDelete = isMultiple;
        this.isShowPopupConfirm = true;
        (this.$refs["modal-base-delete-category"] as any).openModal();
    }
    public handleCancelDeleteCategory() {
        this.isShowPopupConfirm = false;
    }

    // =============== Api Action =============== //
    private async apiRequestCategory(type: "Create" | "Update" | "Delete") {
        let action = TransformedActionTypesPersonAi.CREATE_CATEGORY;
        let payload: any = {
            name: this.categoryForm.text,
        };
        switch (type) {
            case "Update":
                action = TransformedActionTypesPersonAi.UPDATE_CATEGORY;
                payload = {
                    name: this.categoryForm.text,
                    id: this.categoryForm.value,
                };
                break;

            case "Delete":
                action = TransformedActionTypesPersonAi.DELETE_CATEGORY_LIST;
                payload = {
                    category_ids: this.categoryDataSelected.map(
                        (i: number) => i
                    ),
                };
                break;
        }

        this.loading.submitForm = true;
        this.$apiAction.handleApiRequest(
            action,
            payload,
            (data: any) => {
                this.loading.submitForm = false;
                if (type !== "Delete") {
                    this.$toast.success(
                        data?.message || `${type} category success!`
                    );
                } else {
                    this.$toast.success(
                        `Successfully deleted ${data.data.category_ids.length}/${this.categoryDataSelected.length} categories!`
                    );
                }
                this.isStateModal = "list";
                this.updateCategoryData(type, data);
            },
            (data: any) => {
                this.$toast.error(data?.message || `${type} category success!`);
                this.loading.submitForm = false;
            }
        );
    }
    // =============== Utilities Helper =============== //
    private updateCategoryData(
        type: "Create" | "Update" | "Delete",
        data: any
    ) {
        switch (type) {
            case "Delete":
                this.categoriesData.forEach((item: any, idx: any) => {
                    if (data.data.category_ids.includes(item.value)) {
                        this.categoryUpdate.splice(idx, 1);
                        this.categoriesData.splice(idx, 1);
                    }
                });
                break;

            case "Update":
                this.categoriesData.forEach((item: any, idx: any) => {
                    if (item.value === this.categoryForm.value) {
                        this.categoryUpdate[idx].text = this.categoryForm.text;
                        return (this.categoriesData[idx].text =
                            this.categoryForm.text);
                    }
                });
                break;

            default:
                const category = {
                    value: data.data.category_id,
                    text: this.categoryForm.text,
                };
                this.categoriesData.push(category);
                this.categoryUpdate.push({
                    ...category,
                    active: true,
                });
                break;
        }
        this.resetStateCop();
    }
}
