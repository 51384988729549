<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99937 12.6005C9.52437 12.6005 10.0494 12.398 10.4469 12.0005L15.3369 7.11047C15.5544 6.89297 15.5544 6.53297 15.3369 6.31547C15.1194 6.09797 14.7594 6.09797 14.5419 6.31547L9.65187 11.2055C9.29187 11.5655 8.70687 11.5655 8.34687 11.2055L3.45686 6.31547C3.23936 6.09797 2.87936 6.09797 2.66186 6.31547C2.44436 6.53297 2.44436 6.89297 2.66186 7.11047L7.55187 12.0005C7.94937 12.398 8.47437 12.6005 8.99937 12.6005Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.642858"
    />
  </svg>
</template>
