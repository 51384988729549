<script lang="ts" setup>
import {
  INSIGHT_DEFAULT_API_DATA,
  INSIGHT_DEFAULT_VISUALIZATION_DATA,
} from "../../constants";
import { computed, defineProps, ref } from "vue";
import InsightBox from "./InsightBox.vue";
import { InsightDataForVisualization, InsightDataFromAPI } from "../../type";
import CardContainer from "../common/CardContainer.vue";
import { useDeviceType } from "@/hooks/useDevice";

interface Props {
  data: InsightDataFromAPI;
}
const props = withDefaults(defineProps<Props>(), {
  data: () => INSIGHT_DEFAULT_API_DATA,
});

const customStyle = ref({
  maxWidth: "400px",
});

const { deviceType } = useDeviceType();

const insightData = computed<InsightDataForVisualization>(() => {
  let visualizationData: InsightDataForVisualization =
    INSIGHT_DEFAULT_VISUALIZATION_DATA;
  if (props.data) {
    visualizationData.positiveScore.subtitle =
      (props.data.avg_positive_score * 100).toFixed(2) + "%";
    visualizationData.negativeScore.subtitle =
      (props.data.avg_negative_score * 100).toFixed(2) + "%";
    visualizationData.topic.subtitle = props.data?.list_topic
      ? props.data?.list_topic[0]
      : "";
    visualizationData.keyword.subtitle = props.data?.list_keyword
      ? props.data?.list_keyword[0]
      : "";
    return visualizationData;
  }
  return INSIGHT_DEFAULT_VISUALIZATION_DATA;
});
</script>

<template>
  <CardContainer :style="deviceType === 'desktop' ? customStyle : {}">
    <div class="insCard">
      <div class="insCard-header">
        <div>
          <p class="insCard-title">Chat Insight</p>
          <p class="insCard-subtitle">
            Learning is an exciting adventure! Enjoy your daily task and
            discover something new today.
          </p>
        </div>
      </div>
      <div class="insCard-items">
        <InsightBox
          v-for="item in insightData"
          :key="item.title"
          v-bind="item"
        />
      </div>
    </div>
  </CardContainer>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";
.insCard {
  @include flexbox(column, flex-start, flex-start, null, null, 12px);
  align-self: stretch;
  &-header {
    @include flexbox(row, flex-start, flex-start, wrap, null);
  }
  &-title {
    @include title;
  }
  &-subtitle {
    @include subtitle;
  }
  &-items {
    @include flexbox(column, flex-start, flex-start, null, null, 12px);
    width: 100%;
  }
}
</style>
