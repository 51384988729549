<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="12.8335"
      y="2"
      width="8.33335"
      height="1.66667"
      rx="0.833333"
      transform="rotate(90 12.8335 2)"
      fill="#FF513C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 4.33426C7 3.72262 6.36353 3.32556 5.85658 3.66775C3.37838 5.34057 2 8.21087 2 12.0001C2 18.2501 5.75 22.0001 12 22.0001C18.25 22.0001 22 18.2501 22 12.0001C22 8.21087 20.6216 5.34057 18.1434 3.66775C17.6365 3.32556 17 3.72262 17 4.33426V4.44993C17 4.7379 17.1511 5.00225 17.3845 5.17084C17.6671 5.37491 17.9273 5.59685 18.1652 5.83483C19.5026 7.17224 20.3333 9.20926 20.3333 12.0001C20.3333 14.7909 19.5026 16.8279 18.1652 18.1653C16.8278 19.5027 14.7908 20.3334 12 20.3334C9.20918 20.3334 7.17216 19.5027 5.83476 18.1653C4.49736 16.8279 3.66667 14.7909 3.66667 12.0001C3.66667 9.20926 4.49736 7.17224 5.83476 5.83483C6.07275 5.59685 6.33289 5.37491 6.61549 5.17083C6.84894 5.00225 7 4.7379 7 4.44993V4.33426Z"
      fill="#FF513C"
    />
  </svg>
</template>
