<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.49984 9.99998C2.49984 11.6966 2.59183 13.0031 2.81703 14.0164C3.04013 15.0202 3.38273 15.6846 3.84899 16.1508C4.31525 16.6171 4.97957 16.9597 5.98343 17.1828C6.99674 17.408 8.30323 17.5 9.99984 17.5C11.6964 17.5 13.0029 17.408 14.0162 17.1828C15.0201 16.9597 15.6844 16.6171 16.1507 16.1508C16.617 15.6846 16.9595 15.0202 17.1826 14.0164C17.4078 13.0031 17.4998 11.6966 17.4998 9.99998C17.4998 8.30337 17.4078 6.99689 17.1826 5.98357C16.9595 4.97972 16.617 4.31539 16.1507 3.84913C15.6844 3.38287 15.0201 3.04027 14.0162 2.81717C13.0029 2.59197 11.6964 2.49998 9.99984 2.49998C8.30323 2.49998 6.99674 2.59197 5.98343 2.81717C4.97957 3.04027 4.31525 3.38287 3.84899 3.84913C3.38273 4.31539 3.04013 4.97972 2.81703 5.98357C2.59183 6.99689 2.49984 8.30337 2.49984 9.99998Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 5.83331C10 5.83331 10 8.61109 10 9.30554C10 9.99998 9.99999 9.99998 10.6944 9.99998C11.3889 9.99998 14.1667 9.99998 14.1667 9.99998"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
