<template>
  <router-view />
</template>
<script setup>
import { onMounted } from "vue";
import MainService from "@/services";
import { Amplify } from "aws-amplify";
onMounted(async () => {
  try {
    await Amplify.Auth.currentAuthenticatedUser();

    await MainService.setUserDataGlobal();
    await MainService.setAllCountryGlobal();
  } catch (error) {
     // Redirect to login if not authenticated
  }
});
</script>
<style lang="scss" src="./App.scss"></style>
