<script lang="ts" src="./dropdown-select.ts"></script>
<style lang="scss" scoped src="./dropdown-select.scss"></style>

<template>
    <div class="dropdown" :class="disabled ? 'disabled' : ''">
        <div
            class="dropdown-selected d-flex justify-content-between"
            @click="toggleDropdown"
        >
            <div v-if="hasIconSlot" class="dropdown-icon">
                <slot name="icon"></slot>
            </div>
            <p class="value-selected" v-if="selected || defaultSelected">
                {{ selected || defaultSelected }}
            </p>
            <p v-else class="value-placeholder">{{ placeholder }}</p>
            <img src="@/assets/images/icons/svg/arrow-down-black.svg" />
        </div>

        <transition name="slide-fade">
            <div class="dropdown-content" v-if="isOpen">
                <template v-for="(option, index) in options" :key="index">
                    <div
                        class="dropdown-option"
                        :class="option.active || 'disabled-item'"
                        @click="selectOption(option)"
                    >
                        {{ option.text }}
                      <span v-if="provider && option.provider" class="provider">{{ option.provider }}</span>
                    </div>
                    <hr v-if="index < options.length - 1" />
                </template>
            </div>
        </transition>
    </div>
</template>
