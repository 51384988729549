import { TransformedMutationTypesTable } from "@/store/modules/table/mutation-style";
import { inject } from "vue";
import { Vue, Options } from "vue-class-component";

@Options({
    components: {},
    props: {
        defaultSelected: [String, Number],
        options: Object,
        placeholder: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        provider: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["selectOption"],
    watch: {
        defaultSelected: {
            handler() {
                this.updateSelected();
            },
        },
        options: {
            handler(value: any) {
                this.updateSelected();
            },
            deep: true,
        },
    },
})
export default class DropdownSelect extends Vue {
    public defaultSelected!: any;
    public selected: any = null;
    public options!: any;
    public placeholder!: string;
    public isOpen: boolean = false;
    public disabled!: boolean;
    public provider!: boolean;

    mounted(): void {
        document.addEventListener("click", this.handleClickOutside);
        this.updateSelected();
    }
    unmounted(): void {
        document.removeEventListener("click", this.handleClickOutside);
    }

    public updateSelected() {
        this.selected =
            this.options.find((option: any) => {
                return option.value == this.defaultSelected;
            })?.text || "";
    }

    public toggleDropdown() {
        this.isOpen = !this.isOpen;
    }
    public selectOption(option: any) {
        this.selected = option.text;
        this.isOpen = false;

        this.$emit("selectOption", option.value);
    }

    // Function to close the popup when clicking outside
    public handleClickOutside = (event: any) => {
        // this for emoji picker
        if (
            this.isOpen &&
            !event.target.closest(".dropdown-selected") &&
            !event.target.closest(".dropdown-content")
        ) {
            this.isOpen = false;
        }
    };

    get hasIconSlot(): boolean {
        return !!this.$slots.icon;
    }
}
