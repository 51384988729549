<script lang="ts" setup>
import { defineProps, ref } from "vue";
import { MeetingInfoForParent } from "../../type";
import { TrashbinIcon } from "@/assets/images/icons/components";
import StatusBadge from "../common/StatusBadge.vue";
import { deleteMeetingRequest } from "../../fetchService";
import { toast } from "vue3-toastify";
import Loading from "../common/Loading.vue";
import ModalBase from "@/components/base/modal-base/modal-base.vue";

interface Props {
  data?: MeetingInfoForParent;
}

const emits = defineEmits(["delete"]);
const props = defineProps<Props>();

const isDeleting = ref<boolean>(false);
const deleteMeeting = async () => {
  isDeleting.value = true;
  const response = await deleteMeetingRequest(props.data ? props.data.id : 0);
  isDeleting.value = false;
  if (response.isSuccess) {
    emits("delete");
    toast.success("Meeting deleted");
  } else {
    toast.error("Failed to delete meeting");
  }
};

const cancelMeetingModal = ref<any>();
const openModalCancelMeeting = () => {
  if (cancelMeetingModal.value) cancelMeetingModal.value.openModal();
};
</script>

<template>
  <ModalBase
    ref="cancelMeetingModal"
    title="Meeting Cancellation"
    btnAction="Yes, Cancel"
    btnCancel="No"
    description="Are you sure you want to cancel this meeting?"
    :isDanger="true"
    @handleClickBtnAction="deleteMeeting"
  />
  <div v-if="data" class="meetingInfo">
    <div class="meetingInfo-left">
      <p class="meetingInfo-left-title">{{ data.title }}</p>
      <p class="meetingInfo-left-time">
        <img src="@/assets/images/icons/png/google-meet.png" />
        {{ data.time_info }}
      </p>
      <ul>
        <li>Time zone: {{ data.time_zone }}</li>
        <li>
          Google Meet:
          <a :href="data.meeting_link" target="_blank">
            {{ data.meeting_link }}
          </a>
        </li>
      </ul>
    </div>
    <div class="meetingInfo-right">
      <StatusBadge :type="data.status" />
      <button
        v-if="!isDeleting"
        class="meetingInfo-delete"
        @click="openModalCancelMeeting"
      >
        <TrashbinIcon />
      </button>
      <Loading v-else />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";
.meetingInfo {
  @include flexbox(row, flex-start, flex-start, null, null, 16px);
  width: 100%;
  &-left {
    @include flexbox(column, flex-start, flex-start, null, 1, 8px);
    &-label {
      font-size: 14px;
      font-weight: 600;
      line-height: 19.6px;
      text-align: left;
      color: rgba(34, 34, 34, 1);
      padding-bottom: 8px;
    }
    &-title {
      //@include typography(14px, 600, 1.5, normal, $neutral-300);
      font-size: 14px;
      font-weight: 600;
      line-height: 19.6px;
      text-align: left;
      color: rgba(34, 34, 34, 1);
      padding-bottom: 8px;
    }
    &-time {
      @include typography(14px, 600, 1.5, normal, $neutral-300);
      background: linear-gradient(135.91deg, #75d473 15.13%, #1cd1e0 94.67%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    ul {
      li {
        font-size: 14px;
        text-align: left;
        white-space: normal;
        word-wrap: break-word;
      }
    }
  }
  &-right {
    @include flexbox(row, flex-end, center, wrap, null, 16px);
  }
  &-delete {
    @include size(46px, 46px);
    @include flexbox(row, center, center);
    border: none;
    background: $neutral-900;
    border-radius: 50%;
    color: $neutral-500;
  }
  @include responsive-for(mobile) {
    @include flexbox(column, flex-start, flex-start, null, null, 16px);
  }
}
</style>
