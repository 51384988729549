// @ts-nocheck
type MutationTypesUserType = {
    ADD_SELECTED_ROW: string,
    SET_TAB_ACTIVE: string,

};

export const MutationTypesUser: MutationTypesUserType = {
    ADD_SELECTED_ROW: 'ADD_SELECTED_ROW',
    SET_TAB_ACTIVE: 'SET_TAB_ACTIVE',
};


function transformObjectProperties(targetObject, prefix) {
    const transformedObject = {};

    // Iterate over the object properties
    for (const key in targetObject) {
        transformedObject[key] = `${prefix}/${targetObject[key]}`;
    }

    return transformedObject;
}
;

export const TransformedMutationTypesUser: MutationTypesUserType = transformObjectProperties(MutationTypesUser, 'user');
