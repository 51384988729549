<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5769 16.4069C7.44099 16.7433 7.09949 16.9532 6.73927 16.9103C3.27592 16.4975 2 15.3105 2 12.0583C2 7.90702 4.07874 7.12056 10.1318 7.01417C10.7261 7.00373 11.1337 7.60505 10.911 8.15616C10.7845 8.46913 10.4821 8.67489 10.1446 8.68089C9.10349 8.69941 8.20457 8.73819 7.42607 8.80979C6.20504 8.92209 5.40089 9.10614 4.86527 9.34456C4.38147 9.55992 4.14615 9.80408 3.99177 10.106C3.80993 10.4617 3.66667 11.0475 3.66667 12.0583C3.66667 13.069 3.80993 13.6548 3.99177 14.0105C4.14615 14.3124 4.38147 14.5566 4.86527 14.7719C5.40089 15.0104 6.20504 15.1944 7.42607 15.3067C7.71026 15.3329 7.89309 15.6245 7.78617 15.8891L7.5769 16.4069ZM13.1506 15.9593C13.277 15.6465 13.579 15.4408 13.9164 15.4345C14.9317 15.4156 15.8107 15.3769 16.5739 15.3067C17.795 15.1944 18.5991 15.0104 19.1347 14.7719C19.6185 14.5566 19.8538 14.3124 20.0082 14.0105C20.1901 13.6548 20.3333 13.069 20.3333 12.0583C20.3333 11.0475 20.1901 10.4617 20.0082 10.106C19.8538 9.80408 19.6185 9.55992 19.1347 9.34456C18.5991 9.10614 17.795 8.92209 16.5739 8.80979C16.3188 8.78632 16.155 8.52439 16.251 8.28682L16.4815 7.71631C16.6177 7.3793 16.9602 7.16924 17.3209 7.2135C20.7389 7.63284 22 8.82489 22 12.0583C22 16.1953 19.9354 16.9905 13.9301 17.1012C13.3355 17.1122 12.9278 16.5107 13.1506 15.9593Z" fill="#FF513C"/>
    <path
      d="M8 20L15 4"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
