// @ts-nocheck
type ActionTypesUserType = {
    GET_LEARNERS: string,
    GET_PARENTS: string,
    UPDATE_USER: string,
    UPDATE_PARENT : string,
    DELETE_USER: string,
    DELETE_PARENT : string,
    CREATE_USER: string,

    EXPORT_EXCEL_LEARNER: string,
    EXPORT_EXCEL_PARENT: string,
};

export const ActionTypesUser: ActionTypesUserType = {
    GET_LEARNERS: 'GET_LEARNERS',
    GET_PARENTS: 'GET_PARENTS',
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_PARENT: 'UPDATE_PARENT',

    DELETE_USER: 'DELETE_USER',
    DELETE_PARENT: 'DELETE_PARENT',

    CREATE_USER: 'CREATE_USER',
    EXPORT_EXCEL_LEARNER: 'EXPORT_EXCEL_LEARNER',
    EXPORT_EXCEL_PARENT: 'EXPORT_EXCEL_PARENT',
};


function transformObjectProperties(targetObject, prefix) {
    const transformedObject = {};

    // Iterate over the object properties
    for (const key in targetObject) {
        transformedObject[key] = `${prefix}/${targetObject[key]}`;
    }

    return transformedObject;
}

export const TransformedActionTypesUser: ActionTypesUserType = transformObjectProperties(ActionTypesUser, 'user');
