<script lang="ts" src="./modal-base.ts"></script>
<style lang="scss" scoped src="./modal-base.scss"></style>

<template>
    <div class="modal fade" id="modal-base" ref="modal-base" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="header">
                    <h5>{{ title }}</h5>
                </div>
                <div class="body">
                    {{ description }}
                </div>
                <div
                    class="d-flex flex row w-100"
                    style="
                        align-items: flex-start;
                        gap: 8px;
                        align-self: center;
                    "
                >
                    <button
                        v-if="isDanger"
                        class="footer-danger"
                        data-bs-dismiss="modal"
                        @click="handleClickBtnAction"
                    >
                        {{ btnAction }}
                    </button>
                    <button
                        class="footer-secondary"
                        data-bs-dismiss="modal"
                        @click="handleClickCancel"
                    >
                        {{ btnCancel }}
                    </button>
                    <button
                        v-if="!isDanger"
                        class="footer-primary"
                        data-bs-dismiss="modal"
                        @click="handleClickBtnAction"
                    >
                        {{ btnAction }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
