<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6782 4.45172L16.6782 4.45166L15.5055 3.27895C15.5054 3.27893 15.5054 3.27892 15.5054 3.2789C15.2721 3.0457 14.9557 2.9147 14.6259 2.9147C14.296 2.9147 13.9796 3.04571 13.7463 3.27892L13.7463 3.27895M16.6782 4.45172L5.8642 11.1651C5.96044 10.6835 6.19708 10.2411 6.5443 9.89366L13.4531 2.98567L13.7463 3.27895M16.6782 4.45172C16.7938 4.56725 16.8855 4.70442 16.948 4.85539C17.0106 5.00636 17.0428 5.16818 17.0428 5.3316C17.0428 5.49502 17.0106 5.65683 16.948 5.80781C16.8855 5.95878 16.7938 6.09595 16.6782 6.21148L16.6782 6.21154L9.77025 13.1195M16.6782 4.45172L13.7463 3.27895M13.7463 3.27895L6.83761 10.1868C6.83758 10.1869 6.83755 10.1869 6.83752 10.1869C6.54821 10.4764 6.35105 10.845 6.27086 11.2464L6.27085 11.2464L5.78317 13.6865L5.78313 13.6867C5.76971 13.7536 5.77304 13.8229 5.79282 13.8883C5.8126 13.9536 5.84821 14.0131 5.89651 14.0614C5.9448 14.1097 6.00428 14.1453 6.06965 14.1651L5.94955 14.562L6.06965 14.1651C6.13502 14.1849 6.20426 14.1882 6.27123 14.1748L6.27128 14.1748L8.71051 13.6862L8.7107 13.6862C9.11205 13.606 9.48068 13.4088 9.77017 13.1195M9.77017 13.1195L10.0634 13.4128L9.77025 13.1195M9.77017 13.1195C9.7702 13.1195 9.77022 13.1195 9.77025 13.1195M9.77017 13.1195L9.77025 13.1195M12.8668 5.33195L13.16 5.62516L14.3327 6.79792L14.626 7.09115L14.3327 7.38439L9.18388 12.5332L9.18363 12.5335C9.00993 12.7069 8.78881 12.8251 8.54811 12.8731C8.54804 12.8731 8.54797 12.8731 8.54791 12.8731L7.32807 13.1169L6.71826 13.2388L6.84014 12.629L7.08398 11.409L7.08405 11.4086C7.13238 11.1678 7.2509 10.9467 7.42468 10.7732L12.8668 5.33195ZM12.8668 5.33195L12.5735 5.62514L7.42493 10.7729L12.8668 5.33195ZM14.9189 6.21152L15.2122 6.50496L15.5055 6.21163L16.0919 5.62525L16.385 5.33212L16.092 5.03888L14.9192 3.8653L14.626 3.57186L14.3326 3.86519L13.7463 4.45157L13.4531 4.7447L13.7462 5.03794L14.9189 6.21152Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.82939"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.28711 16.6706C3.28711 16.4507 3.37449 16.2397 3.53003 16.0842C3.68557 15.9286 3.89653 15.8412 4.1165 15.8412H15.728C15.9479 15.8412 16.1589 15.9286 16.3144 16.0842C16.47 16.2397 16.5574 16.4507 16.5574 16.6706C16.5574 16.8906 16.47 17.1016 16.3144 17.2571C16.1589 17.4126 15.9479 17.5 15.728 17.5H4.1165C3.89653 17.5 3.68557 17.4126 3.53003 17.2571C3.37449 17.1016 3.28711 16.8906 3.28711 16.6706Z"
      fill="currentColor"
    />
  </svg>
</template>
