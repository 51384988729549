// @ts-nocheck
import { ActionTypesDashboard } from "./action-style";
import ApiService from "@/services/api";
export default {
    [ActionTypesDashboard.GET_NUMBER_KIDS_BY_AGE]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest("/api/v1/users/get-number-users-by_age", payload)
        if (response) {
            return response;
        } else {
            return null;
        }
    },
    [ActionTypesDashboard.GET_TOTAL_USER]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest("/api/v1/users-parents/get-number-users-parents", payload)
        if (response) {
            return response;
        } else {
            return null;
        }
    },
    [ActionTypesDashboard.GET_TOTAL_PAID_USER]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest("/api/v1/package-groups/get-active-subscription", payload)
        if (response) {
            return response;
        } else {
            return null;
        }
    },
    
}