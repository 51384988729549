<script lang="ts" setup>
import { defineEmits, defineProps, ref } from "vue";
import { ParentInformation } from "../../type";
import {
  MailIcon,
  PhoneIcon,
  UnlinkIcon,
  NotificationIcon,
} from "@/assets/images/icons/components";
import ModalBase from "@/components/base/modal-base/modal-base.vue";

interface ParentWithoutChildren extends Omit<ParentInformation, "children"> {}

interface Props {
  data: ParentWithoutChildren;
}
const props = withDefaults(defineProps<Props>(), {
  data: () => ({
    id: 0,
    email: "",
    username: "",
    display_name: "",
    avatar_url: "",
    nationality: "",
  }),
});

const unlinkParentModal = ref<any>();
const openModalUnlinkConfirm = () => {
  if (unlinkParentModal.value) unlinkParentModal.value.openModal();
};

const emits = defineEmits(["on-unlink"]);
const handleConfirmUnlink = () => {
  emits("on-unlink");
};
</script>

<template>
  <ModalBase
    ref="unlinkParentModal"
    title="Unlink parent"
    btnAction="Yes, Unlink"
    btnCancel="Cancel"
    description="Are you sure you want to unlink this parent?"
    :isDanger="true"
    @handleClickBtnAction="handleConfirmUnlink"
  />

  <div class="parentDetails">
    <div class="parentDetails-info">
      <div class="parentDetails-info-avatar">
        <img
          :src="
            data.avatar_url
              ? data.avatar_url
              : require(`@/assets/images/icons/svg/defaultAvatar.svg`)
          "
          alt="Avatar"
        />
      </div>
      <div class="parentDetails-info-text">
        <div class="parentDetails-info-name">
          {{ data.display_name || data.username }}
        </div>
        <div class="parentDetails-info-nationality">{{ data.nationality }}</div>
      </div>
    </div>
    <div class="parentDetails-contact">
      <div class="parentDetails-contact-item">
        <div class="parentDetails-contact-icon"><MailIcon /></div>
        <div class="parentDetails-contact-value">{{ data.email }}</div>
      </div>
      <div class="parentDetails-contact-item">
        <div class="parentDetails-contact-icon"><PhoneIcon /></div>
        <div class="parentDetails-contact-value">
          {{ data.phone || "(000) xxx xxx xxx" }}
        </div>
      </div>

      <div
        class="parentDetails-contact-item unlink"
        @click="openModalUnlinkConfirm"
      >
        <div class="parentDetails-contact-icon"><UnlinkIcon /></div>
        <div class="parentDetails-contact-value">Unlike Request</div>
      </div>
    </div>
    <div class="parentDetails-phone-not-exits" v-if="!data.phone">
      <NotificationIcon />
      <span> This parent has not added a WhatsApp contact method yet. </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";
.parentDetails {
  @include flexbox(column, flex-start, flex-start, null, null, 16px);
  width: 100%;
  &-info {
    @include flexbox(row, flex-start, center, null, null, 24px);
    &-avatar {
      @include avatar(80px, 80px);
      border: 2px solid #75d473;
    }
    &-name {
      @include title;
    }
    &-nationality {
      @include subtitle;
    }
  }
  &-contact {
    @include flexbox(row, flex-start, center, wrap, null, 16px);
    &-item {
      @include flexbox(row, flex-start, center, null, null, 8px);
      @include subtitle;
      color: $neutral-400;
      &.unlink {
        color: $error-500;
        cursor: pointer;
      }
    }
    &-value {
      color: inherit;
    }
  }
  &-phone-not-exits {
    width: 100%;
    padding: 16px;
    gap: 8px;
    border-radius: 16px;
    background: #ffecef;
    align-items: flex-start;
    justify-content: flex-start !important;
    text-align: left;
    span {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #666666;
    }
  }
}
</style>
