<script lang="ts" setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";
import { ArrowIcon } from "@/assets/images/icons/components";

interface Props {
  name?: string;
}

const router = useRouter();
const props = withDefaults(defineProps<Props>(), {
  name: "",
});

function onNavigate() {
  router.go(-1);
}
</script>

<template>
  <div class="backBtn">
    <button @click="onNavigate">
      <ArrowIcon />
    </button>
    <p>
      Parent: <span>{{ name }}</span>
    </p>
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";
.backBtn {
  @include flexbox(row, flex-start, center, null, 1, 16px);
  width: 100%;
  button {
    @include size(32px, 32px);
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 4px 8px 32px 0px #00000014;
    backdrop-filter: blur(32px);
    border: none;
    transform: rotate(90deg);
  }
  p {
    @include typography(24px, 600, 1.5, normal, $neutral-200);

    & span {
      color: $primary-500;
    }
  }
}
</style>
