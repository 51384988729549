import { Vue, Options } from "vue-class-component";
import Notification from "@/components/base/notification/notification.vue";
import { MenuIcon, OffIcon } from "@/assets/images/icons/components";
import router from "@/router";
import { ROUTE_NAMES } from "@/constants/routeNames";
import GetterTypes from "@/store/getter-types";
import { useDeviceType } from "@/hooks/useDevice";
import SideBarMobile from "../side-bar-mobile/side-bar-mobile.vue";
@Options({
  components: {
    Notification,
    MenuIcon,
    OffIcon,
    SideBarMobile,
  },
  props: {},

  watch: {
    $route(to, from) {
      this.checkRouteName(to.name);
      this.routeName = to.name;
    },
    "$store.state.userData": {
      handler(newValue, oldValue) {
        this.userData = newValue;
        const routeName: any = router.currentRoute.value.name;
        this.checkRouteName(routeName);
      },
      deep: true,
    },
  },
})
export default class Header extends Vue {
  public routeName: any = "";
  public headerName: any = "";
  public role: string = "";
  public deviceType: any = "desktop";
  public userData: any = {};
  public isSidebarOpen: boolean = false;

  public beforeMount() {
    this.userData = this.$store.getters[GetterTypes.GET_USER_DATA];
    const routeName: any = router.currentRoute.value.name;
    this.checkRouteName(routeName);
  }

  public created() {
    const { deviceType } = useDeviceType();
    this.deviceType = deviceType;
  }

  public handleProfile() {
    this.$router.push("/profile");
  }

  public checkRouteName(routeName: string) {
    switch (routeName) {
      case ROUTE_NAMES.DASHBOARD:
        this.headerName =
          "Welcome to Jungle station, " + this.userData?.display_name;
        break;
      case ROUTE_NAMES.USERS:
      case ROUTE_NAMES.PAID_USERS:
        this.headerName = "Paid Users";
        break;
      case ROUTE_NAMES.FREE_USERS:
        this.headerName = "Free Users";
        break;
      default:
        this.headerName = routeName;
        break;
    }
  }

  public formatHeader(headerName: string) {
    headerName =
      headerName === "Parent-information" ? "Advisor-dashboard" : headerName;
    return headerName
      .replace(/[-_]/g, " ")
      .split(" ")
      .map((word, index) =>
        index === 0
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : word.toLowerCase()
      )
      .join(" ");
  }

  public toggleSidebarMobile(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }
}
