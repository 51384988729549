import AdminsConstants from "@/constants/admins";
import PersonAisConstants from "@/constants/person-ai";

class PersonAiServices {
    countNonNullFields(obj: any) {
        return Object.values(obj).filter(
            (value) => value !== null && value !== ""
        ).length;
    }

    convertEditPayload(originalObject: any) {
        const languageFields = {
            English: "",
            French: "",
            German: "",
            Hindi: "",
            Italian: "",
            Polish: "",
            Portuguese: "",
            Spanish: "",
            Japanese: "",
            Korean: "",
            Vietnamese: "",
        };

        const skillsStructure: any = {
            creativity: 0,
            resilience: 0,
            emotional_intelligence: 0,
            leadership: 0,
            problem_solving: 0,
            physical_fitness: 0,
        };

        function convertTextToSnakeKey(text: string) {
            return text.toLowerCase().replaceAll(" ", "_");
        }

        // If the original object contains 'skill' array, convert it to the desired structure
        if (originalObject.skill) {
            if (Array.isArray(originalObject.skill)) {
                originalObject.skill.forEach((skillItem: any) => {
                    const skill_name = convertTextToSnakeKey(
                        skillItem.skill_name
                    );
                    if (skillsStructure.hasOwnProperty(skill_name)) {
                        skillsStructure[skill_name] = skillItem.skill_weight;
                    }
                });
            }
        }

        return {
            name: originalObject.name || "",
            continents: originalObject.continents || "",
            country: originalObject.country || "",
            education: originalObject.education || "",
            title: originalObject.title || languageFields,
            description: originalObject.description || languageFields,
            quote: originalObject.quote || languageFields,
            inspired_character: originalObject.inspired_character || "",
            update_date: new Date(originalObject.updated_date).toString() || "",
            welcome_messages: originalObject.welcome_messages || languageFields,
            is_video_call_enabled:
                originalObject.is_video_call_enabled || false,
            guideline_next_question_1: {
                English: originalObject.guideline_next_questions.English[0],
                French: originalObject.guideline_next_questions.French[0],
                German: originalObject.guideline_next_questions.German[0],
                Hindi: originalObject.guideline_next_questions.Hindi[0],
                Italian: originalObject.guideline_next_questions.Italian[0],
                Polish: originalObject.guideline_next_questions.Polish[0],
                Portuguese:
                    originalObject.guideline_next_questions.Portuguese[0],
                Spanish: originalObject.guideline_next_questions.Spanish[0],
                Japanese:
                    originalObject.guideline_next_questions.Japanese !==
                    undefined
                        ? originalObject.guideline_next_questions.Japanese[0]
                        : "",
                Korean:
                    originalObject.guideline_next_questions.Korean !== undefined
                        ? originalObject.guideline_next_questions.Korean[0]
                        : "",
                // Vietnamese: originalObject.guideline_next_questions.Vietnamese !== undefined ? originalObject.guideline_next_questions.Vietnamese[0] : ""
            },
            guideline_next_question_2: {
                English: originalObject.guideline_next_questions.English[1],
                French: originalObject.guideline_next_questions.French[1],
                German: originalObject.guideline_next_questions.German[1],
                Hindi: originalObject.guideline_next_questions.Hindi[1],
                Italian: originalObject.guideline_next_questions.Italian[1],
                Polish: originalObject.guideline_next_questions.Polish[1],
                Portuguese:
                    originalObject.guideline_next_questions.Portuguese[1],
                Spanish: originalObject.guideline_next_questions.Spanish[1],
                Japanese:
                    originalObject.guideline_next_questions.Japanese !==
                    undefined
                        ? originalObject.guideline_next_questions.Japanese[1]
                        : "",
                Korean:
                    originalObject.guideline_next_questions.Korean !== undefined
                        ? originalObject.guideline_next_questions.Korean[1]
                        : "",
                // Vietnamese: originalObject.guideline_next_questions.Vietnamese !== undefined ? originalObject.guideline_next_questions.Vietnamese[1] : ""
            },
            guideline_next_question_3: {
                English: originalObject.guideline_next_questions.English[2],
                French: originalObject.guideline_next_questions.French[2],
                German: originalObject.guideline_next_questions.German[2],
                Hindi: originalObject.guideline_next_questions.Hindi[2],
                Italian: originalObject.guideline_next_questions.Italian[2],
                Polish: originalObject.guideline_next_questions.Polish[2],
                Portuguese:
                    originalObject.guideline_next_questions.Portuguese[2],
                Spanish: originalObject.guideline_next_questions.Spanish[2],
                Japanese:
                    originalObject.guideline_next_questions.Japanese !==
                    undefined
                        ? originalObject.guideline_next_questions.Japanese[2]
                        : "",
                Korean:
                    originalObject.guideline_next_questions.Korean !== undefined
                        ? originalObject.guideline_next_questions.Korean[2]
                        : "",
                // Vietnamese: originalObject.guideline_next_questions.Vietnamese !== undefined ? originalObject.guideline_next_questions.Vietnamese[2] : ""
            },
            type: originalObject.type || "",
            category_id: originalObject.category_id || "",
            number_used: originalObject.number_used || "",
            system_prompt: originalObject.system_prompt || "",
            skills: skillsStructure,
            image_url_primary_avatar:
                originalObject.image_url_primary_avatar || "",
            image_url_primary_detail:
                originalObject.image_url_primary_detail || "",
            image_url_secondary_avatar:
                originalObject.image_url_secondary_avatar || "",
            image_url_secondary_detail:
                originalObject.image_url_secondary_detail || "",
            image_url_high_avatar: originalObject.image_url_high_avatar || "",
            image_url_high_detail: originalObject.image_url_high_detail || "",
            status: originalObject.status || "",
            video_call_configs:
                originalObject.video_call_configs ||
                PersonAisConstants.intVideoConfig,
            // video_call_configs: originalObject.video_call_configs || {},
        };
    }
}

export default new PersonAiServices();
