<script lang="ts" src="./header.ts"></script>
<style scoped lang="scss" src="./header.scss"></style>

<template>
  <SideBarMobile
    v-if="userData"
    :userData="userData"
    :triggerSignal="isSidebarOpen"
  />
  <div
    class="d-flex flex-row justify-content-between align-items-center"
    style="width: 100%"
  >
    <div class="header-information">
      <button
        v-if="deviceType !== 'desktop'"
        class="header-menu-button"
        @click="toggleSidebarMobile"
      >
        <MenuIcon />
      </button>
      <p class="header-title">
        {{ formatHeader(headerName) }}
      </p>
      <img
        class="welcome-img"
        v-if="routeName === 'dashboard'"
        src="@/assets/images/icons/png/hello.png"
      />
    </div>
    <div class="d-flex flex-row align-items-center gap-3">
      <Notification :role="role" />
      <img
        :src="
          userData?.image_url
            ? userData?.image_url
            : require('@/assets/images/pictures/jpg/incognito-avatar.jpg')
        "
        style="
          width: 48px;
          height: 48px;
          border-radius: 100%;
          object-fit: cover;
          cursor: pointer;
        "
        @click="handleProfile"
      />
    </div>
  </div>
</template>
