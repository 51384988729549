<template>
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.9245 11.443C24.2995 11.0681 24.8082 10.8574 25.3385 10.8574C25.8688 10.8574 26.3774 11.0681 26.7525 11.443L28.1665 12.857C28.3523 13.0427 28.4997 13.2632 28.6002 13.5059C28.7008 13.7486 28.7526 14.0088 28.7526 14.2715C28.7526 14.5342 28.7008 14.7943 28.6002 15.037C28.4997 15.2798 28.3523 15.5003 28.1665 15.686L19.8375 24.015C19.4186 24.4336 18.8852 24.719 18.3045 24.835L15.3635 25.424C15.202 25.4564 15.035 25.4483 14.8774 25.4006C14.7198 25.3529 14.5763 25.2671 14.4599 25.1506C14.3434 25.0341 14.2575 24.8907 14.2098 24.7331C14.1621 24.5755 14.1541 24.4085 14.1865 24.247L14.7745 21.305C14.8905 20.7243 15.1758 20.1909 15.5945 19.772L23.9245 11.443ZM26.0455 14.979L26.7525 14.272L25.3385 12.857L24.6315 13.564L26.0455 14.979ZM23.2175 14.979L17.0095 21.186C16.8697 21.3255 16.7744 21.5033 16.7355 21.697L16.4415 23.168L17.9125 22.874C18.106 22.8354 18.2838 22.7404 18.4235 22.601L24.6315 16.393L23.2175 14.979Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.6665 27.943C11.6665 27.6778 11.7719 27.4234 11.9594 27.2359C12.1469 27.0483 12.4013 26.943 12.6665 26.943H26.6665C26.9317 26.943 27.1861 27.0483 27.3736 27.2359C27.5611 27.4234 27.6665 27.6778 27.6665 27.943C27.6665 28.2082 27.5611 28.4626 27.3736 28.6501C27.1861 28.8376 26.9317 28.943 26.6665 28.943H12.6665C12.4013 28.943 12.1469 28.8376 11.9594 28.6501C11.7719 28.4626 11.6665 28.2082 11.6665 27.943Z"
            fill="currentColor"
        />
    </svg>
</template>
