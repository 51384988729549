// @ts-nocheck
import { MutationTypesPersonAi } from "./mutation-style"
export default {
    [MutationTypesPersonAi.SET_EDIT_PERSON_AI_ID](state, newValue) {
        state.personAiEditId = newValue
    },
    [MutationTypesPersonAi.SET_EDIT_TOPIC_VIDEO_CALL](state, newValue) {
        return
    },
    [MutationTypesPersonAi.SAVE_SCENARIO](state, newValue) {
        state.topicIdSaveScenario = newValue
    },
    [MutationTypesPersonAi.SET_TOPIC_DATA_SELECTED](state, newValue) {
        state.topicDataSelected = newValue
    },
    [MutationTypesPersonAi.SET_READY_TO_SAVE](state, newValue) {
        state.topicDataSelected = newValue
    },
    
}