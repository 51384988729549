import { TransformedActionTypesPersonAi } from "@/store/modules/person-ai/action-style";

export class ApiRequest {
    private apiService: any;
    constructor(apiService: any) {
        this.apiService = apiService;
    }

    //Get practice exercise
    public async apiRequestUpdateTopicForScenario(payload: {
        scenario_id: number;
        topic_id: number;
    }): Promise<number | null> {
        return new Promise(async (r) => {
            await this.apiService.handleApiRequest(
                TransformedActionTypesPersonAi.UPDATE_VIDEO_CALL_SCENARIO_OPTION_2_V2,
                payload,
                (data: any) => {
                    r(payload.topic_id);
                },
                (err: any, statusCode: any) => {
                    r(null);
                }
            );
        });
    }
}
