// @ts-nocheck
import { MutationTypesTable } from "./mutation-style"
import defaultState from './state'
export default {
    [MutationTypesTable.ADD_SELECTED_ROW](state, newValue) {
        const newItem = state.tableData.data[newValue];
        // Check if newItem is not already in selectedRow
        if (!state.selectedRow.includes(newItem)) {
            state.selectedRow.push(newItem);
        }
    },
    [MutationTypesTable.REMOVE_SELECTED_ROW](state, newValue) {
        state.selectedRow = state.selectedRow.filter((item) => item.id !== state.tableData.data[newValue].id)
    },
    [MutationTypesTable.RESET_SELECTED_ROW](state) {
        state.selectedRow = []
    },
    [MutationTypesTable.SELECT_All_ROW](state, newValue) {
        state.selectedRow = newValue
    },
    [MutationTypesTable.SET_TABLE_DATA](state, newValue) {
        state.tableData = newValue
    },
    [MutationTypesTable.QUERY_TABLE_DATA](state, newValue) {
        state.payloadQuery = { ...state.payloadQuery, ...newValue }
    },
    [MutationTypesTable.SET_PAYLOAD_EDIT](state, newValue) {
        state.payloadEdit = {...newValue}
    },
    [MutationTypesTable.SET_ALLOW_EDIT_LIST](state, newValue) {
        state.allowEditList = newValue
    },
    [MutationTypesTable.SET_ADD_ACTION](state, newValue) {
        state.addAction = newValue
    },
    [MutationTypesTable.SET_ALLOW_SHOW_HEADER](state, newValue) {
        state.allowShowHeader = newValue
    },
    [MutationTypesTable.SET_LIST_IDS_DELETE](state, newValue) {
        state.listIdsDelete = newValue
    },
    [MutationTypesTable.SET_HEADER_ARRAY](state, newValue) {
        state.headerArray = newValue
    },





    [MutationTypesTable.RESET_STATE](state) {
        state.payloadQuery = {
            page: 1,
            per_page: 10,
        }
        state.selectedRow = []
        // Object.assign(state, defaultState)
    },
    
}
