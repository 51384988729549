<script lang="ts" setup>
import { defineProps } from "vue";

interface Props {
  id: number;
  title: string;
  image: string | null;
  isActive: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  id: 0,
  title: "",
  image: "",
  isActive: false,
});
const emits = defineEmits(["on-select"]);

function onSelect() {
  emits("on-select", props.id);
}
</script>

<template>
  <div class="childTab" :class="{ childActived: isActive }" @click="onSelect">
    <div class="childTab-img">
      <img
        :src="
          image ? image : require(`@/assets/images/icons/svg/defaultAvatar.svg`)
        "
        alt="User Avatar"
      />
    </div>
    <p class="childTab-title">{{ title }}</p>
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";
.childTab {
  @include flexbox(row, flex-start, center, null, 1, 8px);
  padding: 8px;
  box-shadow: 4px 8px 32px 0px #00000014;
  border-radius: 30px;
  background-color: $neutral-900;
  width: 100%;
  color: $neutral-400;
  cursor: pointer;
  &.childActived {
    background-color: $primary-700;
    color: $neutral-900 !important;
  }

  &-img {
    @include avatar(40px, 40px);
  }
  &-title {
    @include typography(16px, 600, 1.5, normal, inherit);
    @include text-truncate;
    @include responsive-for(mobile) {
      display: none;
    }
  }
  @include responsive-for(mobile) {
    @include flexbox(row, center, center);
  }
}
</style>
