// @ts-nocheck
import { GetterTypesTable } from "./getter-style.ts"
export default {
    [GetterTypesTable.GET_SELECTED_ROW](state){
        return state.selectedRow
    },
    [GetterTypesTable.GET_TABLE_DATA](state){
        return state.tableData
    },
    [GetterTypesTable.GET_PAYLOAD_QUERY](state){
        return state.payloadQuery
    },
    [GetterTypesTable.GET_ALLOW_EDIT_LIST](state){
        return state.allowEditList
    },
    [GetterTypesTable.GET_ALLOW_SHOW_HEADER](state){
        return state.allowShowHeader
    },
    [GetterTypesTable.GET_HEADER_ARRAY](state){
        return state.headerArray
    },

}