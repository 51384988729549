<template>
  <div class="side-menu-left" :class="!isShowLessMenu ? 'active' : 'hidden'">
    <slot> </slot>
    <div
      class="icon-toggle-hidden-menu"
      data-bs-placement="right"
      data-bs-toggle="tooltip"
      data-bs-title="Show less menu"
      @click="toggleShowLessMenu"
    >
      <ArrowDownIcon class="icon" />
    </div>
  </div>
  <div
    class="icon-toggle-show-menu"
    data-bs-placement="right"
    :style="!isShowLessMenu ? 'left: -50%' : ''"
    data-bs-toggle="tooltip"
    data-bs-title="Show more menu"
    @click="toggleShowLessMenu"
  >
    <ArrowDownIcon class="icon" />
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { ArrowDownIcon } from "@/assets/images/icons/components";
import "./menu-less.css";

const isShowLessMenu = ref(false);

function toggleShowLessMenu() {
  isShowLessMenu.value = !isShowLessMenu.value;
}
</script>
