<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.7955 5.54335C20.9514 5.77853 21.0892 6.03377 21.2106 6.31051C21.3213 6.56299 21.4184 6.83337 21.5029 7.12274C21.8668 8.36763 22 9.96412 22 12C22 19.4115 20.235 21 12 21C3.765 21 2 19.4115 2 12C2 9.97611 2.13162 8.38644 2.49065 7.14476C2.58094 6.83254 2.6856 6.54231 2.80616 6.27273C2.92158 6.01463 3.05159 5.77544 3.1975 5.55396C4.51267 3.55769 7.12058 3 12 3C16.8708 3 19.4781 3.55571 20.7955 5.54335ZM4.38843 6.75595C4.75941 6.03241 5.31481 5.5571 6.32401 5.21865C7.5303 4.8141 9.30997 4.63636 12 4.63636C14.0255 4.63636 15.5519 4.73582 16.7157 4.96859C17.8697 5.1994 18.5516 5.53934 18.9935 5.93699C19.2237 6.14418 19.4275 6.4026 19.603 6.73906C19.1022 7.24023 18.4323 7.8706 17.6586 8.49928C16.8053 9.19258 15.8416 9.87088 14.852 10.3732C13.8565 10.8785 12.8838 11.1807 11.9989 11.1818C11.1122 11.183 10.1371 10.8822 9.13882 10.3773C8.1465 9.87552 7.1798 9.1969 6.32379 8.50291C5.55559 7.8801 4.8893 7.25537 4.38843 6.75595ZM3.85789 8.5191C4.27724 8.91252 4.76154 9.34293 5.29327 9.77401C6.2042 10.5125 7.27021 11.2661 8.40037 11.8376C9.52457 12.4061 10.7617 12.8198 12.0011 12.8182C13.2377 12.8166 14.4717 12.4014 15.5927 11.8323C16.7197 11.2603 17.7824 10.5071 18.6905 9.76926C19.2276 9.33284 19.7161 8.89724 20.1378 8.50013C20.286 9.38003 20.3636 10.5161 20.3636 12C20.3636 15.7459 19.8691 17.275 18.9935 18.063C18.5516 18.4607 17.8697 18.8006 16.7157 19.0314C15.5519 19.2642 14.0255 19.3636 12 19.3636C9.9745 19.3636 8.44806 19.2642 7.2843 19.0314C6.13033 18.8006 5.44838 18.4607 5.00654 18.063C4.13093 17.275 3.63636 15.7459 3.63636 12C3.63636 10.5435 3.70904 9.40991 3.85789 8.5191Z"
      fill="currentColor"
    />
  </svg>
</template>
