import SideBar from "@/components/layout/side-bar/side-bar.vue";
import Header from "@/components/layout/header/header.vue";
import { Vue, Options } from "vue-class-component";
import GetterTypes from "@/store/getter-types";
import LayoutMainDashboard from "@/components/layout/layoutMainDashboard/index.vue";
import { ModalCategory, ModalTopicVideoCall } from "@/components/base/modals";
import { TransformedActionTypesPersonAi } from "@/store/modules/person-ai/action-style";

@Options({
    components: {
        SideBar,
        Header,
        LayoutMainDashboard,
        ModalCategory,
        ModalTopicVideoCall,
    },
    props: {},
    watch: {
        "$store.state.userData": {
            handler(newVal, oldVal) {
                this.userData = newVal;
            },
            deep: true,
        },
    },
    provide() {
        return {
            handleOpenModalCategory: this.handleOpenModalCategory,
            handleOpenModalTopic: this.handleOpenModalTopic,
        };
    },
})
export default class MainLayout extends Vue {
    public routeName: any = null;
    public userData: any = null;
    public categories: any = [];
    public topics: any = [];
    public beforeMount() {
        this.apiGetCategoryList();
    }
    mounted(): void {
        this.userData = this.$store.getters[GetterTypes.GET_USER_DATA];
    }

    public async handleOpenModalCategory() {
        (this.$refs["modal-category"] as any).openModal();
    }
    public async handleOpenModalTopic() {
        (this.$refs["modal-topic"] as any).openModal();
    }

    public apiGetCategoryList() {
        console.log("apiGetCategoryList");
        this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.GET_CATEGORY_LIST,
            {},
            (data: any) => {
                for (let item of data) {
                    this.categories.push({
                        text: item.name,
                        value: item.id,
                        active: true,
                    });
                }
            }
        );
    }
}
