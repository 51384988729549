import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/icons/png/hello.png'


const _withScopeId = n => (_pushScopeId("data-v-61c525ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "d-flex flex-row justify-content-between align-items-center",
  style: {"width":"100%"}
}
const _hoisted_2 = { class: "header-information" }
const _hoisted_3 = { class: "header-title" }
const _hoisted_4 = {
  key: 1,
  class: "welcome-img",
  src: _imports_0
}
const _hoisted_5 = { class: "d-flex flex-row align-items-center gap-3" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBarMobile = _resolveComponent("SideBarMobile")!
  const _component_MenuIcon = _resolveComponent("MenuIcon")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.userData)
      ? (_openBlock(), _createBlock(_component_SideBarMobile, {
          key: 0,
          userData: _ctx.userData,
          triggerSignal: _ctx.isSidebarOpen
        }, null, 8, ["userData", "triggerSignal"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.deviceType !== 'desktop')
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "header-menu-button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebarMobile && _ctx.toggleSidebarMobile(...args)))
            }, [
              _createVNode(_component_MenuIcon)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.formatHeader(_ctx.headerName)), 1),
        (_ctx.routeName === 'dashboard')
          ? (_openBlock(), _createElementBlock("img", _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Notification, { role: _ctx.role }, null, 8, ["role"]),
        _createElementVNode("img", {
          src: 
          _ctx.userData?.image_url
            ? _ctx.userData?.image_url
            : require('@/assets/images/pictures/jpg/incognito-avatar.jpg')
        ,
          style: {"width":"48px","height":"48px","border-radius":"100%","object-fit":"cover","cursor":"pointer"},
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleProfile && _ctx.handleProfile(...args)))
        }, null, 8, _hoisted_6)
      ])
    ])
  ], 64))
}