import { Vue, Options } from "vue-class-component";
import { Modal } from "bootstrap";

@Options({
    props: {
        title: String,
        description: String,
        btnAction: String,
        btnCancel: String,
        isDanger: Boolean,
        disabledClickOutside: Boolean,
    },
})
export default class ModalBase extends Vue {
    public title!: any;
    public description!: any;
    public btnAction!: any;
    public btnCancel!: any;
    public isDanger!: any;
    public disabledClickOutside: boolean = false;

    public mounted() {
        const modal = document.getElementById("modal-base");
        document.getElementsByTagName("body")[0].appendChild(modal as Node);
    }

    public openModal() {
        const options: any = this.disabledClickOutside
            ? { backdrop: "static", keyboard: false }
            : {};
        const myModal: any = new Modal(
            this.$refs["modal-base"] as any,
            options
        );
        myModal.show();
        myModal._element.addEventListener("hidden.bs.modal", () => {
            // this.handleClickCancel();
        });
    }

    public handleClickBtnAction() {
        this.$emit("handleClickBtnAction");
    }
    public handleClickCancel() {
        this.$emit("handleClickCancel");
    }
}
