import {
    AutoIcon,
    WebsiteOutlineIcon,
    ExclamationMarkIcon,
} from "@/assets/images/icons/components";
import {
    AccordingPanel,
    MenuLanguagesSelection,
    Spinner,
    DropdownSelect,
} from "@/components/base";
import { languageAvailable, languageAvailableOptions } from "@/constants";
import { TLanguages } from "@/interfaces/person-ai";
import ApiService from "@/services/api-service";
import store from "@/store";
import ActionTypes from "@/store/action-types";
import { computed, onMounted, ref, watch } from "vue";
import { toast } from "vue3-toastify";
import { fieldsVocabularyItem } from "../utils/constants";
import { IVocabulary, IVocabularyItem } from "../utils/type";
import Vocabulary from "../utils/vocabulary";
import VocabularyItem from "./vocabulary-item.vue";
import { vocabularyMapping } from "../utils/validate";
export default {
    props: {
        enabled: {
            type: Boolean,
            default: false,
        },
        scenarioData: [Object, String],
    },
    emits: ["on-update-vocabulary", "on-save-vocabulary"],

    setup(props: any, { emit }: any) {
        interface IAutoGeneratePayload {
            context: string;
            ai_role: string;
            user_role: string;
            level: string;
            language: string;
        }

        interface ISelectOptions {
            languages: TLanguages[];
            languagesDropdown: {
                text: TLanguages | "All";
                value: TLanguages | null;
                active: boolean;
            }[];
        }
        const fields = {
            id: "id",
            word: "Word",
            type: "Type",
            word_phonics_pronounce: "WVP for phonics",
            word_variant_pronounce: "Word variant pronounce (WVP)",
            example_variant_pronounce: "Sentence variant",
            example_phonics_pronounce: "Sentence variant",
            example_sentence: "Example sentence",
        };

        const apiService = new ApiService(store);
        const languageSelected = ref<TLanguages>("English");
        const messageValid = ref<string | null>();
        const refVocabulary = ref<IVocabulary | null>(
            Vocabulary.initVocabulary()
        );
        const languageSelectedForAutoGenerate = ref<string | null>(null);
        const isUpdate = ref(false);
        const languageGeneratedFail = ref<TLanguages[]>([]);
        const loading = ref({
            autoGenerate: false,
            getVocabulary: false,
        });
        const selectOptions = ref<ISelectOptions>({
            languages: [
                "English",
                "German",
                "Spanish",
                "Polish",
                "Italian",
                "French",
                "Portuguese",
                "Hindi",
                "Korean",
                "Japanese",
            ],
            languagesDropdown: [
                { text: "All", value: null, active: true },
                ...languageAvailableOptions,
            ],
        });

        function onChangeSelectLanguageAutoGenerate(value: string) {
            languageSelectedForAutoGenerate.value = value;
        }

        async function handleAutoGenerate() {
            const languages: any = languageSelectedForAutoGenerate.value
                ? [languageSelectedForAutoGenerate.value]
                : languageAvailable;
            queueAutoGenerateVocabulary(languages).catch((error) => {});
        }

        async function handleAutoGenerateForFail() {
            queueAutoGenerateVocabulary(languageGeneratedFail.value).catch(
                (error) => {}
            );
        }

        async function handleSaveVocabulary() {
            if (messageValid.value) return toast.error(messageValid.value);

            loading.value.getVocabulary = true;
            emit("on-save-vocabulary", true);
            let result;
            if (isUpdate.value) {
                result = await apiUpdateGenerateVocabulary(refVocabulary.value);
            } else {
                result = await apiCreateGenerateVocabulary({
                    scenario_id: props.scenarioData.id,
                    data: refVocabulary.value,
                });
            }

            loading.value.getVocabulary = false;
            emit("on-save-vocabulary", false);

            if (result?.message) {
                toast.success(result.message);
            }
        }

        function onUpdateVocabulary(voca: IVocabularyItem) {
            emit("on-update-vocabulary");
        }

        const checkListLanguage = computed<string[]>(() => {
            messageValid.value = null;
            if (!refVocabulary.value) return [];

            return Object.entries(refVocabulary.value).reduce(
                (listReturn, [l, v]) => {
                    const isValid = v.every((entry: any, i: any) => {
                        return Object.entries(entry).every(([key, value]) => {
                            if (!value && fieldsVocabularyItem.includes(key)) {
                                if (!messageValid.value) {
                                    messageValid.value = `The "${
                                        // @ts-ignore
                                        fields[key]
                                    }" at vocabulary ${
                                        i + 1
                                    } of "${l}" is not empty!`;
                                }
                                return false;
                            }
                            return true;
                        });
                    });
                    // @ts-ignore
                    if (isValid) listReturn.push(l);
                    return listReturn;
                },
                []
            );
        });

        async function getVocabulary() {
            if (
                props.scenarioData?.id &&
                typeof props.scenarioData.id === "number"
            ) {
                loading.value.getVocabulary = true;
                const result = await apiGetVocabulary({
                    scenario_id: props.scenarioData.id,
                });
                if (result && result["English"]?.length > 0) {
                    refVocabulary.value = result;
                    isUpdate.value = true;
                }
                loading.value.getVocabulary = false;
            }
        }

        async function apiGetVocabulary(payload: any) {
            return new Promise<any>(async (r) => {
                // setTimeout(() => {
                //     r(getVocabularyNull);
                // }, 200000);
                await apiService.handleApiRequest(
                    ActionTypes.GET_VOCABULARY_SCENARIO_LANGUAGE,
                    payload,
                    (data: any) => {
                        r(data || null);
                    },
                    () => {
                        r(null);
                    }
                );
            });
        }

        async function queueAutoGenerateVocabulary(
            languages: TLanguages[]
        ): Promise<void> {
            languageGeneratedFail.value = [];
            loading.value.autoGenerate = true;
            emit("on-save-vocabulary", true);
            const resultData: any = {};
            let curLan: any = null;
            async function* fetchGenerator() {
                for (const l of languages) {
                    curLan = l;
                    const data: any = await apiAutoGenerateVocabulary({
                        context: props.scenarioData.context,
                        ai_role: props.scenarioData.ai_role,
                        user_role: props.scenarioData.user_role,
                        level: props.scenarioData.level,
                        language: l,
                    });
                    yield data;
                }
            }

            const generator = fetchGenerator();
            for await (const data of generator) {
                if (data) {
                    // console.log("🚀 ~ forawait ~ data:", data);
                    //@ts-ignore
                    //   refVocabulary.value[curLan] = data[curLan];
                    // const voceMapped = vocabularyMapping(data[curLan]);
                    const vocasMapped: any = [];
                    if (isUpdate.value) {
                        for (let i = 0; i < data[curLan].length; i++) {
                            const voceMapped = vocabularyMapping({
                                // @ts-ignore
                                id: refVocabulary.value[curLan][i].id,
                                ...data[curLan][i],
                            });
                            vocasMapped.push(voceMapped);
                        }
                    } else {
                        //@ts-ignore
                        data[curLan].forEach((v: any) => {
                            const voceMapped = vocabularyMapping(v);
                            vocasMapped.push(voceMapped);
                        });
                    }

                    // console.log("🚀 ~ forawait ~ vocasMapped:", vocasMapped);
                    // console.log("🚀 ~ forawait ~ voceMapped:", voceMapped);
                    resultData[curLan] = vocasMapped;
                } else {
                    languageGeneratedFail.value.push(curLan);
                }
                curLan = null;
            }
            const finalValue = generator.next();
            finalValue.finally(() => {
                emit("on-save-vocabulary", false);
                loading.value.autoGenerate = false;
                //@ts-ignore
                refVocabulary.value = {
                    ...refVocabulary.value,
                    ...resultData,
                };
            });
        }
        async function apiAutoGenerateVocabulary(
            payload: IAutoGeneratePayload
        ) {
            return new Promise<any>(async (r) => {
                // setTimeout(() => {
                //     // if (
                //     //     (payload.language === "Hindi" ||
                //     //         payload.language === "German" ||
                //     //         payload.language === "Italian") &&
                //     //     languageGeneratedFail.value.length < 3
                //     // ) {
                //     //     r(null);
                //     // } else {
                //     //     r(dataAutoGeneration[payload.language as TLanguages]);
                //     // }
                //     r(dataAutoGeneration[payload.language as TLanguages]);
                // }, 1200);

                await apiService.handleApiRequest(
                    ActionTypes.GENERATE_VOCABULARY_SCENARIO_LANGUAGE,
                    payload,
                    (data: any) => {
                        r(data || null);
                    },
                    () => {
                        r(null);
                    }
                );
            });
        }

        async function apiCreateGenerateVocabulary(payload: any) {
            return new Promise<any>(async (r) => {
                //   setTimeout(() => {
                //       r(dataAutoGeneration[payload.language as TLanguages]);
                //   }, 300);

                await apiService.handleApiRequest(
                    ActionTypes.POST_VOCABULARY_SCENARIO_LANGUAGE,
                    payload,
                    (data: any) => {
                        r(data || null);
                    },
                    () => {
                        r(null);
                    }
                );
            });
        }
        async function apiUpdateGenerateVocabulary(payload: any) {
            return new Promise<any>(async (r) => {
                //   setTimeout(() => {
                //       r(dataAutoGeneration[payload.language as TLanguages]);
                //   }, 300);

                await apiService.handleApiRequest(
                    ActionTypes.PUT_VOCABULARY_SCENARIO_LANGUAGE,
                    payload,
                    (data: any) => {
                        r(data || null);
                    },
                    () => {
                        r(null);
                    }
                );
            });
        }

        function handleSelectLanguage(title: TLanguages) {
            languageSelected.value = title;
        }

        watch(
            () => props.scenarioData,
            (old, ne) => {
                if (old.id !== ne.id) getVocabulary();
            },
            { deep: true }
        );

        onMounted(() => {
            getVocabulary();
        });

        return {
            fields,
            refVocabulary,
            languageSelected,
            messageValid,
            loading,
            selectOptions,
            checkListLanguage,
            languageGeneratedFail,
            languageAvailable,
            handleAutoGenerate,
            handleAutoGenerateForFail,
            handleSaveVocabulary,
            handleSelectLanguage,
            getVocabulary,
            apiAutoGenerateVocabulary,
            apiGetVocabulary,
            onUpdateVocabulary,
            onChangeSelectLanguageAutoGenerate,
        };
    },
    components: {
        AutoIcon,
        MenuLanguagesSelection,
        Spinner,
        VocabularyItem,
        AccordingPanel,
        DropdownSelect,
        WebsiteOutlineIcon,
        ExclamationMarkIcon,
    },
};
