export const AllCountries = {
    Africa: [
        { code: "AO", code_639: "pt-AO", timezone: "Africa/Luanda", name: "Angola" },
        { code: "BJ", code_639: "fr-BJ", timezone: "Africa/Porto-Novo", name: "Benin" },
        { code: "BW", code_639: "en-BW", timezone: "Africa/Gaborone", name: "Botswana" },
        { code: "BF", code_639: "fr-BF", timezone: "Africa/Ouagadougou", name: "Burkina Faso" },
        { code: "BI", code_639: "fr-BI", timezone: "Africa/Bujumbura", name: "Burundi" },
        { code: "CM", code_639: "fr-CM", timezone: "Africa/Douala", name: "Cameroon" },
        { code: "CV", code_639: "pt-CV", timezone: "Atlantic/Cape_Verde", name: "Cape Verde" },
        { code: "CF", code_639: "fr-CF", timezone: "Africa/Bangui", name: "Central African Republic" },
        { code: "TD", code_639: "fr-TD", timezone: "Africa/Ndjamena", name: "Chad" },
        { code: "KM", code_639: "ar-KM", timezone: "Indian/Comoro", name: "Comoros" },
        { code: "CG", code_639: "fr-CG", timezone: "Africa/Brazzaville", name: "Congo" },
        { code: "CD", code_639: "fr-CD", timezone: "Africa/Kinshasa", name: "Democratic Republic of the Congo" },
        { code: "DJ", code_639: "ar-DJ", timezone: "Africa/Djibouti", name: "Djibouti" },
        { code: "GQ", code_639: "es-GQ", timezone: "Africa/Malabo", name: "Equatorial Guinea" },
        { code: "ER", code_639: "ti-ER", timezone: "Africa/Asmara", name: "Eritrea" },
        { code: "ET", code_639: "am-ET", timezone: "Africa/Addis_Ababa", name: "Ethiopia" },
        { code: "GA", code_639: "fr-GA", timezone: "Africa/Libreville", name: "Gabon" },
        { code: "GM", code_639: "en-GM", timezone: "Africa/Banjul", name: "Gambia" },
        { code: "GH", code_639: "en-GH", timezone: "Africa/Accra", name: "Ghana" },
        { code: "GN", code_639: "fr-GN", timezone: "Africa/Conakry", name: "Guinea" },
        { code: "GW", code_639: "pt-GW", timezone: "Africa/Bissau", name: "Guinea-Bissau" },
        { code: "KE", code_639: "sw-KE", timezone: "Africa/Nairobi", name: "Kenya" },
        { code: "LS", code_639: "en-LS", timezone: "Africa/Maseru", name: "Lesotho" },
        { code: "LR", code_639: "en-LR", timezone: "Africa/Monrovia", name: "Liberia" },
        { code: "MG", code_639: "mg-MG", timezone: "Indian/Antananarivo", name: "Madagascar" },
        { code: "MW", code_639: "en-MW", timezone: "Africa/Blantyre", name: "Malawi" },
        { code: "ML", code_639: "fr-ML", timezone: "Africa/Bamako", name: "Mali" },
        { code: "MR", code_639: "ar-MR", timezone: "Africa/Nouakchott", name: "Mauritania" },
        { code: "MU", code_639: "en-MU", timezone: "Indian/Mauritius", name: "Mauritius" },
        { code: "MZ", code_639: "pt-MZ", timezone: "Africa/Maputo", name: "Mozambique" },
        { code: "NA", code_639: "en-NA", timezone: "Africa/Windhoek", name: "Namibia" },
        { code: "NE", code_639: "fr-NE", timezone: "Africa/Niamey", name: "Niger" },
        { code: "NG", code_639: "en-NG", timezone: "Africa/Lagos", name: "Nigeria" },
        { code: "RW", code_639: "rw-RW", timezone: "Africa/Kigali", name: "Rwanda" },
        { code: "ST", code_639: "pt-ST", timezone: "Africa/Sao_Tome", name: "Sao Tome and Principe" },
        { code: "SN", code_639: "fr-SN", timezone: "Africa/Dakar", name: "Senegal" },
        { code: "SC", code_639: "en-SC", timezone: "Indian/Mahe", name: "Seychelles" },
        { code: "SL", code_639: "en-SL", timezone: "Africa/Freetown", name: "Sierra Leone" },
        { code: "SO", code_639: "so-SO", timezone: "Africa/Mogadishu", name: "Somalia" },
        { code: "ZA", code_639: "en-ZA", timezone: "Africa/Johannesburg", name: "South Africa" },
        { code: "SS", code_639: "en-SS", timezone: "Africa/Juba", name: "South Sudan" },
        { code: "SD", code_639: "ar-SD", timezone: "Africa/Khartoum", name: "Sudan" },
        { code: "SZ", code_639: "en-SZ", timezone: "Africa/Mbabane", name: "Swaziland" },
        { code: "TZ", code_639: "sw-TZ", timezone: "Africa/Dar_es_Salaam", name: "Tanzania" },
        { code: "TG", code_639: "fr-TG", timezone: "Africa/Lome", name: "Togo" },
        { code: "UG", code_639: "en-UG", timezone: "Africa/Kampala", name: "Uganda" },
        { code: "ZM", code_639: "en-ZM", timezone: "Africa/Lusaka", name: "Zambia" },
        { code: "ZW", code_639: "en-ZW", timezone: "Africa/Harare", name: "Zimbabwe" }
    ],

    "East Asia": [
        {
            code: "CN",
            code_639: "zh-CN",
            timezone: "Asia/Shanghai",
            name: "China",
        },
        {
            code: "JP",
            code_639: "ja-JP",
            timezone: "Asia/Tokyo",
            name: "Japan",
        },
        {
            code: "KR",
            code_639: "ko-KR",
            timezone: "Asia/Seoul",
            name: "South Korea",
        },
        {
            code: "KP",
            code_639: "ko-KP",
            timezone: "Asia/Pyongyang",
            name: "North Korea",
        },
        {
            code: "MN",
            code_639: "mn-MN",
            timezone: "Asia/Ulaanbaatar",
            name: "Mongolia",
        },
        {
            code: "TW",
            code_639: "zh-TW",
            timezone: "Asia/Taipei",
            name: "Taiwan",
        },
    ],

    "West Europe": [
        {
            code: "AT",
            code_639: "de-AT",
            timezone: "Europe/Vienna",
            name: "Austria",
        },
        {
            code: "BE",
            code_639: "nl-BE",
            timezone: "Europe/Brussels",
            name: "Belgium",
        },
        {
            code: "FR",
            code_639: "fr-FR",
            timezone: "Europe/Paris",
            name: "France",
        },
        {
            code: "DE",
            code_639: "de-DE",
            timezone: "Europe/Berlin",
            name: "Germany",
        },
        {
            code: "IE",
            code_639: "en-IE",
            timezone: "Europe/Dublin",
            name: "Ireland",
        },
        {
            code: "LU",
            code_639: "lb-LU",
            timezone: "Europe/Luxembourg",
            name: "Luxembourg",
        },
        {
            code: "MC",
            code_639: "fr-MC",
            timezone: "Europe/Monaco",
            name: "Monaco",
        },
        {
            code: "NL",
            code_639: "nl-NL",
            timezone: "Europe/Amsterdam",
            name: "Netherlands",
        },
        {
            code: "PT",
            code_639: "pt-PT",
            timezone: "Europe/Lisbon",
            name: "Portugal",
        },
        {
            code: "CH",
            code_639: "de-CH",
            timezone: "Europe/Zurich",
            name: "Switzerland",
        },
        {
            code: "GB",
            code_639: "en-GB",
            timezone: "Europe/London",
            name: "United Kingdom",
        },
        {
            code: "IT",
            code_639: "it-IT",
            timezone: "Europe/Rome",
            name: "Italy"
        },
        {
            code: "GR",
            code_639: "el-GR",
            timezone: "Europe/Athens",
            name: "Greece"
        },
        {
            code: "MT",
            code_639: "mt-MT",
            timezone: "Europe/Valletta",
            name: "Malta"
        },
        {
            code: "ES",
            code_639: "es-ES",
            timezone: "Europe/Madrid",
            name: "Spain"
        }
    ],

    "North America": [
        {
            code: "AG",
            code_639: "en-AG",
            timezone: "America/Antigua",
            name: "Antigua and Barbuda",
        },
        {
            code: "BS",
            code_639: "en-BS",
            timezone: "America/Nassau",
            name: "Bahamas",
        },
        {
            code: "BB",
            code_639: "en-BB",
            timezone: "America/Barbados",
            name: "Barbados",
        },
        {
            code: "BZ",
            code_639: "en-BZ",
            timezone: "America/Belize",
            name: "Belize",
        },
        {
            code: "CA",
            code_639: "en-CA",
            timezone: "America/Toronto",
            name: "Canada",
        },
        {
            code: "CR",
            code_639: "es-CR",
            timezone: "America/Costa_Rica",
            name: "Costa Rica",
        },
        {
            code: "CU",
            code_639: "es-CU",
            timezone: "America/Havana",
            name: "Cuba",
        },
        {
            code: "DM",
            code_639: "en-DM",
            timezone: "America/Dominica",
            name: "Dominica",
        },
        {
            code: "DO",
            code_639: "es-DO",
            timezone: "America/Santo_Domingo",
            name: "Dominican Republic",
        },
        {
            code: "SV",
            code_639: "es-SV",
            timezone: "America/El_Salvador",
            name: "El Salvador",
        },
        {
            code: "GD",
            code_639: "en-GD",
            timezone: "America/Grenada",
            name: "Grenada",
        },
        {
            code: "GT",
            code_639: "es-GT",
            timezone: "America/Guatemala",
            name: "Guatemala",
        },
        {
            code: "HT",
            code_639: "fr-HT",
            timezone: "America/Port-au-Prince",
            name: "Haiti",
        },
        {
            code: "HN",
            code_639: "es-HN",
            timezone: "America/Tegucigalpa",
            name: "Honduras",
        },
        {
            code: "JM",
            code_639: "en-JM",
            timezone: "America/Jamaica",
            name: "Jamaica",
        },
        {
            code: "MX",
            code_639: "es-MX",
            timezone: "America/Mexico_City",
            name: "Mexico",
        },
        {
            code: "NI",
            code_639: "es-NI",
            timezone: "America/Managua",
            name: "Nicaragua",
        },
        {
            code: "PA",
            code_639: "es-PA",
            timezone: "America/Panama",
            name: "Panama",
        },
        {
            code: "KN",
            code_639: "en-KN",
            timezone: "America/St_Kitts",
            name: "Saint Kitts and Nevis",
        },
        {
            code: "LC",
            code_639: "en-LC",
            timezone: "America/St_Lucia",
            name: "Saint Lucia",
        },
        {
            code: "VC",
            code_639: "en-VC",
            timezone: "America/St_Vincent",
            name: "Saint Vincent and the Grenadines",
        },
        {
            code: "TT",
            code_639: "en-TT",
            timezone: "America/Port_of_Spain",
            name: "Trinidad and Tobago",
        },
        {
            code: "US",
            code_639: "en-US",
            timezone: "America/New_York",
            name: "United States",
        },
    ],

    "South America": [
        {
            code: "AR",
            code_639: "es-AR",
            timezone: "America/Argentina/Buenos_Aires",
            name: "Argentina",
        },
        {
            code: "BO",
            code_639: "es-BO",
            timezone: "America/La_Paz",
            name: "Bolivia",
        },
        {
            code: "BR",
            code_639: "pt-BR",
            timezone: "America/Sao_Paulo",
            name: "Brazil",
        },
        {
            code: "CL",
            code_639: "es-CL",
            timezone: "America/Santiago",
            name: "Chile",
        },
        {
            code: "CO",
            code_639: "es-CO",
            timezone: "America/Bogota",
            name: "Colombia",
        },
        {
            code: "EC",
            code_639: "es-EC",
            timezone: "America/Guayaquil",
            name: "Ecuador",
        },
        {
            code: "GY",
            code_639: "en-GY",
            timezone: "America/Guyana",
            name: "Guyana",
        },
        {
            code: "PY",
            code_639: "es-PY",
            timezone: "America/Asuncion",
            name: "Paraguay",
        },
        {
            code: "PE",
            code_639: "es-PE",
            timezone: "America/Lima",
            name: "Peru",
        },
        {
            code: "SR",
            code_639: "nl-SR",
            timezone: "America/Paramaribo",
            name: "Suriname",
        },
        {
            code: "UY",
            code_639: "es-UY",
            timezone: "America/Montevideo",
            name: "Uruguay",
        },
        {
            code: "VE",
            code_639: "es-VE",
            timezone: "America/Caracas",
            name: "Venezuela",
        },
    ],

    Oceania: [
        {
            code: "AU",
            code_639: "en-AU",
            timezone: "Australia/Sydney",
            name: "Australia",
        },
        {
            code: "FJ",
            code_639: "fj-FJ",
            timezone: "Pacific/Fiji",
            name: "Fiji",
        },
        {
            code: "KI",
            code_639: "en-KI",
            timezone: "Pacific/Tarawa",
            name: "Kiribati",
        },
        {
            code: "MH",
            code_639: "mh-MH",
            timezone: "Pacific/Majuro",
            name: "Marshall Islands",
        },
        {
            code: "FM",
            code_639: "en-FM",
            timezone: "Pacific/Pohnpei",
            name: "Micronesia",
        },
        {
            code: "NR",
            code_639: "na-NR",
            timezone: "Pacific/Nauru",
            name: "Nauru",
        },
        {
            code: "NZ",
            code_639: "en-NZ",
            timezone: "Pacific/Auckland",
            name: "New Zealand",
        },
        {
            code: "PW",
            code_639: "pau-PW",
            timezone: "Pacific/Palau",
            name: "Palau",
        },
        {
            code: "PG",
            code_639: "en-PG",
            timezone: "Pacific/Port_Moresby",
            name: "Papua New Guinea",
        },
        {
            code: "WS",
            code_639: "sm-WS",
            timezone: "Pacific/Apia",
            name: "Samoa",
        },
        {
            code: "SB",
            code_639: "en-SB",
            timezone: "Pacific/Guadalcanal",
            name: "Solomon Islands",
        },
        {
            code: "TO",
            code_639: "to-TO",
            timezone: "Pacific/Tongatapu",
            name: "Tonga",
        },
        {
            code: "TV",
            code_639: "tvl-TV",
            timezone: "Pacific/Funafuti",
            name: "Tuvalu",
        },
        {
            code: "VU",
            code_639: "bi-VU",
            timezone: "Pacific/Efate",
            name: "Vanuatu",
        },
    ],

    MENA: [
        {
            code: "DZ",
            code_639: "ar-DZ",
            timezone: "Africa/Algiers",
            name: "Algeria",
        },
        {
            code: "BH",
            code_639: "ar-BH",
            timezone: "Asia/Bahrain",
            name: "Bahrain",
        },
        {
            code: "EG",
            code_639: "ar-EG",
            timezone: "Africa/Cairo",
            name: "Egypt",
        },
        {
            code: "IQ",
            code_639: "ar-IQ",
            timezone: "Asia/Baghdad",
            name: "Iraq",
        },
        {
            code: "IR",
            code_639: "fa-IR",
            timezone: "Asia/Tehran",
            name: "Iran",
        },
        {
            code: "IL",
            code_639: "he-IL",
            timezone: "Asia/Jerusalem",
            name: "Israel",
        },
        {
            code: "JO",
            code_639: "ar-JO",
            timezone: "Asia/Amman",
            name: "Jordan",
        },
        {
            code: "KW",
            code_639: "ar-KW",
            timezone: "Asia/Kuwait",
            name: "Kuwait",
        },
        {
            code: "LB",
            code_639: "ar-LB",
            timezone: "Asia/Beirut",
            name: "Lebanon",
        },
        {
            code: "LY",
            code_639: "ar-LY",
            timezone: "Africa/Tripoli",
            name: "Libya",
        },
        {
            code: "MA",
            code_639: "ar-MA",
            timezone: "Africa/Casablanca",
            name: "Morocco",
        },
        {
            code: "OM",
            code_639: "ar-OM",
            timezone: "Asia/Muscat",
            name: "Oman",
        },
        {
            code: "PS",
            code_639: "ar-PS",
            timezone: "Asia/Gaza",
            name: "Palestine",
        },
        {
            code: "QA",
            code_639: "ar-QA",
            timezone: "Asia/Qatar",
            name: "Qatar",
        },
        {
            code: "SA",
            code_639: "ar-SA",
            timezone: "Asia/Riyadh",
            name: "Saudi Arabia",
        },
        {
            code: "SY",
            code_639: "ar-SY",
            timezone: "Asia/Damascus",
            name: "Syria",
        },
        {
            code: "TN",
            code_639: "ar-TN",
            timezone: "Africa/Tunis",
            name: "Tunisia",
        },
        {
            code: "AE",
            code_639: "ar-AE",
            timezone: "Asia/Dubai",
            name: "United Arab Emirates",
        },
        {
            code: "YE",
            code_639: "ar-YE",
            timezone: "Asia/Aden",
            name: "Yemen",
        },
    ],

    Scandinavia: [
        {
            code: "DK",
            code_639: "da-DK",
            timezone: "Europe/Copenhagen",
            name: "Denmark",
        },
        {
            code: "NO",
            code_639: "no-NO",
            timezone: "Europe/Oslo",
            name: "Norway",
        },
        {
            code: "SE",
            code_639: "sv-SE",
            timezone: "Europe/Stockholm",
            name: "Sweden",
        },
        {
            code: "FI",
            code_639: "fi-FI",
            timezone: "Europe/Helsinki",
            name: "Finland",
        },
        {
            code: "IS",
            code_639: "is-IS",
            timezone: "Atlantic/Reykjavik",
            name: "Iceland",
        },
    ],

    "East Europe": [
        {
            code: "BY",
            code_639: "be-BY",
            timezone: "Europe/Minsk",
            name: "Belarus",
        },
        {
            code: "BG",
            code_639: "bg-BG",
            timezone: "Europe/Sofia",
            name: "Bulgaria",
        },
        {
            code: "CZ",
            code_639: "cs-CZ",
            timezone: "Europe/Prague",
            name: "Czech Republic",
        },
        {
            code: "HU",
            code_639: "hu-HU",
            timezone: "Europe/Budapest",
            name: "Hungary",
        },
        {
            code: "PL",
            code_639: "pl-PL",
            timezone: "Europe/Warsaw",
            name: "Poland",
        },
        {
            code: "MD",
            code_639: "ro-MD",
            timezone: "Europe/Chisinau",
            name: "Moldova",
        },
        {
            code: "RO",
            code_639: "ro-RO",
            timezone: "Europe/Bucharest",
            name: "Romania",
        },
        {
            code: "RU",
            code_639: "ru-RU",
            timezone: "Europe/Moscow",
            name: "Russia",
        },
        {
            code: "SK",
            code_639: "sk-SK",
            timezone: "Europe/Bratislava",
            name: "Slovakia",
        },
        {
            code: "UA",
            code_639: "uk-UA",
            timezone: "Europe/Kiev",
            name: "Ukraine",
        },
    ],

    "Southeast Asia": [
        {
            code: "BN",
            code_639: "ms-BN",
            timezone: "Asia/Brunei",
            name: "Brunei",
        },
        {
            code: "KH",
            code_639: "km-KH",
            timezone: "Asia/Phnom_Penh",
            name: "Cambodia",
        },
        {
            code: "ID",
            code_639: "id-ID",
            timezone: "Asia/Jakarta",
            name: "Indonesia",
        },
        {
            code: "LA",
            code_639: "lo-LA",
            timezone: "Asia/Vientiane",
            name: "Laos",
        },
        {
            code: "MY",
            code_639: "ms-MY",
            timezone: "Asia/Kuala_Lumpur",
            name: "Malaysia",
        },
        {
            code: "MM",
            code_639: "my-MM",
            timezone: "Asia/Yangon",
            name: "Myanmar",
        },
        {
            code: "PH",
            code_639: "tl-PH",
            timezone: "Asia/Manila",
            name: "Philippines",
        },
        {
            code: "SG",
            code_639: "en-SG",
            timezone: "Asia/Singapore",
            name: "Singapore",
        },
        {
            code: "TH",
            code_639: "th-TH",
            timezone: "Asia/Bangkok",
            name: "Thailand",
        },
        {
            code: "VN",
            code_639: "vi-VN",
            timezone: "Asia/Ho_Chi_Minh",
            name: "Vietnam",
        },
    ],

    "South Asia": [
        {
            code: "AF",
            code_639: "fa-AF",
            timezone: "Asia/Kabul",
            name: "Afghanistan",
        },
        {
            code: "BD",
            code_639: "bn-BD",
            timezone: "Asia/Dhaka",
            name: "Bangladesh",
        },
        {
            code: "BT",
            code_639: "dz-BT",
            timezone: "Asia/Thimphu",
            name: "Bhutan",
        },
        {
            code: "IN",
            code_639: "hi-IN",
            timezone: "Asia/Kolkata",
            name: "India",
        },
        {
            code: "MV",
            code_639: "dv-MV",
            timezone: "Indian/Maldives",
            name: "Maldives",
        },
        {
            code: "NP",
            code_639: "ne-NP",
            timezone: "Asia/Kathmandu",
            name: "Nepal",
        },
        {
            code: "PK",
            code_639: "ur-PK",
            timezone: "Asia/Karachi",
            name: "Pakistan",
        },
        {
            code: "LK",
            code_639: "si-LK",
            timezone: "Asia/Colombo",
            name: "Sri Lanka",
        },
    ],
};
