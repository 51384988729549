<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4066 12.1819C10.9436 12.5602 11.5067 12.8783 12.087 13.1191C12.4474 13.2498 13.0129 12.8797 13.4287 12.6076C13.5326 12.5396 13.6272 12.4777 13.7069 12.4313L13.7331 12.4166C14.1247 12.1966 14.5596 11.9524 15.1189 12.0701C15.6213 12.1733 17.3451 13.5318 17.8215 14.0133C18.1333 14.3228 18.3066 14.6495 18.3326 14.9848C18.3846 16.2315 16.7127 17.633 16.3143 17.8738C15.4654 18.4928 14.3306 18.4842 12.9619 17.8652C11.498 17.2719 9.74822 16.0252 8.05907 14.512C7.45441 13.9703 6.29322 12.8298 5.96277 12.4489C4.25629 10.6003 2.81835 8.61418 2.14269 7.00636C1.82218 6.32713 1.66626 5.69948 1.66626 5.14061C1.66626 4.59034 1.82218 4.10026 2.12536 3.67896C2.30727 3.36084 3.7712 1.62405 5.06189 1.66704C5.38239 1.70144 5.71156 1.8648 6.03207 2.17432C6.51716 2.64721 7.91179 4.3582 8.01574 4.86548C8.13434 5.41201 7.88842 5.84343 7.66687 6.2321L7.66687 6.2321L7.65192 6.25834C7.60199 6.34461 7.53429 6.44695 7.46028 6.55884L7.46028 6.55884C7.18762 6.97101 6.82931 7.51268 6.95807 7.85756C7.27944 8.64857 7.73854 9.42238 8.29207 10.136C8.90637 10.8705 9.8695 11.8036 10.4066 12.1819Z"
      stroke="currentColor"
      stroke-width="1.51523"
    />
  </svg>
</template>
