// @ts-nocheck
export default {
    selectedRow: [],
    tableData: [],
    payloadQuery: {
        page: 1,
        per_page: 10,
    },
    payloadEdit: {},
    allowEditList: [],
    allowShowHeader: [],
    addAction: '',
    listIdsDelete: [],
    headerArray: []
}  

