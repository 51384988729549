import { TLanguages, TLanguagesForSampleChat } from "@/interfaces/person-ai";

export const AllowEditList = {
    ADMIN: ["role_name"],
    USER: ["display_name", "date_of_birth", "gender", "nationality"],
};

export const languageAvailable: TLanguages[] = [
    "English",
    "German",
    "French",
    "Spanish",
    "Italian",
    "Portuguese",
    "Polish",
    "Hindi",
    "Korean",
    "Japanese",
];
export const languageAvailableOptions: {
    text: TLanguages;
    value: TLanguages;
    active: boolean;
}[] = (() =>
    languageAvailable.map((l: TLanguages) => ({
        text: l,
        value: l,
        active: true,
    })))();

export const languageAvailableForSampleChat: TLanguagesForSampleChat[] = [
    "English",
    "Korean",
];

export const languageAvailableOptionsForSampleChat: {
    text: TLanguagesForSampleChat;
    value: TLanguagesForSampleChat;
    active: boolean;
}[] = (() =>
    languageAvailableForSampleChat.map((l:TLanguagesForSampleChat) => ({
        text: l,
        value: l,
        active: true,
    })))();

export interface ParentInfo {
    id: string | number;
}
