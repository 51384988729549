<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="
    min-width: 20px;
    min-height: 20px;"
  >
    <path
      d="M3.55695 12.0927C3.60292 12.2994 3.64699 12.499 3.68955 12.6917C3.93506 13.8033 4.13043 14.6879 4.35222 15.4206C4.61087 16.2749 4.87531 16.8113 5.20567 17.1791C5.83097 17.8752 6.95293 18.25 10.0002 18.25C13.0475 18.25 14.1695 17.8752 14.7948 17.1791C15.1251 16.8113 15.3896 16.2749 15.6482 15.4206C15.87 14.6879 16.0654 13.8033 16.3109 12.6917C16.3534 12.499 16.3975 12.2994 16.4435 12.0927C17.0342 9.43618 16.6284 8.18328 15.8056 7.49455C15.3501 7.11325 14.6709 6.81417 13.6872 6.61603C12.7059 6.41841 11.484 6.33329 10.0002 6.33329C8.51642 6.33329 7.29455 6.41841 6.31329 6.61603C5.3295 6.81417 4.65037 7.11325 4.19483 7.49455C3.37201 8.18328 2.96625 9.43618 3.55695 12.0927Z"
      stroke="currentColor"
      stroke-width="1.83333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0004 10V14.5833M6.94482 10V14.5833M13.0559 10V14.5833"
      stroke="currentColor"
      stroke-width="1.83333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5281 2.26184C14.3468 2.46437 16.2489 3.20588 17.348 3.86736C17.7817 4.12842 17.9217 4.69169 17.6607 5.12545C17.3996 5.55921 16.8363 5.69921 16.4026 5.43815C15.4333 4.85478 13.3793 4.04165 10.0003 4.04165C6.60769 4.04165 4.49831 4.86061 3.62049 5.42415C3.19446 5.69765 2.62738 5.574 2.35389 5.14797C2.08039 4.72194 2.20404 4.15487 2.63006 3.88137C3.68679 3.20297 5.64499 2.46327 8.4725 2.26158V1.74998C8.4725 1.24372 8.8829 0.833313 9.38917 0.833313H10.6114C11.1177 0.833313 11.5281 1.24372 11.5281 1.74998V2.26184Z"
      fill="currentColor"
    />
  </svg>
</template>
