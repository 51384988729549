<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="2.5"
            y="1.5"
            width="16"
            height="16"
            rx="7"
            stroke="currentColor"
        />
        <path d="M3 7.5H18.5" stroke="currentColor" />
        <path d="M3 11.5H18.5" stroke="currentColor" />
        <path
            d="M12.8438 9.5C12.8438 11.8004 12.4932 13.8593 11.944 15.3239C11.6686 16.0581 11.3536 16.6154 11.0307 16.9796C10.7073 17.3444 10.4211 17.4688 10.1875 17.4688C9.95388 17.4688 9.66773 17.3444 9.34429 16.9796C9.02141 16.6154 8.70636 16.0581 8.43102 15.3239C7.88181 13.8593 7.53125 11.8004 7.53125 9.5C7.53125 7.19963 7.88181 5.1407 8.43102 3.67613C8.70636 2.94188 9.02141 2.38459 9.34429 2.02041C9.66773 1.65561 9.95388 1.53125 10.1875 1.53125C10.4211 1.53125 10.7073 1.65561 11.0307 2.02041C11.3536 2.38459 11.6686 2.94188 11.944 3.67613C12.4932 5.1407 12.8438 7.19963 12.8438 9.5Z"
            stroke="currentColor"
            stroke-width="1.0625"
        />
    </svg>
</template>
