import { TransformedMutationTypesTable } from "@/store/modules/table/mutation-style";
import { inject, ref } from "vue";
import { Vue, Options } from "vue-class-component";
import { AccordingPanel } from "@/components/base";

@Options({
    components: {
        AccordingPanel
    },
    props: {
        defaultSelected: [String, Number],
        options: Object,
        placeholder: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        provider: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["selectOption"],
    watch: {
        defaultSelected: {
            handler() {
                this.updateSelected();
            },
        },
        options: {
            handler(value: any) {
                this.updateSelected();
            },
            deep: true,
        },
    },
})
export default class DropdownSelect extends Vue {
    public defaultSelected!: any;
    public selected: any = null;
    public options!: any;
    public placeholder!: string;
    public isOpen: boolean = false;
    public disabled!: boolean;
    public provider!: boolean;
    public visibleProviders = ref<{ [key: string]: boolean }>({});

    mounted(): void {
        document.addEventListener("click", this.handleClickOutside);
        this.updateSelected();
        // this.initializeProviderVisibility();
    }
    unmounted(): void {
        document.removeEventListener("click", this.handleClickOutside);
    }

    public updateSelected() {
        this.selected =
            this.options.find((option: any) => {
                return option.value == this.defaultSelected;
            })?.text || "";
    }

    public toggleDropdown() {
        this.isOpen = !this.isOpen;
    }
    public selectOption(option: any) {
        this.selected = option.text;
        this.isOpen = false;

        this.$emit("selectOption", option.value);
    }

    public handleClickOutside = (event: any) => {
        if (
            this.isOpen &&
            !event.target.closest(".dropdown-selected") &&
            !event.target.closest(".dropdown-content")
        ) {
            this.isOpen = false;
        }
    };

    get hasIconSlot(): boolean {
        return !!this.$slots.icon;
    }

    get groupedOptions() {
        return this.options.reduce((acc: any, option: any) => {
            if (!acc[option.provider]) {
                acc[option.provider] = [];
            }
            acc[option.provider].push(option);
            return acc;
        }, {});
    }

}
