<script lang="ts" src="./side-bar.ts"></script>
<style scoped lang="scss" src="./side-bar.scss"></style>

<template>
    <ModalBase
      ref="modal-base"
      :title="'Log Out'"
      :btnAction="'Yes'"
      :btnCancel="'Cancel'"
      :description="'Do you want to log out'"
      :isDanger="true"
      @handleClickBtnAction="handleSignOut"
    />
  <div class="side-bar">
    <div class="side-bar-content">
      <div class="side-bar-header">
        <img src="@/assets/images/logos/png/logo.png" alt="flipjungle-logo" />
      </div>
      <div class="side-bar-list">
        <div class="side-bar-list-main">
          <div v-for="(routes, routesIndex) in routesArray" :key="routesIndex">
            <p class="title">{{ routes?.name }}</p>
            <div
              v-for="(route, routeIndex) in routes?.object"
              :key="routeIndex"
            >
              <div
                v-if="
                  route !== ROUTE_NAMES.USERS &&
                  route !== ROUTE_NAMES.AI_CHARACTER &&
                  route !== ROUTE_NAMES.SCENARIOS
                "
                :class="[routeName === route ? 'item-active' : '', 'item']"
                @click="handleTab(route)"
              >
                <img
                  :src="
                    require(`@/assets/images/icons/svg/${
                      route === routeName ? route + '-active' : route
                    }.svg`)
                  "
                  class="background-icon"
                  :style="
                    route === routeName
                      ? 'background: var(--primary-05-day, #169C8A);'
                      : ''
                  "
                  alt="background-icon"
                />
                <p style="text-transform: capitalize">
                  {{ formatRoute(route) }}
                </p>
              </div>
              <div v-else-if="route === ROUTE_NAMES.AI_CHARACTER">
                <div
                  :class="[
                    routeName === ROUTE_NAMES.CATEGORY ? 'item-active' : '',
                    'd-flex flex-row justify-content-between item',
                  ]"
                  @click="handleTab(route)"
                >
                  <div
                    class="d-flex flex-row align-items-center"
                    style="gap: 8px"
                  >
                    <img
                      :src="
                        require(`@/assets/images/icons/svg/${
                          route === routeName ? route + '-active' : route
                        }.svg`)
                      "
                      class="background-icon"
                      :style="
                        route === routeName
                          ? 'background: var(--primary-05-day, #169C8A);'
                          : ''
                      "
                      alt="background-icon"
                    />
                    <p style="text-transform: capitalize">
                      {{ route }}
                    </p>
                  </div>
                  <img src="@/assets/images/icons/svg/arrow.svg" alt="arrow" />
                </div>
                <div
                  v-if="isShowSubMenu[ROUTE_NAMES.AI_CHARACTER]"
                  style="position: relative; top: 100%"
                >
                  <ul :class="['sub-menu']">
                    <li class="sub-menu-item" @click="handleOpenModalCategory">
                      All Categories
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else-if="route === ROUTE_NAMES.SCENARIOS">
                <div
                  :class="[
                    routeName === ROUTE_NAMES.SCENARIOS ? 'item-active' : '',
                    'd-flex flex-row justify-content-between item',
                  ]"
                  @click="handleTab(route)"
                >
                  <div
                    class="d-flex flex-row align-items-center"
                    style="gap: 8px"
                  >
                    <img
                      :src="
                        require(`@/assets/images/icons/svg/${
                          route === routeName ? route + '-active' : route
                        }.svg`)
                      "
                      class="background-icon"
                      :style="
                        route === routeName
                          ? 'background: var(--primary-05-day, #169C8A);'
                          : ''
                      "
                      alt="background-icon"
                    />
                    <p style="text-transform: capitalize">
                      {{ route }}
                    </p>
                  </div>
                  <img src="@/assets/images/icons/svg/arrow.svg" alt="arrow" />
                </div>
                <div
                  v-if="isShowSubMenu[ROUTE_NAMES.SCENARIOS]"
                  style="position: relative; top: 100%"
                >
                  <ul :class="['sub-menu']">
                    <li class="sub-menu-item" @click="handleOpenModalTopic">
                      All Topics
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else>
                <div
                  :class="[
                    routeName === ROUTE_NAMES.PAID_USERS ||
                    routeName === ROUTE_NAMES.FREE_USERS ||
                    routeName === ROUTE_NAMES.LINK_REQUEST_USERS
                      ? 'item-active'
                      : '',
                    'd-flex flex-row justify-content-between item',
                  ]"
                  @click="handleTab('users')"
                >
                  <div
                    class="d-flex flex-row align-items-center"
                    style="gap: 8px"
                  >
                    <img
                      v-if="routeName === ROUTE_NAMES.USERS"
                      src="@/assets/images/icons/svg/users-active.svg"
                      class="background-icon"
                      style="background: var(--primary-05-day, #169c8a)"
                      alt="users-active"
                    />
                    <img
                      v-else
                      src="@/assets/images/icons/svg/users.svg"
                      class="background-icon"
                      alt="users-active"
                    />
                    <p>Users</p>
                  </div>
                  <img src="@/assets/images/icons/svg/arrow.svg" alt="arrow" />
                </div>
                <div v-if="isUserTab" style="position: relative; top: 100%">
                  <div
                    :class="[
                      routeName === ROUTE_NAMES.PAID_USERS
                        ? 'item-users-active'
                        : '',
                      'item-users',
                    ]"
                    @click="handleTab(ROUTE_NAMES.PAID_USERS)"
                  >
                    <p>Paid</p>
                  </div>
                  <div
                    :class="[
                      routeName === ROUTE_NAMES.FREE_USERS
                        ? 'item-users-active'
                        : '',
                      'item-users',
                    ]"
                    @click="handleTab(ROUTE_NAMES.FREE_USERS)"
                  >
                    <p>Free</p>
                  </div>
                  <div
                    class="disabled-item"
                    :class="[
                      routeName === ROUTE_NAMES.LINK_REQUEST_USERS
                        ? 'item-users-active'
                        : '',
                      'item-users',
                    ]"
                    @click="handleTab(ROUTE_NAMES.LINK_REQUEST_USERS)"
                  >
                    <!-- <p>Link Request</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="side-bar-other">
          <p class="title">OTHERS</p>
          <div class="side-bar-other-btn" @click="handleOpenModalBase">
            <OffIcon />
            <p style="text-transform: capitalize">Logout</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
