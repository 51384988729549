// @ts-nocheck
type GetterTypesAdminType = {
    GET_ALLOW_EDIT_LIST: string,
};

export const GetterTypesAdmin: GetterTypesAdminType = {
    GET_ALLOW_EDIT_LIST: 'GET_ALLOW_EDIT_LIST',
};


function transformObjectProperties(targetObject, prefix) {
    const transformedObject = {};

    // Iterate over the object properties
    for (const key in targetObject) {
        transformedObject[key] = `${prefix}/${targetObject[key]}`;
    }

    return transformedObject;
}
;

export const TransformedGetterTypesAdmin: GetterTypesAdminType = transformObjectProperties(GetterTypesAdmin, 'admin');
