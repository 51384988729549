// @ts-nocheck
type GetterTypesTableType = {
    GET_SELECTED_ROW: string,
    GET_TABLE_DATA: string,
    GET_PAYLOAD_QUERY: string,
    GET_ALLOW_EDIT_LIST: string,
    GET_ALLOW_SHOW_HEADER: string,
    GET_HEADER_ARRAY: string,
};

export const GetterTypesTable: GetterTypesTableType = {
    GET_SELECTED_ROW: 'GET_SELECTED_ROW',
    GET_TABLE_DATA: 'GET_TABLE_DATA',
    GET_PAYLOAD_QUERY: 'GET_PAYLOAD_QUERY',
    GET_ALLOW_EDIT_LIST: 'GET_ALLOW_EDIT_LIST',
    GET_ALLOW_SHOW_HEADER: 'GET_ALLOW_SHOW_HEADER',
    GET_HEADER_ARRAY: 'GET_HEADER_ARRAY',
};


function transformObjectProperties(targetObject, prefix) {
    const transformedObject = {};

    // Iterate over the object properties
    for (const key in targetObject) {
        transformedObject[key] = `${prefix}/${targetObject[key]}`;
    }

    return transformedObject;
}
;

export const TransformedGetterTypesTable: GetterTypesTableType = transformObjectProperties(GetterTypesTable, 'table');
