import { createStore } from 'vuex'
import action from './action';
import mutation from './mutations';
import getters from './getters';
import table from "./modules/table";
import admin from "./modules/admin";
import dashboard from './modules/dashboard';
import user from './modules/user';
import personAi from './modules/person-ai';
export default createStore({
  state: {
    isUserTab: false,
    userData: null,
    allCountries: []
  },
  getters: getters,
  mutations: mutation,
  actions: action,
  modules: {
    table,
    admin,
    dashboard,
    user,
    personAi,
  }
})
