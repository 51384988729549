
import GetterTypes from "./getter-types";
export default {
    [GetterTypes.GET_USER_DATA](state: any){
        return state.userData
    },
    [GetterTypes.GET_ALL_COUNTRIES](state: any){
        return state.allCountries
    },
    [GetterTypes.GET_USER_ROLE](state: any){
        return state.userData?.role_name
    }
}
