<script lang="ts" src="./dropdown-select.ts"></script>
<style lang="scss" scoped src="./dropdown-select.scss"></style>

<template>
  <div class="dropdown" :class="disabled ? 'disabled' : ''">
    <div
      class="dropdown-selected d-flex justify-content-between"
      @click="toggleDropdown"
    >
      <div v-if="hasIconSlot" class="dropdown-icon">
        <slot name="icon"></slot>
      </div>
      <p class="value-selected" v-if="selected || defaultSelected">
        {{ selected || defaultSelected }}
      </p>
      <p v-else class="value-placeholder">{{ placeholder }}</p>
      <img src="@/assets/images/icons/svg/arrow-down-black.svg" />
    </div>

    <div v-if="isOpen" class="dropdown-content">
      <div v-for="(items, provider) in groupedOptions" :key="provider">
        <div class="accordion-title">
          <span class="title-group">{{ provider }}</span>
        </div>

        <div class="chat-model-of-provider">
          <div
            v-for="item in items"
            :key="item.text"
            class="chat-model-item"
            :class="item.active || 'disabled-item'"
            @click="selectOption(item)"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
