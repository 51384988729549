import { DropdownSelect } from "@/components/base";
import ButtonSolid from "@/components/base/buttons/primary/solid.vue";
import { TransformedActionTypesPersonAi } from "@/store/modules/person-ai/action-style";
import { Modal } from "bootstrap";
import { Options, Vue } from "vue-class-component";

@Options({
    components: {
        DropdownSelect,
        ButtonSolid,
    },
    props: {
        scenarioEditProps: Object,
        topicsDataProps: Object,
        scenariosUnknownTopicProps: Object,
    },
    emits: ["onCloseModal"],
})
export default class UpdateTopicForScenario extends Vue {
    public scenarioEditProps!: any;
    public topicsDataProps!: any;
    public scenariosUnknownTopicProps!: any;
    public topicSelected: number | null = null;
    public loading: boolean = false;
    private modal: any = null;

    // =============== Action Modal =============== //
    public openModal() {
        this.modal = new Modal(this.$refs["update-topic-for-scenario"] as any);
        this.modal.show();
    }

    // =============== Event Form =============== //
    public handleSelectedChangeTopic(topicId: number) {
        this.topicSelected = topicId;
    }

    public handleSaveScenario() {
        if (!this.topicSelected)
            return this.$toast.warning("Please select a topic");
        this.apiRequestScenario(this.topicSelected);
    }

    // =============== Api Action =============== //
    public apiRequestScenario(topicId: number) {
        this.loading = true;
        this.$apiAction.handleApiRequest(
            TransformedActionTypesPersonAi.UPDATE_VIDEO_CALL_SCENARIO_OPTION_2_V2,
            {
                topic_id: topicId,
                scenario_id: this.scenarioEditProps.id,
            },
            (data: any) => {
                this.updateData(topicId);
                this.$toast.success(
                    data?.message || `Scenario successfully scenario!`
                );
                this.loading = false;
                this.$emit("onCloseModal");
                this.modal.hide();
            },
            (data: any) => {
                this.loading = false;
                this.$toast.error(data?.message || `Scenario updated failed!`);
            }
        );
    }

    // =============== Utilities Helper =============== //
    public updateData(topicId: number) {
        this.topicsDataProps.forEach((topic: any, idx: number) => {
            if (topic.id === topicId) {
                this.topicsDataProps[idx].scenarios.push({
                    id: this.scenarioEditProps.id,
                    name: this.scenarioEditProps.name,
                });
            }
        });
        this.scenariosUnknownTopicProps.forEach((item: any, idx: number) => {
            if (item.id === this.scenarioEditProps.id) {
                return this.scenariosUnknownTopicProps.splice(idx, 1);
            }
        });
    }
}
