// @ts-nocheck
type MutationTypesPersonAiType = {
    SET_EDIT_PERSON_AI_ID: string,
    SET_EDIT_TOPIC_VIDEO_CALL: string,
    SAVE_SCENARIO: string,
    SET_TOPIC_DATA_SELECTED: string,
    SET_READY_TO_SAVE: string,
};

export const MutationTypesPersonAi: MutationTypesPersonAiType = {
    SET_EDIT_PERSON_AI_ID: 'SET_EDIT_PERSON_AI_ID',
    SET_EDIT_TOPIC_VIDEO_CALL: 'SET_EDIT_TOPIC_VIDEO_CALL',
    SAVE_SCENARIO: 'SAVE_SCENARIO',
    SET_TOPIC_DATA_SELECTED: 'SET_TOPIC_DATA_SELECTED',
    SET_READY_TO_SAVE: 'SET_READY_TO_SAVE',
};


function transformObjectProperties(targetObject, prefix) {
    const transformedObject = {};

    // Iterate over the object properties
    for (const key in targetObject) {
        transformedObject[key] = `${prefix}/${targetObject[key]}`;
    }

    return transformedObject;
}
;

export const TransformedMutationTypesPersonAi: MutationTypesPersonAiType = transformObjectProperties(MutationTypesPersonAi, 'personAi');
