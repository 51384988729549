// @ts-nocheck
import { ActionTypesUser } from "./action-style";
import ApiService from "@/services/api";
export default {
    [ActionTypesUser.GET_LEARNERS]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest("/api/v2/users/get-list", payload)
        return response ? response : null;
    },
    [ActionTypesUser.GET_PARENTS]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest("/api/v2/parents/get-list", payload)
        return response ? response : null;
    },
    [ActionTypesUser.UPDATE_USER]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePutRequest(`/api/v2/users/${payload.id}`, payload.body)
        return response ? response : null;
    },
    [ActionTypesUser.UPDATE_PARENT]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePutRequest(`/api/v2/parents/${payload.id}`, payload.body)
        return response ? response : null;
    },


    [ActionTypesUser.DELETE_USER]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeDeleteRequest(`/api/v2/users/delete-list`, payload)
        return response ? response : null;
    },
    [ActionTypesUser.DELETE_PARENT]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeDeleteRequest(`/api/v2/parents/delete-list`, payload)
        return response ? response : null;
    },


    [ActionTypesUser.CREATE_USER]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executePostRequest(`/api/v1/users`, payload)
        if (response) {
            return response;
        } else {
            return null;
        }
    },
    [ActionTypesUser.EXPORT_EXCEL_LEARNER]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest(`/api/v1/users/export-excel`, payload)
        if (response) {
            return response;
        } else {
            return null;
        }
    },
    [ActionTypesUser.EXPORT_EXCEL_PARENT]: async ({ commit }, payload) => {
        // Since this is an async function, you might want to do some asynchronous operation here.
        const response = await ApiService.executeGetRequest(`/api/v1/parents/export-excel`, payload)
        if (response) {
            return response;
        } else {
            return null;
        }
    },
}
