<script lang="ts" setup>
import ButtonSolid from "@/components/base/buttons/primary/solid.vue";

withDefaults(
    defineProps<{
        textBtn: string;
        title?: string;
        description?: string;
    }>(),
    {
        textBtn: "Go back",
        title: "Nothing to show here!",
        description: "Let's start now to fill this empty space!",
    }
);
const emits = defineEmits(["click"]);
</script>

<template>
    <div class="no-data-container">
        <img src="@/assets/images/pictures/png/no-data.png" alt="no data" />
        <h3 class="title">{{ title }}</h3>
        <p class="description">{{ description }}</p>
        <ButtonSolid
            @click="(event) => emits('click', event)"
            :text="textBtn"
        />
    </div>
</template>

<style lang="scss" scoped>
.no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 8px;
    margin: 0 auto;

    img {
        width: 200px;
    }
    .title {
        color: var(--neutral-02-day, #222);
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }
    .description {
        color: var(--neutral-05-day, #adadad);
        font-size: 14px;
    }
}
</style>
