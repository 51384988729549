<script lang="ts" setup>
import { ExclamationMarkIcon } from "@/assets/images/icons/components";
import { ref } from "vue";
const props = withDefaults(
    defineProps<{
        menuSelections: string[];
        languageSelected: string;
        loading: boolean;
        checkList: string[];
        enabledTranslate?: boolean;
        enabledWarningIcon?: boolean;
    }>(),
    {
        menuSelections: () => [""],
        languageSelected: "",
        loading: () => false,
        checkList: () => [],
        enabledTranslate: true,
        enableWarningIcon: false,
    }
);
const languageSelected = ref<string>(props.languageSelected);
const emits = defineEmits(["handleSelectLanguage", "handleTranslate"]);

const handleSelectLanguage = (language: string) => {
    languageSelected.value = language;
    emits("handleSelectLanguage", language);
};
const handleTranslate = (event: MouseEvent) => {
    emits("handleTranslate", event);
};
</script>
<template>
    <div class="menu-selection-component">
        <div class="warning">
            <img src="@/assets/images/icons/svg/warning.svg" alt="warning" />
            <span
                >The following content needs to be translated into multiple
                languages.</span
            >
        </div>

        <!-- Menu language -->
        <div class="menu-selection">
            <template v-for="(item, index) of menuSelections" :key="index">
                <button
                    :class="[
                        languageSelected === item ? 'active' : '',
                        `menu-selection-item`,
                    ]"
                    @click="handleSelectLanguage(item)"
                >
                    {{ item }}
                    <img
                        v-if="checkList.includes(item)"
                        class="icon-language"
                        src="@/assets/images/icons/svg/check.svg"
                        alt="language"
                    />
                    <span v-else-if="enabledWarningIcon" class="warning-icon">
                        <ExclamationMarkIcon />
                    </span>
                </button>
            </template>
        </div>

        <div v-if="enabledTranslate" class="auto-translate-box">
            <button
                @click="handleTranslate"
                :class="`
                                ${
                                    languageSelected !== 'English'
                                        ? 'disabled-item'
                                        : ''
                                }
                                ${loading ? 'disabled-item' : ''}`"
                class="button-outline"
                style="min-width: 170px"
            >
                <span
                    v-if="loading"
                    class="me-2 spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                />
                Auto translate
            </button>
        </div>
    </div>
</template>
<style lang="scss">
.menu-selection-component {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;

    .warning {
        display: flex;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        font-size: 14px;
        color: var(--neutral-03-day, #464749);

        span {
            padding-top: 2px;
        }
    }

    .menu-selection {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        flex-wrap: wrap;
        &-item {
            flex: 0;
            display: flex;
            height: 40px;
            padding: 6px 12px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 12px;
            border: 1px solid #d6d6d6;
            background: white;
            transition: all 0.3s ease;
            max-width: fit-content;
            &.active,
            &:hover {
                border-radius: 12px;
                background: var(--primary-05-day, #169c8a);
                color: white;
                border-color: transparent;
                .warning-icon {
                    background-color: white;
                    color: var(--warning-500);
                }
            }
            .warning-icon {
                border-radius: 50%;

                height: 22px;
                width: 22px;
                font-size: 12px;

                color: white;
                background-color: var(--warning-500);

                /* color: var(--warning-500);
                border: 1px solid var(--warning-500); */
            }
        }

        .menu-selection-item .icon-language {
            height: 22px;
            width: 22px;
            padding: 5px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            background: #0000002b;
        }
    }
}
</style>
