<script lang="ts" setup>
import {
  COGNITIVE_DEFAULT_API_DATA,
  COGNITIVE_DEFAULT_VISUALIZATION_DATA,
  COGNITIVE_KEYS,
} from "../../constants";
import {
  CognitiveDataForVisualization,
  CognitiveDataFromAPI,
} from "../../type";
import { computed, defineProps } from "vue";
import CognitiveReviewBox from "./CognitiveBox.vue";
import CognitiveChart from "./CognitiveChart.vue";

import CardContainer from "../common/CardContainer.vue";

interface Props {
  data: CognitiveDataFromAPI;
  updateDate?: string;
}
const props = withDefaults(defineProps<Props>(), {
  data: () => COGNITIVE_DEFAULT_API_DATA,
  childId: 0,
  updateDate: "",
});

// Covert the data from the API to the data suitable for visualization with prefix text
const cognitiveVisualizationData = computed<CognitiveDataForVisualization>(
  () => {
    let visualizationData: CognitiveDataForVisualization =
      COGNITIVE_DEFAULT_VISUALIZATION_DATA;
    if (props.data) {
      for (let i = 0; i < COGNITIVE_KEYS.length; i++) {
        const key = COGNITIVE_KEYS[i];
        const value = props.data[key];
        if (value) {
          visualizationData[key].value = value.toFixed(2);
        } else {
          visualizationData[key].value = "0";
        }
      }

      return visualizationData;
    }
    return COGNITIVE_DEFAULT_VISUALIZATION_DATA;
  }
);
</script>

<template>
  <CardContainer>
    <div class="cogCard">
      <CognitiveChart :data="data" />
      <div class="cogCard-items">
        <CognitiveReviewBox
          v-for="item in cognitiveVisualizationData"
          :key="item.icon"
          v-bind="item"
        />
      </div>
    </div>
  </CardContainer>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";

.cogCard {
  @include size(100%, 100%);
  @include flexbox(row, space-between, center, null, null, 16px);
  &-items {
    @include flexbox(column, flex-start, flex-start, null, null, 24px);
    @include size(100%, 100%);
    flex: 1 0 0%;
    align-self: stretch;
    border-radius: 16px;
    border: 2px solid rgb(181, 223, 217);
    padding: 16px;
    max-width: 400px;
  }
  @include responsive-for(mobile) {
    @include flexbox(column, space-between, center, null, null, 16px);
    &-items {
      max-width: unset;
    }
  }
}
</style>
