export const fieldsVocabularyItem = [
    "word",
    "type",
    "word_phonics_pronounce",
    "word_variant_pronounce",
    "example_phonics_pronounce",
    "example_variant_pronounce",
    "example_sentence",
];
export const maxCharacterLengthFields = {
    250: [
        "example_variant_pronounce",
        "example_sentence",
        "sentence_phonics_pronounce",
    ],
    60: ["word", "type", "word_phonics_pronounce", "word_variant_pronounce"],
};
