<template>
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.3648 15.3022C14.3648 15.8741 13.9281 16.2415 13.1911
        16.2415H12.611V14.4382C12.7183 14.4186 12.9217 14.3897 13.2789
        14.3897C13.9571 14.401 14.3648 14.7014 14.3648 15.3022ZM31.9989
        16C31.9989 17.2408 31.5137 18.4083 30.6394 19.2846L28.674
        21.2501V24.0279C28.674 26.5868 26.5888 28.673 24.0288
        28.673H21.251L19.2876 30.6385C18.4081 31.5159 17.2417 32 15.9999
        32C14.7581 32 13.5916 31.5159 12.7132 30.6374L10.7509
        28.6761H7.97202C6.73021 28.6761 5.56479 28.192 4.68634 27.3166C3.80789
        26.4361 3.32686 25.2738 3.32686 24.031V21.2501L1.36144 19.2877C0.484018
        18.4093 0.00195312 17.2439 0.00195312 16.0021C0.00195312 14.7603
        0.487114 13.5938 1.36144 12.7174L3.32686 10.753V7.97419C3.32686 5.41316
        5.41202 3.32903 7.97202 3.32903H10.7498L12.7152 1.36258C13.5916 0.485161
        14.7571 0 15.9999 0C17.2427 0 18.4081 0.484129 19.2866 1.36258L21.2489
        3.3249H24.0278C25.2675 3.3249 26.435 3.808 27.3124 4.68439C28.1919
        5.56387 28.6729 6.72929 28.6729 7.97006V10.7489L30.6383 12.7143C31.5137
        13.5917 31.9989 14.7582 31.9989 16ZM10.0365
        13.345H6.04582V19.873H7.52815V17.248H9.87241V16.0465H7.52815V14.5548H10.0386V13.344L10.0365
        13.345ZM16.0783 19.873C15.9524 19.6212 15.7501 18.7706 15.5457
        18.0335C15.3805 17.4338 15.1297 16.9961 14.6724 16.8124V16.7845C15.234
        16.5812 15.8254 16.0103 15.8254 15.1763C15.8254 14.5755 15.6118 14.1203
        15.2247 13.8106C14.7601 13.4431 14.082 13.2965 13.1127 13.2965C12.3271
        13.2965 11.6211 13.3554 11.1462
        13.4328V19.873H12.6089V17.3068H13.0559C13.6567 17.3172 13.9374 17.5401
        14.1109 18.3546C14.3049 19.1577 14.4598 19.6914 14.5651 19.8751L16.0783
        19.873ZM21.0538
        18.6632H18.3679V17.1128H20.771V15.9112H18.3679V14.5548H20.9186V13.344H16.8876V19.872H21.0538V18.6632ZM26.2234
        18.6632H23.5395V17.1128H25.9416V15.9112H23.5395V14.5548H26.0881V13.344H22.0592V19.872H26.2234V18.6632Z"
            fill="currentColor"
        />
    </svg>
</template>
