<script lang="ts" setup>
import { ClockIcon, XIcon, CheckIcon } from "@/assets/images/icons/components";
import { computed, defineProps } from "vue";

interface Props {
  type?: "CONFIRMED" | "PENDING" | "DECLINED";
}

const props = withDefaults(defineProps<Props>(), {
  type: "PENDING",
});

const statusIcon = computed(() => {
  switch (props.type) {
    case "CONFIRMED":
      return CheckIcon;
    case "PENDING":
      return ClockIcon;
    case "DECLINED":
      return XIcon;
    default:
      return null;
  }
});

const statusTitle = computed(() => {
  switch (props.type) {
    case "CONFIRMED":
      return "Confirmed";
    case "PENDING":
      return "Pending Confirmation";
    case "DECLINED":
      return "Declined";
    default:
      return "";
  }
});

const statusClass = computed(() => {
  switch (props.type) {
    case "CONFIRMED":
      return "success";
    case "PENDING":
      return "warning";
    case "DECLINED":
      return "danger";
    default:
      return "";
  }
});
</script>

<template>
  <div class="stBadge" :class="[statusClass]">
    <component :is="statusIcon" />
    <p>{{ statusTitle }}</p>
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/index.scss";
.stBadge {
  @include flexbox(row, center, center, null, null, 4px);
  width: fit-content;
  background: #fef3df;
  color: #f89900;
  border: none;
  border-radius: 16px;
  padding: 12px 16px;
  p {
    font-size: 16px;
    font-weight: 500;
  }
  &.success {
    background: #e9f7e6;
    color: #46ba2b;
    p {
      color: #46ba2b;
    }
  }
  &.warning {
    background: #fff4e6;
    color: #ff9800;
    p {
      color: #ff9800;
    }
  }
  &.danger {
    background: #ffecef;
    color: #ff513c;
    p {
      color: #ff513c;
    }
  }
}
</style>
